//@ts-ignoree
import * as H from 'history';
import {FormInstance} from "antd";
import * as React from "react";

export type History = H.History
export type Location = H.Location

export interface IBaseProps<Params = any> {
    history: History,
    location: Location,
    match: {
        id: Symbol,
        params: Params;
        isExact: boolean;
        path: string;
        url: string;
        alive?: boolean
    }
}


export type IdKey = BigInt

export type IReactKey = React.Key | IdKey

export const getEmptyIdKey = (v: string | number = 0): IdKey => BigInt(v)

export type ISetFormFun = ((from: FormInstance<any> | null, id?: string | number) => void) | null

/*分页数据*/
export interface IListData<T = any> {
    pageNum: number,
    pageSize: number,
    totalPage: number,

    total: number,

    list: T[]
}

export interface IListSate<T = any> {
    listData: IListData<T>,
    loading: boolean,
    selectedRowKeys?: any[]
}


export interface ISelectOption {
    value: any,
    label: string
}


export const getSelectOptionName = (value: any, list: ISelectOption[]) => list.find(item => item.value === value)?.label

export enum EPlaceHolder {
    Input = '请输入',
    Select = '请选择'
}


export enum ELogisticUnit {
    kg = 'Kg',
    m3 = 'm³',
    count = '件',
}

/*image/gif, image/png, image/jpeg, image/bmp, image/webp, image/x-icon, image/vnd.microsoft.icon*/
export enum EImageMIME {
    gif = 'image/gif',
    png = 'image/png',
    xpng = 'image/x-png',
    jpeg = 'image/jpeg',
    pjpeg = 'image/pjpeg',
    bmp = 'image/bmp',
    webp = 'image/webp',
    xIcon = 'image/x-icon',
    msIcon = 'image/vnd.microsoft.icon',
}

export enum EFileMIME {
    excel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.csv',
    doc = '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf = '.pdf,application/pdf'
}

/*
* .objectFitCover {
    object-fit: cover;
}

.objectFitFill {
    object-fit: fill;
}

.objectFitContain {
    object-fit: contain;
}
* */
export enum EImageFitClass {
    cover = 'object-fit-cover',
    fill = 'object-fit-fill',
    contain = 'object-fit-contain'
}


export enum EStatusName {
    disable = '停用',
    enable = '激活'
}

export enum EStyleClass {
    AlignCenter = 'align-center',
    AlignLeft = 'align-left',
    AlignRight = 'align-right',
    DefaultColor = 'default-color',
    DefaultTransition = 'default-transition',
    PrimaryColor = 'primary-color',
    ContentWith = 'contentWith',
    ClearMargin = 'clearMargin',
    FlexBox = 'flexBox',
    FlexBoxStart = 'flexBoxStart',
    FlexBoxBaseline = 'flexBoxBaseline',
    AutoFlex = 'autoFlex',
    ClearFlex = 'clearFlex',
    TextClick = 'text-click',
    TextClickImportant = 'text-click-important',
    TextPrimaryClick = 'text-primary-click',
    Font20 = 'font-20',
    Font16 = 'font-16',
    Font12 = 'font-12',

    DefaultBorder = 'default-border',

    BorderClick = 'border-click',

    PriceUnit = 'price-unit',
    PriceSubUnit = 'price-sub-unit',

    IconFont = 'icon-font',

    BackgroundColorHover = 'background-color-hover',

    CursorPointer = 'cursor-pointer',
    CursorUnset = 'cursor-unset',

    Wrap = 'wrap',
    Wrap16_20 = 'wrap-16-20',

    BorderRadius = 'border-radius',

    Active = 'active',
}

export type IModalProps = {
    visible?: boolean,
    onCancel?(data: boolean): void
}

