import {EGlobalKey, getStorageData, setStorageData} from "../GlobalData";
import {getEmptyHomeData, IHomeData} from "../../service/Home";

export const setHomeStorage = (data: IHomeData) => {
    setStorageData(EGlobalKey.home, data)
}

export const getHomeStorage = (del?: boolean): IHomeData | undefined => getStorageData(EGlobalKey.home, del)


export const getHomeData = (): IHomeData => getHomeStorage() || getEmptyHomeData()
