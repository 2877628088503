import {getEmptyLoginData, ILoginData} from "../../service/Login";
import {getStorageData, setStorageData} from "../GlobalData";

const KEY = 'login'
export const setLoginStorage = (data: ILoginData) => {
    setStorageData(KEY, {...data, timestamp: new Date().getTime()})
}

export const getLoginStorage = (del?: boolean): ILoginData | undefined => getStorageData(KEY, del)


export const getLoginData = (): ILoginData => getLoginStorage() || getEmptyLoginData()
