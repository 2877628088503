import * as React from 'react'
import {useLayoutEffect, useState} from 'react'
//@ts-ignore
//import Styles from './AdvanceSearchModal.scss'
import {IModalProps, ISelectOption} from "../../types";
import {Button, Form, Image, Input, Modal, Space, Tabs, Typography} from "antd";
import {IHomeData} from "../../service/Home";
import {EAdvanceSearchType} from "../../service/Item";
import VINSearch from "../VINSearch";
import ScrollContainer from "../ScrollContainer";
import {UserAutoItem} from "../ChooseAutoModal/AutoItem";
import {IAutoGearItem} from "../../service/Common";
import {IUserAutoItem} from "../../service/My";
import {useNavigate} from "react-router-dom";
import {compoundRoutePath} from "../../utils";
import {EURLs, generateGoodsListURL} from "../../types/URLs";
import ChooseAutoModal from "../ChooseAutoModal";
import {SearchOutlined} from '@ant-design/icons';
import HotTag from "./HotTag";

type Props = IModalProps & {
    homeData: IHomeData
    searchList?: ISelectOption[]
    onChange?(data: IUserAutoItem): void
}

const AdvanceSearchModal: React.FC<Props> = ({visible, homeData, searchList, onCancel, onChange}) => {

    const navigate = useNavigate(),
        [index, setIndex] = useState<EAdvanceSearchType | undefined>(),
        [vinList, setVinList] = useState<IAutoGearItem[]>([]),
        [showVinList, setShowVinList] = useState(false),
        [showAdd, setShowAdd] = useState(false),
        [form] = Form.useForm<{ kw: string }>(),
        _onCancel = () => {
            setShowVinList(false)
            onCancel && onCancel(false)
        },

        onSubmit = () => {
            form.validateFields().then(({kw}) => {
                navigate(compoundRoutePath(generateGoodsListURL(0, 0, 0, 0, 0, 0, 0, 0, kw)))
                _onCancel()
            })
        },

        onTabChange = (index: string) => {
            if (Number(index) === EAdvanceSearchType.auto) {
                setShowAdd(true)
            } else {
                setIndex(Number(index))
            }
        },
        onVInSubmit = (auto: IUserAutoItem) => {
            onChange && onChange(auto)
            navigate(compoundRoutePath(EURLs.GoodsList))
            _onCancel()
        },
        onVInListChange = (data: IAutoGearItem[]) => {
            if (data.length === 1) {
                const {GearboxParam, AutoName} = data[0]
                onChange && onChange({GearboxParam, AutoName})
                navigate(compoundRoutePath(EURLs.GoodsList))
                _onCancel()
            } else {
                setVinList(data)
                setShowVinList(true)
            }
        }

    useLayoutEffect(() => {
        if (visible) {
            const index = Number(homeData.DefaultItem || (searchList && searchList[0]?.value))
            if (index === EAdvanceSearchType.auto) {
                setShowAdd(true)
            } else {
                setIndex(index)
            }
        }
    }, [visible])


    return <>
        <Modal visible={visible && !showVinList && !showAdd} width={600} title={'高级搜索'} onCancel={_onCancel}
               footer={false}>
            <Image preview={false} width={'100%'} src={homeData.SearchImg}/>

            <Tabs activeKey={`${index ?? ''}`} size={'large'} onChange={onTabChange}>
                {searchList?.map(item => <Tabs.TabPane key={`${item.value}`} tab={item.label}/>)}
            </Tabs>

            {index === EAdvanceSearchType.part && <Form form={form} initialValues={{kw: ''}}>
				<Form.Item name={'kw'} rules={[{required: true, message: '请输入关键字'}]}>
					<Input.Search prefix={<SearchOutlined/>} placeholder={'搜索配件名称/品牌/OE/厂商编码'}
					              onSearch={onSubmit}
					              enterButton={<Button type={"primary"}>搜索</Button>}/>
				</Form.Item>
			</Form>}
            {index === EAdvanceSearchType.vin && <VINSearch onChange={onVInListChange}/>}
            <ScrollContainer type={"y"} height={80}>
                {index === EAdvanceSearchType.part && <div>
					<Typography.Paragraph type={"secondary"}>热门搜索</Typography.Paragraph>
					<Space wrap size={10}>
                        {homeData.HotKeys?.map((item, index) => <HotTag key={index} onClick={() => {
                            form.setFieldsValue({kw:item})
                        }}>{item}</HotTag>)}
					</Space>
				</div>}
            </ScrollContainer>
            <div style={{height: 20}}/>
        </Modal>

        <Modal visible={showVinList} width={600} title={'选择具体车型'} onCancel={() => setShowVinList(false)} footer={false}>
            <ScrollContainer type={"y"} height={400}>
                {vinList.map(item => <UserAutoItem key={item.GearboxParam} data={item} onClick={onVInSubmit}/>)}
            </ScrollContainer>
        </Modal>

        <ChooseAutoModal visible={showAdd} onCancel={setShowAdd} onChange={onVInSubmit}/>
    </>
}

export default AdvanceSearchModal
