import * as React from 'react'
import {fetchChooseAutoModelList, IAutoListItem} from "../../service/Common";
import ScrollContainer from "../ScrollContainer";
import {useLayoutEffect, useState} from "react";
import {apiResponseCheck} from "../../utils";
import AutoItem from "./AutoItem";


type Props = {
    className?: string
    series?: string
    onChange?(data: IAutoListItem): void
}

const AutoModelList: React.FC<Props> = ({series, className, onChange}) => {

    const [gearList, setGearList] = useState<IAutoListItem[]>([])

    useLayoutEffect(() => {
        series && fetchChooseAutoModelList(series).then(res => {
            apiResponseCheck<IAutoListItem[]>(res).then(data => {
                setGearList(data)
            })
        })
    }, [series])

    return <ScrollContainer className={className}>
        {gearList.map(item => <AutoItem key={item.key} data={item} onClick={onChange}/>)}
    </ScrollContainer>
}

export default AutoModelList
