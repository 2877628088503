import * as React from 'react'
//@ts-ignore
import Styles from './HotTag.scss'
import {GCNAlias} from "../../utils";
import {EStyleClass} from "../../types";


const HotTag: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({children, className, ...attr}) => {

    return <div {...attr} className={GCNAlias(Styles.container, EStyleClass.TextClick, className)}>{children}</div>
}

export default HotTag
