import * as React from 'react'
// @ts-ignore
import Styles from "./AutoListTip.scss";
import {Button, Checkbox, Modal} from "antd";
import {useState} from "react";
import {delAutoListAction, IUserAutoItem} from "../../service/My";
import {generateClassName} from "../../utils";
import {CheckOutlined} from "@ant-design/icons";
import {IStoreContext} from "../Context/StoreContext";
import ScrollContainer from "../ScrollContainer";
import ModalFooter from "../ModalFooter";
import {EStyleClass} from "../../types";
import EmptyBox from "../EmptyBox";

type Props = IStoreContext & {
    visible?: boolean,
    onClick?(): void
    onCancel?(show: boolean): void
}

const UserAutoModal: React.FC<Props> = ({visible, store, setStore, onClick, onCancel}) => {

    const [checkMode, setCheckMode] = useState(false),
        [checkedList, setCheckedList] = useState<string[]>([]),

        autoList: IUserAutoItem[] = store.LoginData.AutoList,
        indeterminate = !!checkedList.length && autoList.length !== checkedList.length,
        isCheckAll = !!autoList.length && autoList.length === checkedList.length,
        _onCancel = () => onCancel && onCancel(false),
        _onClick = () => {
            onClick && onClick()
            _onCancel()
        },
        onCheckedAuto = (data: IUserAutoItem) => () => {
            if (!checkMode) {
                store.LoginData.AutoList.forEach(item => {
                    item.IsSelect = item.GearboxParam === data.GearboxParam
                })
                //store.LoginData.AutoList = autoList
                setStore(store)
                //message.success('已选')
                _onCancel()
            }
        },

        onClearAuto = () => {
            if (!checkMode) {
                store.LoginData.AutoList.forEach(item => {
                    item.IsSelect = false
                })
                //store.LoginData.AutoList = autoList
                setStore(store)
                //message.success('已清除')
                _onCancel()
            }
        },

        onAllCheckBoxChange = () => {
            setCheckedList(isCheckAll ? [] : autoList.map(item => item.GearboxParam))
        },

        onAddNewCar = () => {
            _onClick()
        },

        onDeleteAuto = () => {
            if (checkedList.length) {
                const delList = autoList.filter(item => checkedList.some(id => id === item.GearboxParam)),
                    delIds = checkedList.filter(item => !!item),
                    gearboxParams = delList.map(item => item.GearboxParam).filter(item => item)
                if (store.LoginData.SCCode) {
                    delAutoListAction(delIds.join(','), gearboxParams.join(','))
                }

                store.LoginData.AutoList = autoList.filter(item => !checkedList.some(id => id === item.GearboxParam))
                setStore(store)
            }
        }

    const uiList = autoList.map(item => {
        const checked = !checkMode && item.IsSelect
        const title = <div className={Styles.autoNameWrap}>
            <div className={generateClassName(item.GearboxParam ? Styles.autoName2 : Styles.autoName, 'text-primary-click')}>
                {item.AutoName}
            </div>
            {item.SalesVersion ? <div className={Styles.autoSeries}>{item.SalesVersion}</div> : null}
        </div>
        return <div key={item.GearboxParam} className={generateClassName(Styles.autoItem, {[Styles.active]: checked})}
                    onClick={onCheckedAuto(item)}>
            {checked ? <CheckOutlined className={generateClassName(Styles.icon, 'primary-color')}/> : null}
            {checkMode ? <Checkbox className={'flexBox'} value={item.GearboxParam}>{title}</Checkbox> : title}
        </div>
    })

    return <>
        <Modal visible={visible} onCancel={_onCancel} title={'车型管理'}
               footer={!!autoList.length && <ModalFooter className={EStyleClass.FlexBox}>

                   {checkMode && <Checkbox indeterminate={indeterminate} checked={isCheckAll}
			                               onChange={onAllCheckBoxChange}>全选</Checkbox>}

                   {!checkMode && <Button disabled={!autoList.some(item => item.IsSelect)} type={"text"}
			                              onClick={onClearAuto}>清除已选</Button>}

			       <div className={'autoFlex'}>
				       <Button onClick={() => setCheckMode(!checkMode)}>{checkMode ? '取消' : '编辑'}</Button>
				       <Button type={checkMode ? undefined : "primary"}
				               onClick={checkMode ? onDeleteAuto : onAddNewCar}>{checkMode ? '删除' : '新增车型'}</Button>
			       </div>
		       </ModalFooter>}>

            <ScrollContainer className={generateClassName(Styles.content, 'autoFlex')}>
                {checkMode ? <Checkbox.Group value={checkedList}
                                             onChange={(e) => setCheckedList(e as string[])}>{uiList}</Checkbox.Group> : uiList}

                {!autoList.length && <EmptyBox style={{marginTop: 40}} title={'抱歉您还未添加过车型，快去新增车型吧～'}>
					<Button type={"primary"} onClick={onAddNewCar}>新增车型</Button>
				</EmptyBox>}
            </ScrollContainer>
        </Modal>
    </>
}

export default UserAutoModal
