import {isFormData, isObject} from "./index";
import smartJson from "./smartJson";
import {requestFomData, requestGet, requestPost} from "./request";

enum EMethod {
    POST = "post",
    GET = "get"
}

enum EDataType {
    json = 'json',
    text = 'text'
}

enum Error {
    ERR_1 = 'not found url'
}

interface IPageRequest {
    pageindex: number
    pagesize?: number
    total?: number,
    pagecount?: number
}

export interface AjaxOption {
    headers?: object | string,
    dataType?: EDataType,
    method?: EMethod,
    responseType?: EDataType,
    animation?: boolean | number,
    apiUrl?: string,
    apiUrlUnit?: string
    url: string,
    data?: any
    page?: IPageRequest
    onUploadProgress?: (event: any) => void
}

const Ajax = <T>(options: AjaxOption) => {

    /^\//.test(options.url) || (options.url = `/${options.url}`);
    /.ashx$/i.test(options.url) || (options.url += '.ashx');

    const {data, page, url, method} = options

    if (isFormData(data)) {
        page && data.append('page', smartJson(page))
        return requestFomData<T>(url, data)
    } else {
        const _data = isObject(data) ? {...data, ...(page || {})} : data

        if (method === EMethod.GET) {
            return requestGet<T>(url, _data)
        } else {
            return requestPost<T>(url, _data)
        }
    }
}

export default Ajax
