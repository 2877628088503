import * as React from 'react'
//@ts-ignore
import Styles from './ScrollContainer.scss'
import {GCNAlias, getAnimationFrame, mixStyles} from "../../utils";
import {useEffect, useLayoutEffect, useRef} from "react";


export type IPScrollProps = React.HTMLAttributes<HTMLDivElement> & {

    type?: boolean | 'x' | 'y',
    width?: number | string,
    height?: number | string,

    fetchNode?(node: HTMLDivElement): void,

    onScroll?(data: { x: number, y: number }, clientRectTop: number): void

    onEndReached?(): void

    onEndReachedThreshold?: number
    scroll?: { x?: number, y?: number }
}

const _animationFrame = getAnimationFrame()

const ScrollContainer: React.FC<IPScrollProps> = ({
                                                      className,
                                                      onEndReachedThreshold = 0,
                                                      onEndReached,
                                                      onScroll,
                                                      children,
                                                      scroll,
                                                      width, height,
                                                      style,
                                                      type = true,
                                                      ...attr
                                                  }) => {
    const element = useRef<HTMLDivElement | null>(null),

        innerOnScroll = (e: React.UIEvent<HTMLDivElement>) => {
            _animationFrame(_onScroll(e))
        },

        getBoundingClientRect = () => {
            const data = element.current?.getBoundingClientRect()
            return data ?? {top: 0, left: 0}
        },

        _onEndReached = () => {
            const div = element.current
            const _onEndReachedThreshold = 1 - (onEndReachedThreshold > 1 ? 1 : onEndReachedThreshold < 0 ? 0 : onEndReachedThreshold)

            if (div && div.getBoundingClientRect().height + div.scrollTop >= div.scrollHeight * _onEndReachedThreshold) {
                onEndReached && onEndReached()
            }
        },

        _onScroll = (e: React.UIEvent<HTMLDivElement>) => () => {
            const scrollTop = element.current?.scrollTop || 0,
                scrollLeft = element.current?.scrollLeft || 0,

                clientRect = getBoundingClientRect()

            onScroll && onScroll({
                x: scrollLeft + clientRect.left,
                y: scrollTop + clientRect.top
            }, clientRect.top)
            onEndReached && _onEndReached()
        },

        setScroll = (data: { x?: number, y?: number }) => {
            element.current?.scrollTo({left: data.x, top: data.y, behavior: 'smooth'})
        }

    /*setShowModal = (showModal?: boolean) => {
        if (element.current) {
            if (showModal) {
                element.current.style.overflow = 'visible'
                element.current.style.overflowY = 'visible'
            } else {
                element.current.style.overflow = ''
                element.current.style.overflowY = ''
            }
        }
    }*/

    useLayoutEffect(() => {
        scroll && setScroll(scroll)
    }, [scroll])
    //console.log(style,'scroll')
    return <div {...attr} style={mixStyles(style, {width, height})}
                className={GCNAlias(type === "x" ? Styles.scrollX : type ? Styles.scroll : false, className)}
                onScroll={innerOnScroll} ref={element}>
        {children}
    </div>
}

export default ScrollContainer
