import * as React from 'react'
//@ts-ignore
import Styles from './UploadInput.scss'
import {Upload} from "antd";
import {UploadChangeParam, UploadProps} from "antd/lib/upload/interface";
import {UploadRequestOption} from "rc-upload/lib/interface";
import {EUploadType, IUploadItem} from "../service/Common";
import {apiResponseCheck, isFunction} from "../utils";
import {checkAxiosResponse, execAxios, generateFrom} from "../utils/request";
import {ReactNode, useLayoutEffect, useState} from "react";
import {UploadFile} from "antd/es/upload/interface";

type ChildrenFunc = (data: UploadFile<IUploadItem>[]) => ReactNode | undefined

type Props = Omit<UploadProps, 'onChange'>
    & { uploadType: EUploadType, value?: IUploadItem[], onChange?: (data: IUploadItem[]) => void, children?: ReactNode | ChildrenFunc }

const UploadInput: React.FC<Props> = ({value, maxCount, children, onChange, uploadType, ...attr}) => {

    const [valueList, setValueList] = useState<UploadFile<IUploadItem>[]>([]),
        onInnerChange = (info: UploadChangeParam<UploadFile<IUploadItem>>) => {
            setValueList(info.fileList)
            onChange && onChange(info.fileList.filter(item => item.status === "done" && item.response).map(item => item.response) as IUploadItem[])
        }


    useLayoutEffect(() => {

        const newValues: UploadFile<IUploadItem>[] = (value ? Array.isArray(value) ? value : [value] : value ?? []).map(item => ({
                name: item.ImgName || item.ImgUrl.split('/').pop() || '',
                url: item.ImgUrl,
                status: 'done',
                uid: item.ImgName || item.ImgUrl,
                response: item
            })),
            list = newValues.filter(item => {
                const index = valueList.findIndex(v => v.response?.ImgName === item.name)
                if (index >= 0) {
                    valueList[index] = {...valueList[index], ...item}
                }
                return index < 0
            })

        setValueList([...valueList.filter(v => v.status !== "done" || newValues.some(item => item.name === v.response?.ImgName)), ...list])
    }, [value])

    const customRequest = (options: UploadRequestOption) => {
        execAxios('/common/uploadimg.ashx', generateFrom({
            file: options.file,
            sourcetype: uploadType
        }, true), 'post', "normal", 'application/x-www-form-urlencoded', options.onProgress)
            .then(response => {
                checkAxiosResponse<IUploadItem>(response).then(res => {
                    apiResponseCheck<IUploadItem>(res).then(data => {
                        options.onSuccess && options.onSuccess(data, response.request)
                    }).catch(e => options.onError && options.onError(e, ''))
                }).catch(e => options.onError && options.onError(e, ''))
            }).catch(e => options.onError && options.onError(e, ''))
    }

    return <Upload {...attr} maxCount={maxCount} fileList={valueList} customRequest={customRequest}
                   onChange={onInnerChange}>
        {valueList.length <= (maxCount ?? 9999) ? isFunction(children) ? children(valueList) : children : null}
    </Upload>
}

export default UploadInput
