import {IAjaxResponse, requestFomData, requestPost} from "../utils/request";
import {EHomeCategoryType} from "./Home";
import {apiResponseCheck, getEmptyListData, isEmpty, isFunction, isObject} from "../utils";
import {IListData} from "../types";
import {useLayoutEffect, useState} from "react";
import {RcFile} from "antd/es/upload";

export enum EStatus {
    enabled = 1,
    disabled = 0,
}


//分类对应的车类别
export enum ECategoryAutoType {
    //乘用车
    usual = 1,
    //商用车
    business = 2
}

/*发送短信验证码*/
export enum ISendType {
    // 注册验证码
    REGISTER = 2,
    //登录验证码
    LOGIN = 1,
    // 忘记密码
    FORGET = 3
}

export const sendVerifyCode = (Phone: string, SendType = ISendType.LOGIN) => requestPost('/common/sendverifycode.ashx', {
    Phone,
    SendType
})

/*获取地区*/
export enum EAreaType {
    Country = 1,
    Province = 2,
    City = 3,
    County = 4,
}

export interface IAreaItem {
    alphabet: string
    key: string | number
    value: string,
    children?: IAreaItem[]
    isLeaf?: boolean
}

export const fetchArea = (data: {
    AreaType: EAreaType,
    ParentID?: number
}) => requestPost<IAreaItem[]>('/common/getarea.ashx', data)

/*获取车型*/
export enum EAutoType {
    Brand = 1,
    Serise = 2,
    Model = 3,
    Year = 4,
    Version = 5,
    VIN = 6,
    ByCategory = 7,
    SeriseByType = 9,
    TransmissionType = 10,
    Ver = 11
}

export interface IAutoRequest {
    AutoType: EAutoType
    VinCode?: string
    ParentValue?: string | number
    AutoModelID?: number
}

export interface IAutoListItem {
    alphabet: string
    autoname: string
    autotype: ECategoryAutoType
    child: IAutoListItem[]
    icon: string
    key: string
    showname: string
    value: string
}

export interface IAutoGearItem {
    GearboxParam: string,
    Year: string,
    AutoName: string
}

const fetchAutoList = <T>(data: IAutoRequest) => requestPost<T>('/common/getauto.ashx', data)

export const fetchChooseAutoBrandList = () => fetchAutoList<IAutoListItem[]>({AutoType: EAutoType.ByCategory})

export const fetchChooseAutoSeriesList = (ParentValue: string) => fetchAutoList<IAutoListItem[]>({
    AutoType: EAutoType.SeriseByType,
    ParentValue
})

export const fetchChooseAutoModelList = (ParentValue: string) => fetchAutoList<IAutoListItem[]>({
    AutoType: EAutoType.Model,
    ParentValue
})

export const fetchChooseYearList = (ParentValue: string) => fetchAutoList<IAutoListItem[]>({
    AutoType: EAutoType.Year,
    ParentValue
})

export const fetchAutoMainGearList = (ParentValue: string, AutoModelID: string | number) => fetchAutoList<IAutoListItem[]>({
    AutoType: EAutoType.TransmissionType,
    ParentValue,
    AutoModelID: Number(AutoModelID)
})

export const fetchChooseAutoVerList = (ParentValue: string) => fetchAutoList<IAutoListItem[]>({
    AutoType: EAutoType.Ver,
    ParentValue
})


export const fetchAutoListFromVin = (VinCode: string) => fetchAutoList<{ GearboxList: IAutoGearItem[] }>({
    AutoType: EAutoType.VIN,
    VinCode
})

//export const fetchBankList = () => requestPost('/common/getbank.ashx')


//获取分类类型
export enum EFetchCategoryType {
    //1:一级分类
    First = 1,
    //2:二级分类
    Second = 2,
    //3:三级分类
    Third = 3,
    //4:快速搜索三级分类
    SearchThird = 4,
    //5.快速搜索分类
    Search = 5,
}

//获取商品分类
export enum ECategoryShow {
    //0.系统分类
    def = 0,
    //1.自定义分类
    custom = 1,
    //2.是否分组
    pack = 2,
}


export interface ICategoryRequest {
    CategoryType: EFetchCategoryType
    CategoryShow?: ECategoryShow,
    CategoryName?: string
    ParentID?: number
}

//分类级别
export enum ECategoryLevel {
    First = 1,
    Second = 2,
    Third = 3,
}

export interface ICategoryItem {
    uniqueId: string
    key: number
    value: string
    parentid: number
    icon: string
    level: ECategoryLevel
    categorytype?: ECategoryAutoType
    categoryshow: ECategoryShow,
    remarklist?: {
        id: number,
        name: string
    }[]
}

export interface ICategoryTreeItem extends ICategoryItem {
    children?: ICategoryTreeItem[]
}


export const generateUniqueId = (data: ICategoryItem) => {
    data.uniqueId = `${data.key}|${data.categoryshow}`
    return data
}

export const getCategoryKeyByUniqueId = (uniqueId: string): number => {
    return Number(uniqueId.split('|')[0] || 0)
}

export const getCategorySHowByUniqueId = (uniqueId: string): ECategoryShow => {
    return Number(uniqueId.split('|')[1] || 0)
}

type IFetchListCategoryParamsProps = { ParentCategoryID?: string, CategoryID?: string, PartsID?: string, CategoryShow?: number }

export const generateFetchListCategoryParams = (data: IFetchListCategoryParamsProps = {}) => {
    const CategoryShow = data.CategoryShow ? data.CategoryShow : data.PartsID ? getCategorySHowByUniqueId(data.PartsID)
            : data.CategoryID ? getCategorySHowByUniqueId(data.CategoryID)
                : data.ParentCategoryID ? getCategorySHowByUniqueId(data.ParentCategoryID) : data.CategoryShow,
        result = {
            ParentCategoryID: data.ParentCategoryID ? getCategoryKeyByUniqueId(data.ParentCategoryID) : undefined,
            CategoryID: data.CategoryID ? getCategoryKeyByUniqueId(data.CategoryID) : undefined,
            PartsID: data.PartsID ? getCategoryKeyByUniqueId(data.PartsID) : undefined,
            CategoryShow,
        }
    return (Object.keys(result) as (keyof typeof result)[]).filter(key => !isEmpty(result[key])).reduce((obj, key) => {
        obj[key] = result[key]
        return obj
    }, {} as typeof result)
}

export const fetchCategoryList = async (data: ICategoryRequest) => {
    const res = await requestPost<ICategoryItem[]>('/common/getitemcategory.ashx', data)
    res.body = res.body.map(item => generateUniqueId(item))
    return res
}

export const filterAutoList = (list: ICategoryTreeItem[], showType: ECategoryAutoType | EHomeCategoryType = EHomeCategoryType.all) => {
    //console.log(list, showType, 'filterAutoList')
    if (showType === EHomeCategoryType.all) {
        return list
    } else {
        //console.log(list.filter(item => item.categorytype === showType))
        return list.filter(item => item.categorytype === showType)
    }
}

export const fetchFirstCategoryList = async (): Promise<ICategoryTreeItem[]> => {
    try {
        const res = await fetchCategoryList({
            CategoryType: EFetchCategoryType.First,
        })

        return await apiResponseCheck<ICategoryTreeItem[]>(res)
        //return filterAutoList(list, showType)
    } catch (e) {
        return []
    }
}

export const fetchChildCategoryList = async (ParentID: string, CategoryType = EFetchCategoryType.Second): Promise<ICategoryTreeItem[]> => {
    try {
        const res = await fetchCategoryList({
            CategoryType,
            ParentID: getCategoryKeyByUniqueId(ParentID),
            CategoryShow: getCategorySHowByUniqueId(ParentID),
        })

        return await apiResponseCheck<ICategoryTreeItem[]>(res)
    } catch (e) {
        return []
    }
}


/*上传文件*/
export enum EUploadType {
    /// 临时图片
    /// </summary>
    fromTempPic = -100,

    /// <summary>
    /// 临时文件
    /// </summary>
    fromTempFile = -101,

    /// <summary>
    /// 奖品图片
    /// </summary>
    fromBigWheelPrize = 1000,

    /// <summary>
    /// 广告图片
    /// </summary>
    fromAdvertisement = 1001,

    /// <summary>
    /// 车型品牌图标
    /// </summary>
    autobrand = 1002,

    /// <summary>
    /// 车系图标
    /// </summary>
    autoseries = 1003,

    /// <summary>
    /// 商品品牌图标
    /// </summary>
    fromItemBrand = 1004,

    /// <summary>
    /// 商品图片
    /// </summary>
    fromItem = 1005,

    /// <summary>
    /// 配件分类图片
    /// </summary>
    partscategory = 1006,

    /// <summary>
    /// 子车型图片
    /// </summary>
    autoimage = 1007,

    /// <summary>
    /// 主车型图片
    /// </summary>
    automodelimage = 1008,

    /// <summary>
    /// 配件图片
    /// </summary>
    parts = 1009,

    /// <summary>
    /// 店铺图片
    /// </summary>
    shopimg = 1010,

    /// <summary>
    /// 证件图片
    /// </summary>
    certificates = 1011,

    haadpoint = 1207,

    paycertificate = 1209
}

export interface IUploadItem {
    ImgName: string
    ImgUrl: string
    process?: number
    bigUrl?: string
    ImageList?: IUploadItem[]
}

export declare type BeforeUploadFileType = File | Blob | boolean | string;

export const uploadFile = (data: {
    file: Exclude<BeforeUploadFileType, File | boolean> | RcFile,
    sourcetype: EUploadType,
    width?: number,
    height?: number
}) => requestFomData<IUploadItem>('/common/uploadimg.ashx', data)


/*获取车型名称*/
export enum EAutoNameSearchType {
    detail = 0,
    brand = 1,
    series = 2,
}

export const fetchCommonAutoName = (AutoID: number, SearchType = EAutoNameSearchType.detail) => requestPost<string>('/common/getautoname.ashx', {
    AutoID,
    SearchType
})

export const fetchAutoNameAction = (AutoID: number) => fetchCommonAutoName(AutoID, EAutoNameSearchType.detail)

export const fetchAutoSeriesNameAction = (AutoID: number) => fetchCommonAutoName(AutoID, EAutoNameSearchType.series)

export const fetchAutoBrandNameAction = (AutoID: number) => fetchCommonAutoName(AutoID, EAutoNameSearchType.brand)


/*OCR识别*/
export const fetchVINFromOCR = (file: File | Blob) => {
    return requestFomData<{
        //0.失败 1.成功
        status: 0 | 1
        message: string
        vin: string
    }>('/common/ocrdistinguish.ashx', {file})
}


/*获取配件报价*/
export interface IDRPOrderDetail {
    QuoteCode: string;
    InquiryNo: string;
    ReportCode: string;
    InsuranceName: string;
    FactoryName: string;
    ShopName: string;
    ContactMobile: string;
    IsExpires?: number;
    ItemList: IDRPOrderDetailItem[]
}

export interface IDRPOrderDetailItem {
    PartName: string;
    PartCode: string;
    OriginalPrice: number;
    InlandBrand: string;
    InlandPrice: number;
    ForeignBrand: string;
    ForeignPrice: number;
    GuaranteePeriod: number;
}

export const fetchDRPOrderDetail = (QuoteCode: string) => requestPost<IDRPOrderDetail>('/common/getpartscode.ashx', {QuoteCode})


/*获取分类名称*/
export interface ICategoryNameData {
    PCategoryID: number,
    CategoryID: number,
    PartsID: number,
    GroupID?: number,
    CategoryShow: ECategoryShow,
    CategoryShow1: ECategoryShow,
    CategoryShow2: ECategoryShow,
    CategoryName: string,
    IsMatching: number
}

export const fetchCategoryName = (CategoryID: number, CategoryShow: ECategoryShow = ECategoryShow.def, Level: number = 1) =>
    requestPost<ICategoryNameData>('/common/getcategoryname.ashx', {
        CategoryID,
        Level,
        CategoryShow
    })


type IFunc<T, S = T> = (data: T[]) => S[]
type IFetchCheck<S> = (searchData: S) => boolean
type ISelectFunc<T> = (data: T[], selectedKeys: any[]) => any[]


interface IOptions<T, S = any> {
    combine?: boolean | 0 | 1
    resolve?: IFunc<T>,
    fetchCheck?: IFetchCheck<S> | boolean
    selectedKeys?: ISelectFunc<T> | (any[])
    initSearchData?: S

    onChange?(): void
}


export const fetchRFComponentList = function <T = any, S = any>(this: any, api: (data: any) => Promise<IAjaxResponse<T[] | { list: T[] }>>, options?: IOptions<T, S> | IFunc<T>) {
    const isObj = isObject<IOptions<T>>(options)
    //@ts-ignore
    const [searchData, setSearchData] = useState.bind(this)<S>(isObj ? options.initSearchData || ({} as any) : ({} as any))
    //@ts-ignore
    const [state, _setState] = useState.bind(this)<{ loading: boolean, listData: IListData<T>, selectedRowKeys: any[] }>({
        listData: getEmptyListData(),
        loading: false,
        selectedRowKeys: []
    })

    const setState = (data: { loading?: boolean, listData?: IListData<T>, selectedRowKeys?: any[] }) => {
        _setState({...state, ...data})
    }

    const fetchList = function (pageNum: number = 1, pageSize: number = state.listData.pageSize) {
        state.loading || setState({loading: true})
        api({pageSize, pageNum, ...searchData}).then(res => {
            apiResponseCheck(res, true).then(listData => {
                let selectedRowKeys = [],
                    func = (isFunction(options) && options) || (isObject<IOptions<T>>(options) && options.resolve),
                    list = Array.isArray(listData) ? listData : listData.list

                if (func) {
                    list = func(list)
                }

                if (isObj) {
                    if (options.selectedKeys) {
                        selectedRowKeys = isFunction(options.selectedKeys) ? options.selectedKeys(list, state.selectedRowKeys || []) : options.selectedKeys
                    }

                    if (options.combine && pageNum > 1) {
                        list = [...state.listData.list, ...list]
                    }
                }
                //isObject<IOptions>(options) && options.resolve && options.resolve(listData)

                setState({
                    listData: {
                        pageNum: res.page.pageindex,
                        pageSize: res.page.pagesize,
                        totalPage: res.page.pagecount,
                        total: res.page.total,
                        list
                    }, loading: false, selectedRowKeys
                })
                isObject<IOptions<T>>(options) && options.onChange && options.onChange()
            }).catch(() => {
                setState({listData: getEmptyListData(), loading: false, selectedRowKeys: []})
                isObject<IOptions<T>>(options) && options.onChange && options.onChange()
            })
        })
    }

    useLayoutEffect.bind(this)(() => {
        //console.log(searchData)
        if (isObj && (isFunction(options.fetchCheck) || typeof options.fetchCheck === "boolean")) {
            (isFunction(options.fetchCheck) && options.fetchCheck(searchData) || options.fetchCheck) && fetchList()
        } else {
            fetchList()
        }
    }, [searchData])

    return {
        state, setState, searchData, setSearchData, tableOptions: {
            loading: state.loading, dataSource: state.listData.list, pagination: {
                total: state.listData.total,
                current: state.listData.pageNum,
                pageSize: state.listData.pageSize,
                onChange: fetchList,
                onShowSizeChange: fetchList,
            }
        }, fetchList, onSelectedRowKeysChange: (selectedRowKeys: any[]) => setState({...state, selectedRowKeys})
    }
}
