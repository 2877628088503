import * as React from 'react'
import ScrollContainer from "../ScrollContainer";
import AutoItem from "./AutoItem";
import {fetchAutoMainGearList, IAutoListItem} from "../../service/Common";
import {useLayoutEffect, useState} from "react";
import {apiResponseCheck} from "../../utils";

type Props = {
    className?: string
    year?: string,
    model?: string
    onChange?(data: IAutoListItem): void
}

const MainGearList: React.FC<Props> = ({className, year, model, onChange}) => {

    const [gearList, setGearList] = useState<IAutoListItem[]>([])

    useLayoutEffect(() => {
        year && model && fetchAutoMainGearList(year, model).then(res => {
            apiResponseCheck<IAutoListItem[]>(res).then(data => {
                data.length === 1 ? onChange && onChange(data[0]) : setGearList(data)
            })
        })
    }, [year, model])

    return gearList.length > 1 ? <ScrollContainer className={className}>
        {gearList.map(item => <AutoItem key={item.key} data={item} onClick={onChange}/>)}
    </ScrollContainer> : null
}

export default MainGearList
