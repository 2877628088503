import * as React from 'react'
//@ts-ignore
import Styles from './EmptyBox.scss'
import {Image} from "antd";
import {GCNAlias} from "../../utils";

type Props = React.HTMLAttributes<HTMLDivElement> & {
    title?: string
    content?: string
    size?: 'large'
}

const EmptyBox: React.FC<Props> = ({content, title, size, children, className, ...attr}) => {

    return <div className={GCNAlias(Styles.container, className)} {...attr}>
        <Image preview={false} src={require('../../assets/images/img_waiting_quotation.png')}
               className={size === "large" ? Styles.largeImage : Styles.img}/>
        {!!title && <div className={size === "large" ? Styles.largeTitle : Styles.title}>{title}</div>}
        {content && <div className={Styles.content}>{content}</div>}

        {children ? <div className={Styles.children}>
            {children}
        </div> : null}
    </div>
}

export default EmptyBox
