import * as React from 'react'
import {fetchChooseYearList, IAutoListItem} from "../../service/Common";
import ScrollContainer from "../ScrollContainer";
import {useLayoutEffect, useState} from "react";
import {apiResponseCheck} from "../../utils";
import AutoItem from "./AutoItem";


type Props = {
    className?: string
    model?: string
    onChange?(data: IAutoListItem): void
}

const AutoYearList: React.FC<Props> = ({className, model, onChange}) => {

    const [yearList, setYearList] = useState<IAutoListItem[]>([])

    useLayoutEffect(() => {
        model && fetchChooseYearList(model).then(res => {
            apiResponseCheck<IAutoListItem[]>(res).then(data => {
                setYearList(data)
            })
        })
    }, [model])

    return <ScrollContainer className={className}>
        {yearList.map(item => <AutoItem key={item.key} data={item} onClick={onChange}/>)}
    </ScrollContainer>
}

export default AutoYearList
