import {IAjaxBodyStatus, requestFomData, requestPost} from "../utils/request";
import {ECategoryAutoType} from "./Common";
import {RcFile} from "antd/es/upload";
import {EAfterSalesApplyType, EAfterSalesGoodsStatus, EQualityType} from "./Item";
import {EGoodsLevel, EGoodsPost} from "./ShoppingCart";
import {hackAmount, hackNumber} from "../utils";
import {ISelectOption} from "../types";
import {ESettlementType} from "./Login";
import {IScoreData} from "../components/CalculationScore";
import {ICanUseCouponItem} from "./Coupon";
import {EInvoiceType} from "./Invoice";

export enum EPayType {
    Online = 1,
    Offline = 2,
    Payed = 3,
    Month = 4,
}

export const PayTypeOptions: ISelectOption[] = [
    {value: EPayType.Online, label: '线上支付'},
    {value: EPayType.Offline, label: '货到付款'},
    {value: EPayType.Payed, label: '线下付款'},
    {value: EPayType.Month, label: '月结'},
]

export enum ESubmitOrderType {
    Common = 1,
    Quotation = 2,
}


/*获取快速采购单默认地区*/
export interface IQuickOrderArea {
    provinceid: number
    provincename: string
    cityid: number
    cityname: string
    areaid: number
    areaname: string
}

export const fetchQuickOrderArea = () => requestPost<IQuickOrderArea>('/order/getquickorderarea.ashx')

/*获取询价设置*/
export interface IQuickOrderSetting {
    AutoEnabled: number,
    AutoMust: number,
    PartsOEShow: number
    PartsMust: number
    OEMust: number,
    /*0全部
1仅图片询价
2仅手动询价*/
    OrderMode: 0 | 1 | 2,
    ExcelEnabled: number
    /*品质设置
1.原厂
2.品牌
3.拆车*/
    QualityTypes: string
}

export const getEmptyQuickOrderSetting = (): IQuickOrderSetting => ({
    AutoEnabled: 0,
    AutoMust: 0,
    PartsOEShow: 0,
    PartsMust: 0,
    OEMust: 0,
    OrderMode: 0,
    ExcelEnabled: 0,
    QualityTypes: ''
})

export const fetchOrderSetting = () => requestPost<IQuickOrderSetting>('/order/getordersetting.ashx')


/*添加快速采购单*/

export enum EAddOrderType {
    images = 1,
    part = 2
}

export interface IQuickPart {
    index?: number
    PartsName: string
    OENO: string
    QualityType: string
    Quantity: number,
    checked?: boolean
    disabled?: boolean
}

export interface IQuickRequest {
    OrderType: EAddOrderType
    SubGroupID: number
    AutoName?: string
    AutoBrandID: number
    AutoSeriesID: number
    AutoBrandName?: string
    ExpectedDate: string
    InvoiceType: EInvoiceType
    ProvinceID: number
    CityID: number
    AreaID: number
    Remark: string
    Images: string
    PartList: IQuickPart[]
    VinNo: string
    QualityType: string,
    addMode?: 1 | 2,
    GearboxParam: string
    fromPurchase: string
    DownUrl?: string
}

export const submitQuickOrderAction = (data: IQuickRequest) => requestPost<{ OrderCode: string }>('/order/addquickorder.ashx', data)


/*订单信息验证*/
export interface ICustomerPayTypeResponse {
    PayType?: string
    CreditAmount?: number
    IsNoSmallAmount?: number
    InvoiceType?: string
}

export const getEmptyCustomerPayType = (): ICustomerPayTypeResponse => ({})

export type IPayTypeData = {
    settlementType: ESettlementType,
    invoiceTypeList: number[],
    payType: number,
    isMonthAccount: boolean,
    isNoSmallAmount: boolean,
    payOptions?: ISelectOption[]
}

export const getPayData = (payTypeData: ICustomerPayTypeResponse, payAmount: number = 0): IPayTypeData => {
    const payTypeArr = (payTypeData.PayType || '').split(',').map(x => Number(x))

    const monthPayIndex = payTypeArr.findIndex(id => id === 4)

    const invoiceTypeList: EInvoiceType[] = (payTypeData.InvoiceType || '').split(',').map(x => Number(x))
    let settlementType,
        payType = 0,
        payOptions: ISelectOption[] | undefined

    //this.isMonthAccount = monthPayIndex >= 0

    //this.isNoSmallAmount = !!data.IsNoSmallAmount

    if (monthPayIndex >= 0 && payAmount <= (payTypeData.CreditAmount ?? 0)) {
        settlementType = ESettlementType.Month
        //this.setState({SettlementType: SettlementType.Month, invoiceType, loading: false})
    } else {
        monthPayIndex >= 0 && payTypeArr.splice(monthPayIndex, 1)

        payOptions = PayTypeOptions.filter(item => !!payTypeArr.find((type) => type === item.value))
        if (payOptions.length === 1) {
            payType = Number(payOptions[0].value)
        }

        settlementType = ESettlementType.Now
    }

    return {
        payOptions,
        settlementType,
        invoiceTypeList,
        isMonthAccount: monthPayIndex >= 0,
        isNoSmallAmount: !!payTypeData.IsNoSmallAmount,
        payType
    }
}

export type ITotalSumItem = {
    ItemID: number,
    ItemName: string,
    Quantity: number,
    Price: number
    Level?: EGoodsLevel
    IsFreePost?: EGoodsPost
}

export const getTotalSumData = (list: ITotalSumItem[], optinons: {
    //服务费
    serviceAmount?: number,
    //运费
    freightAmount?: number,
    //订单优惠金额
    discountAmount?: number,
    //附加金额
    additionalAmount?: number,
    //优惠券
    coupon?: ICanUseCouponItem,
    //抹零
    clearSmall?: boolean,
    //使用积分
    useScore?: boolean
    calculate?(amount: number): IScoreData
} = {}) => {
    let postNotFree: boolean = false
    let amount: number = 0
    //let payAmount: number = 0
    let sumQuantity: number = 0
    //let smallAmount: number = 0
    let OriginAmount = 0;
    let OriginNum = 0;
    let OriginCategory = 0;
    let BrandAmount = 0;
    let BrandNum = 0;
    let BrandCategory = 0;
    let DCarAmount = 0;
    let DCarNum = 0;
    let DCarCategory = 0;
    let ReCategory = 0;
    let ReNum = 0;
    let ReAmount = 0;
    const {
        serviceAmount = 0,
        additionalAmount = 0,
        freightAmount = 0,
        discountAmount = 0,
        clearSmall,
        coupon,
        useScore,
        calculate
    } = optinons

    list.forEach((item, index: number) => {

        //const {Images, ItemShowName, Quantity, OENO, Level, ItemID} = item


        if (!postNotFree && item.IsFreePost !== EGoodsPost.free) {
            postNotFree = true
        }

        amount += hackNumber(item.Price * item.Quantity)
        sumQuantity += item.Quantity
        if (item.Level === EGoodsLevel.host) {
            OriginNum += item.Quantity
            OriginCategory += 1
            OriginAmount += item.Price * item.Quantity
        } else if (item.Level === EGoodsLevel.brand) {
            BrandNum += item.Quantity
            BrandCategory += 1
            BrandAmount += item.Price * item.Quantity
        } else if (item.Level === EGoodsLevel.cc) {
            DCarNum += item.Quantity
            DCarCategory += 1
            DCarAmount += item.Price * item.Quantity
        } else if (item.Level === EGoodsLevel.recover) {
            ReNum += item.Quantity
            ReCategory += 1
            ReAmount += item.Price * item.Quantity
        }
    })


    const goodsAmount = hackAmount(OriginAmount + BrandAmount + DCarAmount + ReAmount),
        orderAmount = hackAmount(goodsAmount + serviceAmount + freightAmount),
        preAmount = hackAmount(orderAmount - (coupon?.ReduceAmount ?? 0)),
        scoreData = calculate && calculate(preAmount),
        _payAmount = hackAmount(preAmount - (scoreData?.enabled && useScore ? scoreData.amount : 0) - discountAmount + additionalAmount),
        noSmallAmount = clearSmall && _payAmount > 1 ?
            hackAmount(_payAmount - Math.floor(_payAmount)) : 0,
        payAmount = clearSmall ? Math.floor(_payAmount) : _payAmount

    return {
        goodsAmount,
        orderAmount,
        scoreData,
        payAmount: payAmount >= 0 ? payAmount : 0,
        noSmallAmount,
        postNotFree,
        amount,
        sumQuantity,
        OriginAmount,
        OriginNum,
        OriginCategory,
        BrandAmount,
        BrandNum,
        BrandCategory,
        DCarAmount,
        DCarNum,
        ReNum,
        ReCategory,
        ReAmount,
        DCarCategory,
    }
}

export const fetchCustomerPayTypeAction = (ItemList: ITotalSumItem[]) => requestPost<ICustomerPayTypeResponse>('/order/getordercheck.ashx', {ItemList})

/*提交订单*/
export interface ICouponListItem {
    CouponID: number
    CouponName: string
    SubTitle: string
    CouponType: number
    ReachAmount: number
    ReduceAmount: number
    PayType: number
    PayAmount: number
    PictorialImgUrl: string
    Status: number
    FinalAmount?: number
}


export interface ISubmitGoodsItem {
    ItemID: number
    Quantity: number
    SubGroupID: number
}

export interface IShopProjectItem {
    ShopProjectID: number
    ProjectName: string
    ServicePrice: number
    ServiceDetail?: string
    Checked?: boolean
}

export interface IReserveItem {
    CusName: string
    CusMobile: string
    ReserveTime: string
    TimeSection: string
}


export interface ISubmitOrderRequest {
    CouponInfo?: ICouponListItem
    InvoiceInfoID?: number,
    AddressID?: number
    /*    FreightType:number*/
    OrderRemark?: string
    PayType?: EPayType
    OrderType: ESubmitOrderType
    ItemList: ISubmitGoodsItem[]
    IsUseScore?: number
    UseScore?: number
    ScoreAmount?: number
    ShopID?: number
    ProjectList?: IShopProjectItem[]
    ReserveInfo?: IReserveItem
    ProvinceID?: number,
    CityID?: number,
    AreaID?: number
}

export const submitOrder = (data: ISubmitOrderRequest) => requestPost<{ OrderCode: string }>('/order/submitorder.ashx', data)

/*询价补全*/
export const fetchInquiryComplete = (searchkey: string, searchtype: number = 1) => requestPost<{ value: string, categorytype: ECategoryAutoType }[]>('/order/inquirycomplete.ashx', {
    searchkey,
    searchtype
})

/*询价导入*/
export interface InquiryImportResponse extends IAjaxBodyStatus {
    SubGroupID: number
    AutoName?: string
    GearboxParam?: string,
    Remark: string
    PartList: IQuickPart[]
    VinNo: string
    QualityType: string,
    DownUrl?: string
    OENO?: { QualityType: EQualityType, Quantity: number }[]
    FileName: string
}

export const inquiryImport = (file: RcFile | File) => requestFomData<InquiryImportResponse>('/order/inquiryimport.ashx', {file})


/*导出订单信息*/
export const exportOrderInfo = (data: { ordercode: string, isquote: number }) => requestPost<{ downurl: string, filename: string } & IAjaxBodyStatus>('/order/exportorderinfo.ashx', data)


/*导出保险报价excel*/
export const exportOrderExcel = (data: { ordercode: string }) => requestPost<{ downurl: string, filename: string } & IAjaxBodyStatus>('/order/exportorderexcel.ashx', data)

/*获取后台订单信息*/
export interface IBackOrderDetailRequest {
    ordercode: string,
    isprint?: number,
    isquotepic?: number
}

export interface IBackOrderDetailResponse {
    itemnum: number,
    itemtypenum: number,

    quoteurl: string,
    codepath: string,

    purchaseinfo: {
        brandname: string,
        vinno: string,
        expecteddate: string,
        invoicetype: number,
        alladress: string,
        auto: string,
        remark: string,
        remarkimgs: string[],
        images: string[],
        ispurchase: number,
    },

    basicinfo: {
        companyname: string,
        ordercode: string,
        relationcode: string,
        orderstatus: number,
        orderstatusstr: string,
        createtime: string,
        ordersource: number,
        ordersourcestr: string,
        paytype: number,
        cuspaytype: string,
        ordertype: number,
        paymentmethod: number,
        paymentmethodstr: string,
        paystatus: number,
        invoicetype: number,
        invoicetypestr: string,
        cusname: string,
        cusmobile: string,
        Ismonth: number,
        paytime: string,
        sendtime: string,
        signtime: string,
        completetime: string,
        logisticsmode: number,
        logisticsname: string,
        freighttype: number,
        freighttypestr: string,
        discountamount: number,
        additionalamount: number,
        orderamount: number,
        payamount: number,
        freightamount: number,
        remark: string,
        shopremark: string,
        itemnum: number,
        itemtypenum: number,
        itemamount: number,
        taxamount: number,
        cancelreason: string,
        shopname: string,
        shopaddress: string,
        shopmobile: string,
        operatorname: string,
        expressno: string,
        isreturnall: number,
        isdeleted: number,
        returnaddress: string,
        isnosmallamount: number,
        nosmallamount: number,
        iswaitout: number,
    },
    receiveinfo: {
        receiver: string,
        mobile: string,
        provinceid: number,
        cityid: number,
        areaid: number,
        address: string,
        alladdress: string,
    },
    quoteinfo: {},
    invoiceinfo: {
        invoicetype: number,
        invoicetitle: string,
        serialcode: string,
        registaddress: string,
        registphone: string,
        openbank: string,
        bankaccount: string,
    },

    itemlist: { partstype: number }[],
}

export const getEmptyBackOrderDetail = (): IBackOrderDetailResponse => ({
    itemnum: 0,
    itemtypenum: 0,

    quoteurl: '',
    codepath: '',

    purchaseinfo: {
        brandname: '',
        vinno: '',
        expecteddate: '',
        invoicetype: 0,
        alladress: '',
        auto: '',
        remark: '',
        remarkimgs: [],
        images: [],
        ispurchase: 0,
    },

    basicinfo: {
        companyname: '',
        ordercode: '',
        relationcode: '',
        orderstatus: 0,
        orderstatusstr: '',
        createtime: '',
        ordersource: 0,
        ordersourcestr: '',
        paytype: 0,
        cuspaytype: '',
        ordertype: 0,
        paymentmethod: 0,
        paymentmethodstr: '',
        paystatus: 0,
        invoicetype: 0,
        invoicetypestr: '',
        cusname: '',
        cusmobile: '',
        Ismonth: 0,
        paytime: '',
        sendtime: '',
        signtime: '',
        completetime: '',
        logisticsmode: 0,
        logisticsname: '',
        freighttype: 0,
        freighttypestr: '',
        discountamount: 0,
        additionalamount: 0,
        orderamount: 0,
        payamount: 0,
        freightamount: 0,
        remark: '',
        shopremark: '',
        itemnum: 0,
        itemtypenum: 0,
        itemamount: 0,
        taxamount: 0,
        cancelreason: '',
        shopname: '',
        shopaddress: '',
        shopmobile: '',
        operatorname: '',
        expressno: '',
        isreturnall: 0,
        isdeleted: 0,
        returnaddress: '',
        isnosmallamount: 0,
        nosmallamount: 0,
        iswaitout: 0,
    },
    receiveinfo: {
        receiver: '',
        mobile: '',
        provinceid: 0,
        cityid: 0,
        areaid: 0,
        address: '',
        alladdress: '',
    },
    quoteinfo: {},
    invoiceinfo: {
        invoicetype: 0,
        invoicetitle: '',
        serialcode: '',
        registaddress: '',
        registphone: '',
        openbank: '',
        bankaccount: '',
    },
    itemlist: [],
})

export const fetchBackOrderDetail = (data: IBackOrderDetailRequest) => requestPost<IBackOrderDetailResponse>('/order/getbackorderinfo.ashx', data)


/*提交售后*/
export interface IReturnGoodsItem {
    DetailID: number
    ItemID: number
    ReturnQuantity: number
}

export interface ISubmitReturnOrderRequest {
    OrderCode: string,
    ApplyType: EAfterSalesApplyType
    GoodType?: EAfterSalesGoodsStatus
    Reason: string
    Remark?: string
    Images?: string
    ReturnItem: IReturnGoodsItem[]
}

export const submitAfterSales = (data: ISubmitReturnOrderRequest) => requestPost('/order/applyreturnorder.ashx', data)
