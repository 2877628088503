import {requestPost} from "../utils/request";
import {ECompanyAuth, ECustomerType, EPersonalAuth, ESettlementType, ESex, IUserInfo} from "./Login";


/*我的信息*/
export interface IManageCenter {
    Name?: string
    Mobile: string
    Sex?: ESex
    VIPLevelName?: string
    CustomerType: ECustomerType
    SettlementType: ESettlementType
    PersonalAuth?: EPersonalAuth
    CompanyAuth?: ECompanyAuth
    MessageNum: number
    WaitOrderNum: number
    WaitPayNum: number
    WaitDeliveryNum: number
    WaitGoodsReceiptNum: number
    QuickOrderNum: number
    WaitConfirmNum: number
    Telphone: string
    AfterSaleNum: number
    viplevel?: number
    HeadIconUrl?: string

    ShowDistri: number
    /*
    * 分销状态
0.未申请
1.审核中
2.审批成功
3.审批失败
    * */
    DistriAuditStatus: 0 | 1 | 2 | 3
    DistriAuditStatusStr: string
    DistriReason: string
    SaleUserID: number
    SaleLevel: number
    SaleLevelName: string
    HasTeam: number
}

export const getEmptyManageCenter = (): IManageCenter => ({
    Mobile: '',
    CustomerType: ECustomerType.Personal,
    SettlementType: ESettlementType.Now,

    MessageNum: 0,
    WaitOrderNum: 0,
    WaitPayNum: 0,
    WaitDeliveryNum: 0,
    WaitGoodsReceiptNum: 0,
    QuickOrderNum: 0,
    WaitConfirmNum: 0,
    Telphone: '',
    AfterSaleNum: 0,


    ShowDistri: 0,
    DistriAuditStatus: 0,
    DistriAuditStatusStr: '',
    DistriReason: '',
    SaleUserID: 0,
    SaleLevel: 0,
    SaleLevelName: '',
    HasTeam: 0,
})

export const fetchMassageCenterData = () => requestPost<IManageCenter>('/user/getmyinfo.ashx')


/*获取用户信息*/
export const fetchUserInfo = () => requestPost<IUserInfo>('/user/getuserinfo.ashx')

/*用户信息操作*/
export const userManage = (data: IUserInfo) => requestPost('/user/usermanage.ashx', data)

/*获取会员权益*/
export interface IVipLevel {
    viplevel: number,
    LevelID: number
    LevelName: string
    CommonProfit: number
    SaleDiscount: number
    WholeDiscount: number
}

export interface IGrowUpItem {
    Title: string
    Content: string
    Restrictions: string
}

export interface IVipInfo {
    VIPLevel: number,
    VIPLevelID: number
    VIPLevelName: string
    VIPLevelList: IVipLevel[]
    GrowUpList: IGrowUpItem[]
}

export const getEmptyVipInfo = (): IVipInfo => ({
    VIPLevel: 0,
    VIPLevelID: 0,
    VIPLevelName: '',
    VIPLevelList: [],
    GrowUpList: []
})

export const fetchVipInfo = () => requestPost<IVipInfo>('/user/getvipinfo.ashx')


/*意见反馈*/

//反馈类型
export enum EFeedbackType {
    //1.订单问题
    order = 1,
    //2.功能建议
    suggest = 2,
    //3.账号问题
    account = 3,
    //4.服务问题
    service = 4,
    //5.商品问题
    goods = 5,
    //6.其他问题
    other = 6,
}

export interface IFeedbackReportRequest {
    FeedbackType: EFeedbackType,
    Content: string,
    Images?: string
}

export const feedbackReport = (data: IFeedbackReportRequest) => requestPost('/user/feedback.ashx', data)


/*获取用户车型*/
export interface IUserAutoItem {
    AutoID?: number,
    AutoName: string,
    IsSelect?: 0 | 1 | boolean,
    GearboxParam: string,
    SalesVersion?: string
}

export const fetchUserAuto = () => requestPost<IUserAutoItem[]>('/user/getuserauto.ashx')


/*用户车型管理*/
export enum EUserAutoManageActionType {
    add = 1,
    clear = 2,
    delete = 3
}

export interface IUserAutoManage {
    ActionType: EUserAutoManageActionType
    AutoID?: number,
    AutoIDs?: string,
    GearboxParam?: string
    GearboxParams?: string,
    ShowName?: string
}

export const userAutoManage = (data: IUserAutoManage) => requestPost('/user/userautomanage.ashx', data)

export const saveAutoAction = (GearboxParam: string, AutoID?: number) => userAutoManage({
    ActionType: EUserAutoManageActionType.add,
    GearboxParam,
    AutoID
})

export const cleanAutoAction = () => userAutoManage({ActionType: EUserAutoManageActionType.clear})

export const delAutoListAction = (AutoIDs: string, GearboxParams: string) => userAutoManage({
    ActionType: EUserAutoManageActionType.delete,
    AutoIDs,
    GearboxParams
})

/*重新提交审核*/
export const submitAuditAgain = () => requestPost('/user/agasubmitaudit.ashx')

/*获取销售人员列表*/
export interface ISalesPersonListItem {
    //	销售人员编号
    SalerID: number,
    //	销售人员名称
    SalerName: string
}

export const fetchSalerList = () => requestPost<{ list: ISalesPersonListItem[] }>('/user/getsalerlist.ashx')

/*获取完善信息*/
export interface IUserInfoItem {
    ColID: number,
    ColName: string,
    IsMust: number,
    DataList: (string | number)[]
}

export const fetchUserPerfect = (SCCode: string) => requestPost<{
    IsCanSkip: number,
    list: IUserInfoItem[]
}>('/user/getuserperfect.ashx', SCCode)


/*保存完善信息*/
export const setUserPerfect = (SCCode: string, list: IUserInfoItem[]) => requestPost('/user/setuserperfect.ashx', {
    SCCode,
    list
})


