import * as React from 'react'
import {useEffect, useLayoutEffect, useState} from 'react'
import {Col, Form, Image, Input, message, Modal, Row, Select, Typography} from "antd";
import {EImageFitClass, EStyleClass, ISelectOption} from "../../types";
import {CustomerTypeOptions, ECustomerType, SexOptions} from "../../service/Login";
import {
    fetchBaseSetAction,
    getEmptyBaseSetInfo,
    IBaseSetInfo,
    saveBaseSetAction
} from "../../service/ManageCenter/BaseSet";
import {apiResponseCheck, generateClassName} from "../../utils";
import {EmailRegCheck, FixedTelephoneRegCheck} from "../../utils/validate";
import UploadInput from "../UploadInput";
import {EUploadType} from "../../service/Common";
import HeadPortrait from '../../assets/images/headPortrait.png'
import {RightOutlined} from '@ant-design/icons'

const PersonalType: ISelectOption[] = [
    {value: 6, label: '车主'},
    {value: 7, label: '其他'}
]

const CompanyType: ISelectOption[] = [
    {value: 1, label: '汽配商'},
    {value: 2, label: '4S店'},
    {value: 3, label: '连锁门店'},
    {value: 5, label: '个体门店'},
    {value: 7, label: '其他'},
]

const itemStyles = {width: 258}

interface Props {

    visible?: boolean

    onChange?(): void

    onCancel?(data: boolean): void
}


const BaseInfoModal: React.FC<Props> = ({visible, onCancel, onChange}) => {

    const [customerType, setCustomerType] = useState<ECustomerType>(ECustomerType.Personal),
        [baseInfo, setBaseInfo] = useState(getEmptyBaseSetInfo()),
        [loading, setLoading] = useState(false),
        [form] = Form.useForm(),
        categoryName = `${customerType === ECustomerType.Personal ? '个人' : '企业'}类型`,

        _onCancel = () => {
            onCancel && onCancel(false)
        },
        onSubmit = async () => {
            try {
                setLoading(true)
                const values = await form.validateFields()
                if (values.HeadIconList && values.HeadIconList[0]) {
                    values.HeadIcon = values.HeadIconList[0].ImgName
                    values.HeadIconUrl = values.HeadIconList[0].ImgUrl
                }

                delete values.HeadIconList

                const res = await saveBaseSetAction(values)
                await apiResponseCheck(res)
                message.success('保存成功')
                _onCancel()
                onChange && onChange()
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }

    useLayoutEffect(() => {
        if (visible) {
            fetchBaseSetAction().then(res => {
                apiResponseCheck<IBaseSetInfo>(res).then(data => {
                    setBaseInfo(data)
                    form.setFieldsValue({
                        ...data, HeadIconList: [{ImgName: data.HeadIcon, ImgUrl: data.HeadIconUrl}]
                    })
                })
            })
        }
    }, [visible])

    return <Modal visible={visible} title={'完善基本信息'} width={588} confirmLoading={loading} onOk={onSubmit}
                  onCancel={_onCancel}>
        <Form layout={"vertical"} form={form}>
            <Form.Item name={'HeadIconList'}>
                <UploadInput uploadType={EUploadType.haadpoint} maxCount={1} showUploadList={false}>
                    {data => <div className={generateClassName(EStyleClass.FlexBox, EStyleClass.TextPrimaryClick)}>
                        <Image preview={false} className={generateClassName(EImageFitClass.cover)}
                               style={{width: 40, height: 40, borderRadius: '100%', marginRight: 10}}
                               src={data.length ? data[0].response?.ImgUrl ?? HeadPortrait : HeadPortrait}/>
                        <Typography.Link>修改头像<RightOutlined style={{marginLeft: 2}}/></Typography.Link>
                    </div>}
                </UploadInput>
            </Form.Item>

            <Form.Item label={'手机号码'}>
                {baseInfo.Mobile}
            </Form.Item>

            <Row gutter={[20, 0]}>
                <Col>
                    <Form.Item style={itemStyles} label={'身份类型'} name={'CustomerType'} required={true} rules={[{
                        required: true, message: '请选择身份类型'
                    }]}>
                        <Select options={CustomerTypeOptions} optionFilterProp={'label'}
                                onChange={e => setCustomerType(e)} placeholder={'请选择身份类型'}/>
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item style={itemStyles} label={categoryName} name={'Category'}
                               required={true} rules={[{
                        required: true, message: `请选择${categoryName}`
                    }]}>
                        <Select<ECustomerType>
                            options={customerType === ECustomerType.Personal ? PersonalType : CompanyType}
                            optionFilterProp={'label'} placeholder={`请选择${categoryName}`}/>
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item style={itemStyles} name={'Name'} label={'姓名'} required={true}
                               rules={[{required: true, message: '请填写姓名'}]}>
                        <Input placeholder={'请填写姓名'} maxLength={20}/>
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item style={itemStyles} label={'性别'} name={'Sex'}>
                        <Select options={SexOptions} optionFilterProp={'label'} placeholder={'请选择性别'}/>
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item style={itemStyles} name={'OtherPhone'} label={'固话电话'}
                               rules={[{validator: (_, value) => FixedTelephoneRegCheck(value)}]}>
                        <Input placeholder={`（区号）（电话号码）（-分机号）`} maxLength={17}/>
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item style={itemStyles} name={'Email'} label={'邮箱地址'}
                               rules={[{validator: (_, value) => EmailRegCheck(value)}]}>
                        <Input type={"email"} placeholder={`填写业务联系邮箱，用于接收交易对账单等`} maxLength={20}/>
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item style={itemStyles} name={'QQ'} label={'QQ'}>
                        <Input placeholder={`请填写QQ号`} maxLength={12}/>
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item style={itemStyles} name={'Wechat'} label={'微信'}>
                        <Input placeholder={`请填写微信号`} maxLength={12}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>
}

export default BaseInfoModal
