import React, {Suspense} from 'react';
import './App.scss';
import HeadAccountBar from "./components/HeadAccountBar";
import Header from "./components/Header";
import {StoreContextProvider} from "./components/Context/StoreContext";
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import {compoundRoutePath} from "./utils";
//import LoginCombine from "./components/LoginCombine";
import zhCN from 'antd/lib/locale/zh_CN';
import {ConfigProvider} from "antd";
import {menus} from "./routes";
import {EURLs} from "./types/URLs";
import StoreWith from "./components/StoreWith";
import {EGlobalKey, getSessionData, setNavigate} from "./utils/GlobalData";
import {ECheckStatus} from "./service/Login";
import {EHomFirstShow} from "./service/Home";


const SetNavigate:React.FC = () => {
    const navigate = useNavigate()
    setNavigate(navigate)
    return null
}

const RouterCheck: React.FC = StoreWith(({store}) => {

    const LoginData = store.LoginData,
        skip = !!getSessionData(EGlobalKey.SKIP_PREFECT),
        needPrefect = (!LoginData.IsCanSkip || (LoginData.IsCanSkip && !skip)) && LoginData.HasPerfect && LoginData.NeedPerfect,
        isErr = (!store.LoginData.SCCode && store.HomeData.HomeType === EHomFirstShow.login) ||
            LoginData.AuditStatus === ECheckStatus.reject ||
            (LoginData.AuditStatus === ECheckStatus.wait && !needPrefect),
        resultItem = isErr ? menus.find(item => item.path === EURLs.Login) : null

    console.log(menus)
    return <Suspense fallback={<div/>}>
        <Routes>
            {resultItem ? <Route path={'*'} element={React.createElement(resultItem.component)}/> : menus.map(item =>
                <Route key={item.path} path={compoundRoutePath(item.path)}
                       element={React.createElement(item.component)}/>)}
        </Routes>
    </Suspense>
})

function App() {
    return <ConfigProvider locale={zhCN}>
        <StoreContextProvider>
            <BrowserRouter>
                <div style={{position: "fixed", top: 0, left: 0, width: '100%', zIndex: 2}}>
                    <HeadAccountBar/>
                    <Header/>
                </div>


                <RouterCheck/>
                {/*<LoginCombine/>*/}
                <SetNavigate/>
            </BrowserRouter>

        </StoreContextProvider>
    </ConfigProvider>
}

export default App;
