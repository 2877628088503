import * as React from 'react'
//@ts-ignore
import Styles from './AutoTitle.scss'
import {Typography} from "antd";
import {EStyleClass} from "../../types";

const AutoTitle: React.FC = ({children}) => {

    return <div className={EStyleClass.FlexBox} style={{height: 30, background: '#F7F9FA', padding: '0 20px'}}>
        <Typography.Text type={"secondary"}>{children}</Typography.Text>
    </div>
}

export default AutoTitle
