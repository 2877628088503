import * as React from 'react'
//@ts-ignore
import Styles from './AutoItem.scss'
import {GCNAlias} from "../../utils";
import {EImageFitClass, EStyleClass} from "../../types";
import {IAutoListItem} from "../../service/Common";
import {Image} from "antd";
import {IUserAutoItem} from "../../service/My";


export const UserAutoItem: React.FC<{ data: IUserAutoItem, onClick?(data: IUserAutoItem): void }> = ({
                                                                                                         data,
                                                                                                         onClick
                                                                                                     }) => {

    const _onClick = () => onClick && onClick(data)

    return <div className={GCNAlias(Styles.container, EStyleClass.FlexBox, EStyleClass.BackgroundColorHover)}
                onClick={_onClick}>

        <span>{data.AutoName}</span>
    </div>
}

const AutoItem: React.FC<{ data: IAutoListItem, onClick?(data: IAutoListItem): void }> = ({data, onClick}) => {

    const _onClick = () => onClick && onClick(data)

    return <div className={GCNAlias(Styles.container, EStyleClass.FlexBox, EStyleClass.BackgroundColorHover)}
                onClick={_onClick}>
        {!!data.icon &&
			<Image preview={false} src={data.icon} className={GCNAlias(Styles.image, EImageFitClass.cover)}/>}

        <span>{data.value || data.showname}</span>
    </div>
}

export default AutoItem
