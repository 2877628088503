function smartJson(str: any) {
    if (!str) {
        return str;
    }
    if (typeof (str) == 'object' || typeof str === "number") {
        return JSON.stringify(str);
    } else {
        switch (str[0]) {
            case '{':
            case '[':
                switch (str[1]) {
                    case '}':
                        return {};
                    case ']':
                        return [];
                }
                try {
                    return JSON.parse(str)
                }catch (e){
                    throw e
                    return  undefined
                }
            default:
                return isNaN(str) ? str : Number(str);
        }
    }
}

export default smartJson
