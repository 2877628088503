export const uAgent = window.navigator.userAgent;
/*
export enum EDevicePlatform {
    iPhone = 'iPhone',
}

const platform:EDevicePlatform = window.navigator.platform*/

export const isIOS = () => uAgent.match(/iphone/i)
export const isYIXIN = () => uAgent.match(/yixin/i)
export const is2345 = () => uAgent.match(/Mb2345/i)
export const ishaosou = () => uAgent.match(/mso_app/i)
export const isSogou = () => uAgent.match(/sogoumobilebrowser/ig)
export const isLiebao = () => uAgent.match(/liebaofast/i)
export const isGnbr = () => uAgent.match(/GNBR/i)
export const isWeChat = () => uAgent.match(/MicroMessenger/i)
export const isWeiBo = () => uAgent.match(/weibo/i)
export const isQQ = () => uAgent.match(/qqbrowser/i)
export const isQQInner = () => uAgent.match(/qqbrowser/i) && uAgent.match(/\sqq/i)
export const isOS = () => uAgent.match(/os\s+5/i)
export const isBaidu = () => uAgent.match(/baidu/i)
export const isUC = () => uAgent.match(/ucbrowser/i)
export const isWindows = () => uAgent.match(/windows/i)
export const isAndroid = () => uAgent.match(/android/i)
