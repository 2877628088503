import Axios, {AxiosResponse} from 'axios'
import {setToken} from "./token";
import {message} from "antd";
import {clearLoading} from "../components/Loading";
import {isEmpty, isFormData, isObject} from "./index";
import {IdKey} from "../types";
import {UploadProgressEvent} from "rc-upload/lib/interface";
import {EGlobalKey, getSessionData, JumpToLogin} from "./GlobalData";
import smartJson from "./smartJson";
import {API_URL, getAPIHead} from "../config";

//const API_TYPE: 'DEV' | "MOCK" = 'DEV'

/*结构更新成接口需要的结构*/
const formatPostData = (data: { [key: string]: any }) => {
    return Object.keys(data).reduce((newData: { [key: string]: any }, key) => {
        if (key === 'pageindex' || key === 'pagesize' || key === 'pageSize' || key === 'pageNum') {
            newData.page = newData.page || {}
            newData.page[key === 'pageSize' ? 'pagesize' : key === 'pageNum' ? 'pageindex' : key] = data[key]
        } else {
            newData.data = newData.data || {}
            newData.data[key] = data[key]
        }
        newData.data = newData.data || {}
        return newData
    }, {})
}

const formAppend = (form: FormData | URLSearchParams, key: string, list: any) => {
    if (Array.isArray(list)) {
        formArrayCheck(form, key, list)
    } else if (isObject(list) && !(list instanceof File)) {
        Object.keys(list).forEach(k => {
            formAppend(form, key, list[k])
        })
    } else {
        form.append(key, list)
    }
}

const formArrayCheck = (form: FormData | URLSearchParams, key: string, list: any[]) => {
    list.forEach((item, index) => {
        if (Array.isArray(item)) {
            formArrayCheck(form, `${key}[${index}]`, item)
        } else if (isObject(item)) {
            Object.keys(item).forEach(k => {
                formAppend(form, `${key}[${index}].${k}`, item[k])
            })
        } else {
            form.append(`${key}[${index}]`, item)
        }
    })
}

export const generateFrom = (options: any, useForm: boolean = false, ignoreObject = false) => {
    return isObject(options) ? Object.keys(options).reduce((form: FormData, key) => {
        if (!isEmpty(options[key])) {
            if (ignoreObject) {
                form.append(key, options[key])
            } else {
                formAppend(form, key, options[key])
            }
        }
        return form
    }, useForm ? new FormData() : new URLSearchParams()) : options
}

type IContentType =
    'text/html'
    | 'text/plain'
    | 'text/xml'
    | 'image/gif'
    | 'image/jpeg'
    | 'image/png'
    | 'application/xhtml+xml'
    | 'application/xml'
    | 'application/atom+xml'
    | 'application/json'
    | 'application/pdf'
    | 'application/msword'
    | 'application/octet-stream' | 'application/x-www-form-urlencoded' | 'multipart/form-data'

export interface IAjaxPage {
    pageindex?: number,
    pagesize?: number,
}

export interface IAjaxDownUrl {
    downurl: string
}

export enum EBodyStatus {
    fail = 0,
    success = 1,
}

export interface IAjaxBodyStatus {
    Status: EBodyStatus,
    Message?: string
}

export interface IAjaxResponse<T> {
    header: {
        code: string,
        message: string,
        timestamp: number,
        isenabled: boolean | 0 | 1
    },
    body: T,
    page: {
        pageindex: number,
        pagesize: number,
        total: number,
        pagecount: number,
    }
}

//export const apiUrl = 'http://cptbapi.test.chepeilong.cn/01/';
//'http://192.168.2.137:8080/01/'


// export const apiUrl = ID_DEV ? 'http://192.168.2.137:8081/01/':
//   window.location.href.indexOf('store.chepeilong.com') >= 0 ||
//   window.location.href.indexOf('store.chepeitong.com') >= 0?
//     'http://storeapi.chepeilong.com/01/':
//     'http://cptbapi2.test.chepeilong.cn/01/'


/*'http://192.168.2.137:8080/01/'*/

//export const ApiHost = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.BUILD_TARGET === 'production' ? 'https://mall.dadi18.com' : 'https://test.dadi18.com'
//export const ApiHost = 'http://10.150.192.223:8091'

export const apiUrl = `${API_URL}`

/*export const generateGetData = (url: string, params?: { [key: string]: unknown }) => {
    let head = getAPIHead(params);

    let urlParams: string[] = [`header=${JSON.stringify(head)}`]
    if (typeof params === 'object') {
        urlParams = [...urlParams, ...Object.keys(params).map(key => {
            return `${key}=${params[key]}`
        })]
    }
    return apiUrl + url + '?' + urlParams.join('&')
}*/

/*export const getFileStream = (url: string) => {
    // request('common/getstreamforpath.ashx',{downurl: url}).then(data=>{
    //     return data
    // })
    return generateGetUrl('common/getstreamforpath.ashx', {downurl: url})
}*/


export function requestPost<T>(url: string, options?: any): Promise<IAjaxResponse<T>> {
    return request<T>(url, options, 'post')
}

export function requestGet<T>(url: string, options?: any): Promise<IAjaxResponse<T>> {
    return request<T>(url, options, 'get')
}

export function requestDelete<T>(url: string, options?: any): Promise<IAjaxResponse<T>> {
    return request<T>(url, options, 'delete')
}

export function requestPath<T>(url: string, path?: string | number | IdKey | null, options?: any, ignoreObject = false): Promise<IAjaxResponse<T>> {

    return request<T>(path ? `${url}/${path}` : url, generateFrom(options, true, ignoreObject), 'post', "normal", 'application/x-www-form-urlencoded')
}

export function requestFomUrlencoded<T>(url: string, options?: any, ignoreObject = false): Promise<IAjaxResponse<T>> {
    return request<T>(url, generateFrom(options, false, ignoreObject), 'post', "normal", 'application/x-www-form-urlencoded')
}

export function requestFomData<T>(url: string, options?: any, ignoreObject = false): Promise<IAjaxResponse<T>> {
    return request<T>(url, generateFrom(options, true, ignoreObject), 'post', "normal")
}

export function requestDownloadPost<T = Blob>(url: string, options?: any): Promise<IAjaxResponse<T>> {
    return request<T>(url, options, 'post', "download")
}

export function requestDownloadGet<T = Blob>(url: string, options?: any): Promise<IAjaxResponse<T>> {
    return request<T>(url, options, 'get', "download")
}

export function requestUpload<T>(url: string, options?: any, onPress?: (precent: UploadProgressEvent) => void): Promise<IAjaxResponse<T>> {
    return request<T>(url, generateFrom(options, true), 'post', "normal", 'application/x-www-form-urlencoded', onPress)
}

type IFunc<T> = (...attr: any[]) => Promise<IAjaxResponse<T>>

export const requestThrottle = <T = any>(f: IFunc<T>, times = 50000): IFunc<T> => {
    let _time = 0
    let data: any = null

    return (...attr) => new Promise<IAjaxResponse<T>>((resolve, reject) => {
        if (!data || new Date().getTime() - _time > times) {
            f(...attr).then(res => {
                _time = new Date().getTime()
                data = res
                resolve(data)
            }).catch(reject)
        } else {
            resolve(data)
        }
    })
}

export const execAxios = (url: string, options: any, method: 'post' | 'get' | 'delete' = 'post', type: 'normal' | 'download' = "normal", ContentType: IContentType = 'application/json', onPress?: (percent: UploadProgressEvent) => void) => {
    //const token = getToken()
    const head = getAPIHead()
    const headers: { 'Content-Type': IContentType, token?: string } = {'Content-Type': ContentType}
    let paramData: any


    if (!isEmpty(options) && !isObject(options)) {
        /*url = `${url}/${options}`
        options = null
        ContentType = 'application/x-www-form-urlencoded'*/
        paramData = {
            head,
            data: options
        }
    } else if (isEmpty(options)) {
        if (method === "get") {
            paramData = {header: smartJson(head)}
        } else {
            paramData = {
                head
            }
        }
    } else {
        paramData = options
        if (isFormData(options)) {
            options.append('header', smartJson(head))
            ContentType = 'multipart/form-data'
        } else if (isURLSearchParams(options)) {
            options.append('header', smartJson(head))
            ContentType = 'application/x-www-form-urlencoded'
        } else {
            if (method === "get") {
                paramData = {...options, header: smartJson(head)}
            } else {
                paramData = {
                    ...formatPostData(options),
                    head
                }
            }
        }
    }

    /*if (token) {
        headers.token = getToken()
    }*/

    headers['Content-Type'] = ContentType

    return Axios({
        url: /^http/i.test(url) ? url : `${apiUrl}${url[0] === '/' ? '' : '/'}${url}`,
        [method === "get" ? 'params' : 'data']: paramData,
        headers,
        method,
        responseType: type === "download" ? "blob" : 'text',
        onUploadProgress: (ev) => {
            if (onPress) {
                //const percent = ((ev.loaded / ev.total) * 100) | 0;
                ev.percent = ((ev.loaded / ev.total) * 100) | 0;
                onPress && onPress(ev)
            }
        },
        transformRequest: (data) => {
            if (ContentType === 'application/json') {
                try {
                    return JSON.stringify(data)
                } catch (err) {
                    return data
                }
            }
            return data
        },
        transformResponse: (data) => {
            if (type === "download" && data instanceof Blob) {
                return {
                    header: {
                        code: 0, message: "操作成功",
                        isenabled: true
                    },
                    body: data
                }
            } else {
                try {
                    // 如果转换成功则返回转换的数据结果
                    return JSON.parse(data);
                } catch (err) {
                    //console.error(err)
                    //console.error(data, 'err')
                    // 如果转换失败，则包装为统一数据格式并返回
                    return data;
                }
            }
        }
    })
}

export const checkAxiosResponse = <T = any>(response: AxiosResponse): Promise<IAjaxResponse<T>> => new Promise((resolve, reject) => {
    if (!response.data && response.request && response.request.responseText) {
        response.data = JSON.parse(response.request.responseText)
    }

    if (response.data.data instanceof Blob) {
        response.data.data.filename = response.headers['content-disposition'].split(';').find((item: string) => item.indexOf('filename=') >= 0)?.replace('filename=', '')
    }

    //console.log(response.data.header)

    if (response.data.header.isenabled) {

        if (response.data.header.code === '3') {
            getSessionData(EGlobalKey.SKIP_PREFECT, true)
            JumpToLogin()
        }

        resolve && resolve(response.data)
        //throw new Error(response.data.message)
    } else {
        clearLoading()
        message.error(response.data.message).then(() => {
            setToken('')
            JumpToLogin()
        })
        reject && reject(response.data.message)
    }


    return response.data;
})

export default function request<T>(url: string, options: any, method: 'post' | 'get' | 'delete' = 'post', type: 'normal' | 'download' = "normal", ContentType: IContentType = 'application/json', onPress?: (percent: UploadProgressEvent) => void): Promise<IAjaxResponse<T>> {

    return new Promise((resolve1, reject1) => execAxios(url, options, method, type, ContentType, onPress).then(response => {
        //console.log(response)
        checkAxiosResponse<T>(response).then(resolve1).catch(reject1)
    }).catch(function (error) {
        message.error(`接口错误：${error.message}`)
        clearLoading()
        reject1 && reject1(error)
        return error
    }))
}

export const isURLSearchParams = (v: any): v is URLSearchParams => {
    return Object.prototype.toString.call(v) === '[object URLSearchParams]'
}
