import * as React from 'react'
//@ts-ignore
import Styles from './ChooseAutoModal.scss'
import {Breadcrumb, Modal, Steps} from "antd";
import AutoBrandList from "./AutoBrandList";
import {useState} from "react";
import {IAutoGearItem, IAutoListItem} from "../../service/Common";
import AutoSeriesList from "./AutoSeriesList";
import AutoModelList from "./AutoModelList";
import AutoYearList from "./AutoYearList";
import MainGearList from "./MainGearList";
import VINSearch from "../VINSearch";
import {IUserAutoItem} from "../../service/My";
import ScrollContainer from "../ScrollContainer";
import {UserAutoItem} from "./AutoItem";

type Props = {
    showVin?: boolean
    visible?: boolean,
    title?: string
    onChange?(data: IUserAutoItem): void
    onCancel?(data: boolean): void
}

const ChooseAutoModal: React.FC<Props> = ({visible, showVin = true, title = '新增车型', onChange, onCancel}) => {

    const [selectedList, setSelectList] = useState<IAutoListItem[]>([]),
        [vinList, setVinList] = useState<IAutoGearItem[]>([]),
        [showVinList, setShowVinList] = useState(false),
        onStepClick = (len: number = 0) => () => {
            setSelectList(selectedList.slice(0, len))
        },
        onStepChange = (len: number = 0) => (data: IAutoListItem) => {
            const dataList = [...selectedList.slice(0, len), data]
            setSelectList(dataList)
        },
        _onCancel = () => {
            setSelectList([])
            setShowVinList(false)
            onCancel && onCancel(false)
        },
        onSubmit = (data: IAutoListItem) => {
            data.value = [...selectedList, data].map(item => item.value).join(' ')
            onChange && onChange({GearboxParam: data.key, AutoName: data.value})
            _onCancel()
        },

        onVInSubmit = (data: IUserAutoItem) => {
            onChange && onChange(data)
            _onCancel()
        },

        onVInListChange = (data: IAutoGearItem[]) => {
            if (data.length === 1) {
                const {GearboxParam, AutoName} = data[0]
                onChange && onChange({GearboxParam, AutoName})
                _onCancel()
            } else {
                setVinList(data)
                setShowVinList(true)
            }
        }

    return <>
        <Modal visible={visible && !showVinList} width={600} title={title} onCancel={_onCancel} footer={false}>

            {showVin && <VINSearch onChange={onVInListChange}/>}

            <Steps progressDot current={selectedList.length}>
                <Steps.Step title={'选择品牌'} onStepClick={onStepClick()}/>
                <Steps.Step title={'选择车系'} onStepClick={onStepClick(1)}/>
                <Steps.Step title={'选择车型'} onStepClick={onStepClick(2)}/>
                <Steps.Step title={'选择年份'} onStepClick={onStepClick(3)}/>
            </Steps>

            <div className={Styles.breadcrumb}>
                <Breadcrumb separator="<">
                    {selectedList.map((item, index) => <Breadcrumb.Item key={item.key}
                                                                        onClick={onStepClick(index + 1)}>{item.value}</Breadcrumb.Item>)}
                </Breadcrumb>
            </div>

            <div style={{display: selectedList.length ? "none" : "block"}}><AutoBrandList
                onChange={onStepChange()}/></div>
            {selectedList.length === 1 &&
				<AutoSeriesList className={Styles.scrollList} brandId={selectedList[0]?.key}
				                onChange={onStepChange(1)}/>}
            {selectedList.length === 2 &&
				<AutoModelList className={Styles.scrollList} series={selectedList[1]?.key} onChange={onStepChange(2)}/>}
            {selectedList.length === 3 &&
				<AutoYearList className={Styles.scrollList} model={selectedList[2]?.key} onChange={onStepChange(3)}/>}

            {selectedList.length === 4 &&
				<MainGearList className={Styles.scrollList} year={selectedList[3]?.key} model={selectedList[2]?.key}
				              onChange={onSubmit}/>}
        </Modal>


        <Modal visible={showVinList} width={600} title={'选择具体车型'} onCancel={() => setShowVinList(false)} footer={false}>
            <ScrollContainer type={"y"} className={Styles.scrollList}>
                {vinList.map(item => <UserAutoItem key={item.GearboxParam} data={item} onClick={onVInSubmit}/>)}
            </ScrollContainer>
        </Modal>
    </>
}

export default ChooseAutoModal
