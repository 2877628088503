import React from "react";
import {EURLs, EUrlTitles} from "../types/URLs";
import {getHomeData} from "../utils/storage/Home";
import {getDistributionBrokeName} from "../service/Distribution";

interface InnerRouter {
    path: string[] | string,
    component: any,
    menu?: boolean,
    title: (() => string) | string,
    menuTitle?: string
}

export interface IMenuItem {
    title: string,
    path?: string,
    icon?: React.ReactNode,
    routes: InnerRouter[]
}

const _menus: InnerRouter[] = [
    {
        title: EUrlTitles.Home,
        component: React.lazy(() => import('../views/Home')),
        path: EURLs.Home,
    },

    {
        title: EUrlTitles.ManageCenter,
        component: React.lazy(() => import('../views/My')),
        path: EURLs.ManageCenter,
    },

    {
        title: EUrlTitles.News,
        component: React.lazy(() => import('../views/News')),
        path: EURLs.News,
    },

    {
        title: EUrlTitles.GoodsList,
        component: React.lazy(() => import('../views/GoodsList')),
        path: [EURLs.GoodsList_11, EURLs.GoodsList_10, EURLs.GoodsList_9, EURLs.GoodsList_8, EURLs.GoodsList_7, EURLs.GoodsList_6, EURLs.GoodsList_5, EURLs.GoodsList_4, EURLs.GoodsList_3, EURLs.GoodsList_2, EURLs.GoodsList_1, EURLs.GoodsList],
    },
    {
        title: EUrlTitles.Category,
        component: React.lazy(() => import('../views/GoodsList')),
        path: EURLs.Category,
    },
    {
        title: EUrlTitles.Goods,
        component: React.lazy(() => import('../views/GoodsDetail')),
        path: EURLs.Goods,
    },

    {
        title: EUrlTitles.ShopCar,
        component: React.lazy(() => import('../views/ShoppingCart')),
        path: EURLs.ShopCar,
    },

    {
        title: EUrlTitles.QuickPurchase,
        component: React.lazy(() => import('../views/QuickPurchase')),
        path: EURLs.QuickPurchase,
    },

    {
        title: EUrlTitles.SubmitOrder,
        component: React.lazy(() => import('../views/SubmitOrder')),
        path: EURLs.SubmitOrder,
    },
    {
        title: EUrlTitles.Pay,
        component: React.lazy(() => import('../views/Payment')),
        path: [EURLs.Pay2, EURLs.Pay],
    },

    {
        title: EUrlTitles.PayResult,
        component: React.lazy(() => import('../views/Payment/Result')),
        path: [EURLs.PayResult, EURLs.PayResult2],
    },

    {
        title: EUrlTitles.OrderList,
        component: React.lazy(() => import('../views/OrderManage/OrderList')),
        path: [EURLs.OrderList_1, EURLs.OrderList],
    },

    {
        title: EUrlTitles.OrderDetail,
        component: React.lazy(() => import('../views/OrderManage/OrderDetail')),
        path: EURLs.OrderDetail,
    },
    {
        title: EUrlTitles.Collection,
        component: React.lazy(() => import('../views/Collection')),
        path: EURLs.Collection,
    },
    {
        path: EURLs.BillList,
        component: React.lazy(() => import('../views/BillManage')),
        title: EUrlTitles.BillList,
    },

    {
        path: EURLs.ScoreCenter,
        component: React.lazy(() => import('../views/ScoreManage')),
        title: EUrlTitles.ScoreCenter,
    },

    {
        path: EURLs.ScoreCash,
        component: React.lazy(() => import('../views/ScoreManage/Cash')),
        title: EUrlTitles.ScoreCash,
    },

    {
        path: EURLs.CouponDetail,
        component: React.lazy(() => import('../views/Coupon/CouponDetail')),
        title: EUrlTitles.CouponDetail,
    },

    {
        path: EURLs.DistributionGoods,
        component: React.lazy(() => import('../views/Distribution/GoodsList')),
        title: EUrlTitles.DistributionGoods,
    },

    {
        path: EURLs.DistributionLog,
        component: React.lazy(() => import('../views/Distribution/DistributionLog')),
        title: EUrlTitles.DistributionLog,
    },
    {
        path: EURLs.DistributionCustomer,
        component: React.lazy(() => import('../views/Distribution/MyCustomer')),
        title: EUrlTitles.DistributionCustomer,
    },
    {
        path: EURLs.DistributionTeam,
        component: React.lazy(() => import('../views/Distribution/MyTeam')),
        title: EUrlTitles.DistributionTeam,
    },
    {
        path: EURLs.DistributionCash,
        component: React.lazy(() => import('../views/Distribution/Cash')),
        title: () => `${getDistributionBrokeName(getHomeData())}提现`,
    },

    {
        path: EURLs.MyCoupon,
        component: React.lazy(() => import('../views/Coupon/MyCoupon')),
        title: EUrlTitles.MyCoupon,
    },

    {
        path: EURLs.CouponList,
        component: React.lazy(() => import('../views/Coupon/CouponCenter')),
        title: EUrlTitles.CouponList,
    },
    {
        path: EURLs.InviteRegister,
        component: React.lazy(() => import('../views/InviteRegister')),
        title: EUrlTitles.InviteRegister,
    },

    {
        path: EURLs.Login,
        component: React.lazy(() => import('../views/Login')),
        title: EUrlTitles.Login,
    },

    {
        path: EURLs.Register,
        component: React.lazy(() => import('../views/Login')),
        title: EUrlTitles.Register,
    },

    {
        path: EURLs.OCR,
        component: React.lazy(() => import('../views/RedirectPages/OCR')),
        title: EUrlTitles.OCR,
    },

    {
        path: EURLs.OCRVin,
        component: React.lazy(() => import('../views/RedirectPages/OCR')),
        title: EUrlTitles.OCRVin,
    },
    {
        path: EURLs.SearchAuto,
        component: React.lazy(() => import('../views/RedirectPages/OCR')),
        title: EUrlTitles.SearchAuto,
    },

    {
        path: EURLs.AboutUs,
        component: React.lazy(() => import('../views/RedirectPages/AboutUs')),
        title: EUrlTitles.AboutUs,
    },

    {
        path: EURLs.DistributionSubmit,
        component: React.lazy(() => import('../views/RedirectPages/DistributionSubmit')),
        title: EUrlTitles.DistributionSubmit,
    },

    {
        path: EURLs.AdvanceSearch,
        component: React.lazy(() => import('../views/RedirectPages/AdvanceSearch')),
        title: EUrlTitles.AdvanceSearch,
    },

    {
        path: EURLs.AddressManage,
        component: React.lazy(() => import('../views/RedirectPages/AddressManage')),
        title: EUrlTitles.AddressManage,
    },
    {
        path: EURLs.AddAddress,
        component: React.lazy(() => import('../views/RedirectPages/AddressManage')),
        title: EUrlTitles.AddAddress,
    },

    {
        path: EURLs.EPCHome,
        component: React.lazy(() => import('../views/EPC')),
        title: EUrlTitles.EPCHome,
    },

    {
        path: EURLs.EPCSearch,
        component: React.lazy(() => import('../views/EPC/EPCSearch')),
        title: EUrlTitles.EPCSearch,
    },

    {
        path: EURLs.EPCPartsList,
        component: React.lazy(() => import('../views/EPC/EPCPartsList')),
        title: EUrlTitles.EPCPartsList,
    },

    {
        path: EURLs.EPCShopCar,
        component: React.lazy(() => import('../views/EPC/EPCShopCart')),
        title: EUrlTitles.EPCShopCar,
    },
    {
        path: EURLs.EPCPartsDetail,
        component: React.lazy(() => import('../views/EPC/EPCPartDetail')),
        title: EUrlTitles.EPCPartsDetail,
    },

    //EPCShopCar


    //InviteRegister
]

type IRouter = Omit<InnerRouter, 'path'> & { path: string }

export const menus: IRouter[] = _menus.reduce((list, item) => {
    if (Array.isArray(item.path)) {
        return [...list, ...item.path.map(path => ({...item, path}))]
    }
    return [...list, item as IRouter]
}, [] as IRouter[])
console.log(menus)
