import * as React from 'react'
//@ts-ignore
import Styles from './HeadAccountBar.scss'
import {compoundRoutePath, GCNAlias} from "../../utils";
import {Button, Modal, Space, Tooltip, Typography} from "antd";
import {EGlobalKey, getSessionData, JumpToLogin} from "../../utils/GlobalData";
import {getEmptyLoginData, ILoginData} from "../../service/Login";
import {getLoginStorage} from "../../utils/storage/Login";
import {Link} from "react-router-dom";
import {EURLs} from "../../types/URLs";
import StoreWith from "../StoreWith";
import BaseInfoModal from "../BaseInfoModal";
import {useState} from "react";
import {Language} from "../Language";

const HeadAccountBar: React.FC = StoreWith(({store, setStore}) => {

    const //location = useLocation(),
        //native = useNavigate(),
        [showBaseInfo, setShowBaseInfo] = useState(false),
        onLogin = (loginData: ILoginData) => () => {
            if (!loginData.SCCode) {
                JumpToLogin()
            }
        },
        onLogOut = () => {
            Modal.confirm({
                type: "warning",
                title: '提示',
                content: '是否退出登录？',
                onOk: () => {
                    getLoginStorage(true)
                    getSessionData(EGlobalKey.SKIP_PREFECT, true)
                    setStore({LoginData: getEmptyLoginData()})
                    //setRedirect(`${location.pathname}${location.search ?? ''}`)
                    //native(compoundRoutePath(EURLs.Home), {replace: true})
                }
            })
        }

    return <>
        <div className={Styles.container}>
            <div className={GCNAlias('contentWith', 'flexBox', Styles.inner)}>
                <Space>
                    {store.LoginData.SCCode ? <Tooltip color='#fff' title={<div>
                        <Space direction={"vertical"}>
                            {!!store.LoginData.HasPerfect &&
								<Typography.Link onClick={() => setShowBaseInfo(true)}>完善资料</Typography.Link>}
                            <Typography.Link onClick={onLogOut}>退出登录</Typography.Link>
                        </Space>
                    </div>}>
                        <Button type={"text"}>{store.LoginData.Name || '匿名'}</Button>
                    </Tooltip> : <Button type={"text"} onClick={onLogin(store.LoginData)}>登录/注册</Button>}

                    <Link to={compoundRoutePath(EURLs.News)}><Button type={"text"}>消息中心</Button></Link>
                    <Language/>
                </Space>
            </div>
        </div>
        <BaseInfoModal visible={showBaseInfo} onCancel={setShowBaseInfo}/>
    </>
})

export default HeadAccountBar
