import {requestPost} from "../utils/request";
import {EItemType} from "./Home";
import {ISelectOption} from "../types";
import {EQualityType} from "./Item";

/*获取购物车*/

export enum EShopCarItemStatus {
    Online = 1,
    Offlien = 2
}

export enum EShopCarItemType {
    Standard = 1,
    Offer = 2
}

export enum EShopCarSheachType {
    Count = 1,
    List = 2
}

export enum EStockType {
    Infinite = 1,
    Offlien = 2,
    Online = 3
}

export enum ESaleType {
    wholesale = 2,
    vip = 4,
    def = 1
}

export enum EGoodsLevel {
    host = 1,
    brand = 2,
    cc = 3,
    recover=4
}

export const GoodsLevelOptions: ISelectOption[] = [
    {value: EGoodsLevel.host, label: '原厂件'},
    {value: EGoodsLevel.brand, label: '品牌件'},
    {value: EGoodsLevel.cc, label: '拆车件'},
    {value: EGoodsLevel.recover, label: '再制造件'},
]

export const getGoodsLevelName = (data: EGoodsLevel) => GoodsLevelOptions.find(item => item.value === data)?.label || ''

export const getLevelFromAny = (data?: string | number): EGoodsLevel | undefined => GoodsLevelOptions.find(item => item.value === Number(data))?.value

export enum EGoodsPost {
    free = 1,
    charge = 0
}

export interface IShopCarGoodsItem {
    ShoppingCarID: number
    PartsID: number
    PartsName: string
    ItemID: number
    ItemShowName: string
    Status: EShopCarItemStatus
    SalesPrice: number
    WholeSalePrice: number
    Quantity: number
    StockType: EStockType
    StockNum: number
    Orderdays: number
    SaleType: ESaleType
    MinWholeSaleNum: number
    MaxBuyNum: number
    OENO: string
    AutoNames: string
    SubItemID: number
    SubName: string
    Level: EGoodsLevel
    IsFreePost: EGoodsPost
    SubGroupID: number
    Images: string[]

    ItemType: EShopCarItemType
    checked?: boolean
}


export interface IShopCarPartItem {
    PartsID: number
    PartsName: string
    ItemList: IShopCarGoodsItem[]
}

export interface ShopCarListRequest {
    CarType: EShopCarSheachType
}

export interface IShopCarListResponse {
    StandardList: IShopCarPartItem[]
    QuoteList: IShopCarPartItem[]
    ItemType?: number
}

export const fetchShopCartItemList = () => requestPost<IShopCarListResponse>('/shopcar/getshopcar.ashx', {CarType: EShopCarSheachType.List})

/*获取购物车数量*/
export const fetchShopCarCount = () => requestPost<number>('/shopcar/getshopcar.ashx', {CarType: EShopCarSheachType.Count})

/*立即购买信息*/
export interface IBuySubItem {
    SubItemID: number,
    Quantity: number
}

export interface IFetchBuyItemRequest {
    ItemID: number,
    Quantity: number,
    SubGroupID?: number,
    SubList?: IBuySubItem[]
}

export enum EGoodsStatus {
    ONLINE = 1,
    OFFLINE = 2
}

export interface ImmediatelyBuyItemInfo {
    PartsID: number,
    PartsName: string,
    ItemID: number
    ItemShowName: string

    Status: EGoodsStatus
    ItemType: EItemType

    SalesPrice: number
    WholeSalePrice: number
    Quantity: number

    StockType: EStockType
    StockNum: number

    Orderdays: number
    SaleType: ESaleType

    MinWholeSaleNum: number
    MaxBuyNum: number
    OENO: string
    Level: EGoodsLevel
    IsFreePost: EGoodsPost
    Images: string[]
    SubItemID: number
    SubName: string
}

export const fetchImmediatelyBuyItemInfo = ({
                                                SubGroupID = 0,
                                                ...data
                                            }: IFetchBuyItemRequest) => requestPost<ImmediatelyBuyItemInfo[]>('/shopcar/immediatelybug.ashx', {
    ...data,
    SubGroupID
})


/*购物车操作*/
export enum EShopCarActionType {
    AddSub = 1,
    Change = 2,
    Delete = 3,
    Empty = 4,
    DeleteCategory = 5,
    BatchAdd = 6
}

export interface IShopCarManageRequest {
    ActionType: EShopCarActionType
    ItemID?: number
    Quantity?: number
    SubGroupID?: number
    PartsID?: number
    SubItemID?: number
    SubList?: {
        SubItemID: number
        Quantity: number
    }[]
}

export const manageShopCart = (data: IShopCarManageRequest) => requestPost('/shopcar/shopcarmanage.ashx', data)


/*增加减少商品数量*/
export const addOrSubShopCartQuantity = (ItemID: number, Quantity: number, SubItemID?: number) => manageShopCart({
    ActionType: EShopCarActionType.AddSub,
    ItemID,
    Quantity,
    SubItemID
})

/*修改商品数量*/
export const changeShopCartQuantity = (ItemID: number, Quantity: number, SubItemID?: number) => manageShopCart({
    ActionType: EShopCarActionType.Change,
    ItemID,
    Quantity,
    SubItemID
})

/*清空购物车*/
export const cleanShopCart = () => manageShopCart({ActionType: EShopCarActionType.Empty})


/*删除某个分类商品*/
export const deleteShopCartByPart = (PartsID: number) => manageShopCart({
    ActionType: EShopCarActionType.DeleteCategory,
    PartsID
})

/*删除某个分类商品*/
export const deleteShopCartItem = (ItemID: number) => manageShopCart({
    ActionType: EShopCarActionType.Delete,
    ItemID
})

/*批量加入子商品*/
export const addBatchShopCart = (SubList: {
    SubItemID: number
    Quantity: number
}[]) => manageShopCart({ActionType: EShopCarActionType.BatchAdd, SubList})
