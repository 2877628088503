import * as React from 'react'
import ReactDom from 'react-dom'
// @ts-ignore
import Styles from './Loading.scss'
import {generateClassName} from "../../utils";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    txt?: string,
    loading?: boolean | number,
    full?: boolean | number,
}

const Loading = React.memo<Props>(({loading, full, txt, className, children, ...attr}) => {

    return <div className={generateClassName({[Styles.loading]: loading && !full, className})} {...attr}>
        {loading ? <div className={generateClassName(Styles.loadingMask, {[Styles.full]: full})}>
            <div className={Styles.spinner}>
                <svg viewBox="25 25 50 50" className={Styles.circular}>
                    <circle cx="50" cy="50" r="20" fill="none" className={Styles.path}/>
                </svg>
                {txt ? <div className={Styles.text}>{txt}</div> : null}
            </div>
        </div> : null}
        {children}
    </div>
})

let elem: HTMLDivElement | null = null

export const createLoading = (txt?:string) => {
    clearLoading()
    elem = document.createElement('div')
    ReactDom.render(<Loading full={1} loading={1} txt={txt}/>, elem)
    document.body.append(elem)
}

export const clearLoading = () => {
    if (elem) {
        elem.remove()
        elem = null
    }
}

export default Loading
