import Ajax from "../utils/Ajax";
import {IGoodsListItem, IGoodsListRequest} from "./Item";
import {IAjaxPage} from "../utils/request";
import {IListData} from "../types";
import {ECashAccountType} from "./Score";


export interface IDisCheck {
    CheckID: number
    CheckName: string
    CheckValue: number
    DValue: number
    Ischeck: number

}

export const applyCheck = () => Ajax<{
    HasApplyLimit: number,
    ViplevelName: string,
    CheckList: IDisCheck[]
    Applyrule: string
}>({url: 'distribution/applycheck'})

export interface ICollItem {
    ColID: number
    ColShowName: string
    ColName: string
    ValueType: number
    ValueTypeStr: string
    IsMust: number
    Value: any[]
}

export const fetchApplyCol = () => Ajax<{
    ColList: ICollItem[]
}>({url: 'distribution/getapplycol'})

export const submitApplyCol = (ColList: ICollItem[], PUserID?: number) => Ajax<{
    Message: string, Status: number
}>({url: 'distribution/submitapplycol', data: {ColList}})

export interface ISaleGoodsItem extends IGoodsListItem {
    OldPrice: number
    PriceStr: string
    BrokeageType: number
    Brokeage: number
    InBrokeageType: number
    InBrokeage: number
    //Url?: string
}

export const fetchSaleItemList = (data: IGoodsListRequest & IAjaxPage) => Ajax<IListData<ISaleGoodsItem>>({
    url: 'distribution/getsaleitemlist',
    data
})

export const fetchSaleItemImg = (ItemID: number, SubItemIDs?: string,) => Ajax<{ codepath: string }>({
    url: 'distribution/getsaleitemimg',
    data: {ItemID, SubItemIDs}
})

export const fetchSaleItemUrl = (ItemID: number) => Ajax<{ urlpath: string }>({
    url: 'distribution/getsaleitemurl',
    data: {ItemID}
})

export interface IDistributionInfo {
    TotalBroke: number
    NowBroke: number
    FrozenBroke: number
    CashBroke: number
}

export const getEmptyDistributionInfo = (): IDistributionInfo => ({
    TotalBroke: 0,
    NowBroke: 0,
    FrozenBroke: 0,
    CashBroke: 0,
})

export const fetchDistributionInfo = () => Ajax<IDistributionInfo>({url: 'distribution/getdistributioninfo'})

export interface IDistribution {
    LogID: number
    Title: string
    Content: string
    Brokeage: number
    /*佣金状态
1.冻结中
2.已入账*/
    BrokeageStatus: number
    BrokeageStatusStr: string
    CreateTime: string
    /*EntryTime*/
    EntryTime: string
}

export const fetchDistributionList = (data: {
    //收益类型
    // 0.全部
    // 1.获取
    // 2.扣除
    BrokeType: number
    /*时间类型
0.全部
1.按月
2.按日期*/
    TimeType: number
    /*时间*/
    TimeValue: string
} & IAjaxPage) => Ajax<IListData<IDistribution>>({
    url: 'distribution/getdistributionlist',
    data
})

export interface IDistributionUser {
    UserID: number
    CusName: string
    HeadIcon: string
    HeadIconUrl: string
    TotalBroke: number
    MonthBroke: number
    Status: number
    StatusStr: string
    IsExpire: number
    ExpireTime: string
    ProtectDay: number
}

export const fetchDistributionUserList = (data: {
    /*
    * 状态
0.全部
1.有效中
2.已失效
    * */
    Status: number
} & IAjaxPage) => Ajax<{ list: IDistributionUser[] }>({
    url: 'distribution/getdistributionuserlist',
    data
})

export interface ICashAccount {
    AccountType: ECashAccountType
    AccountTypeStr?: string
    Account: string
    Name: string
    CreateTime?: string
    Amount: number
}

export const fetchCashAccount = () => Ajax<ICashAccount>({url: 'distribution/getcashaccount'})

export interface ICashLog extends ICashAccount {
    CreateTime: string
    HandleStatus: number
    HandleStatusStr: string
    Remark: string
    Amount: number
}

export const fetchCashLog = () => Ajax<{ list: ICashLog[] }>({url: 'distribution/getcashlog'})

export const cashBrokeage = (params: ICashAccount) => Ajax({
    url: 'distribution/cashbrokeage',
    data: params
})

export const fetchTeamInfo = () => Ajax<{
    TotalBroke: number
    TeamNum: number
}>({url: 'distribution/getteaminfo'})

export interface IDistributionTeam {
    SaleUserID: number
    CusName: string
    OrderNum: number
    OrderAmount: number
    MonthAmount: number
    JoinTime: string
}

export const fetchTeamList = (data: {
    /*时间类型
0.全部
1.按月
2.按日期*/
    TimeType: number
    /*时间*/
    TimeValue: string
    OrderBy: number
} & IAjaxPage) => Ajax<{ list: IDistributionTeam[] }>({
    url: 'distribution/getteamlist',
    data
})
export const fetchTeamInvitationImg = () => Ajax<{
    codepath: string
    urlpath: string
}>({url: 'distribution/getteaminvitationimg'})

export interface IInviteInfo {
    ShortName: string
    CusName: string
    HeadIcon: string
    HeadIconUrl: string
    DistriAuditStatus: number
    SaleUserID: number
    HasParent: number
}

export const getEmptyInviteInfo = (): IInviteInfo => ({
    ShortName: '',
    CusName: '',
    HeadIcon: '',
    HeadIconUrl: '',
    DistriAuditStatus: 0,
    SaleUserID: 0,
    HasParent: 0,
})

export const fetchTeamInvitationInfo = (PUserID: number) => Ajax<IInviteInfo>({
    url: 'distribution/getteaminvitationinfo',
    data: {PUserID}
})

export const fetchFirstBrokeTime = () => Ajax<{ TimeStamp: string }>({url: 'distribution/getfirstbroketime'})

export const fetchFirstTeamTime = () => Ajax<{ TimeStamp: string }>({url: 'distribution/getfirstteamtime'})

export const joinTeam = () => Ajax<{
    Status: number
    Message: string
}>({url: 'distribution/jointeam'})


/*获取分销收益名称*/
export const getDistributionBrokeName = ({BrokeMode, BrokeName}: {/*0.佣金模式
    1.积分模式*/
    BrokeMode?: 1 | 0,
    //佣金名称
    BrokeName?: string
},defaultName = '收益') => {
    return BrokeMode === 1 ? BrokeName || defaultName : defaultName
}
