import {getLoginData, getLoginStorage} from "./storage/Login";

const TOKEN_NAME = 'ADMIN_TOKEN';

export const setToken = (token: string) => {
    localStorage.setItem(TOKEN_NAME, JSON.stringify({token, timestamp: new Date().getTime()}))
}

export const getToken = () => {
    return getLoginData().SCCode
}

export const removeToken = () => {
    getLoginStorage(true)
}
