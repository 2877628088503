import * as React from 'react'
// @ts-ignore
import Styles from './EllipsisToolTip.scss'
import {Tooltip} from "antd";
import {generateClassName} from "../../utils";


export const Ellipsis:React.FC<React.HTMLAttributes<HTMLDivElement>> = ({children,className,...attr}) => {
    return <div {...attr} className={generateClassName(Styles.ellipsis, className)}>
        {children}
    </div>
}

export const EllipsisSpan:React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({children,className,...attr}) => {
    return <span {...attr} className={generateClassName(Styles.ellipsis, className)}>
        {children}
    </span>
}

interface State {
    visible: boolean
}

export default class EllipsisToolTip extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>, State> {

    state: State = {
        visible: false
    }

    content = React.createRef<HTMLDivElement>()

    onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
        const cellChild = event.target as HTMLDivElement;
        if (cellChild) {
            const _node: Node = cellChild as Node;
            const range = document.createRange();
            range.setStart(_node, 0);
            range.setEnd(_node, _node.childNodes.length);
            const rangeWidth = range.getBoundingClientRect().width;
            const visible = rangeWidth > cellChild.offsetWidth
            this.state.visible === visible || this.setState({visible})
        }
    }

    onMouseLeave = () => {
        this.state.visible && this.setState({visible: false})
    }

    render() {
        const {children, ...attr} = this.props
        return <Tooltip title={children} visible={this.state.visible}>
            <Ellipsis {...attr} onMouseEnter={this.onMouseEnter}
                 onMouseLeave={this.onMouseLeave}>
                {children}
            </Ellipsis>
        </Tooltip>
    }
}


