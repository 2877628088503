//import smartJson from "./util/smartJson";
import md5 from 'md5'
import collectSonar from "./components/collectSonar";
import {uriGet} from "./utils/uriParam";
import {uAgent} from "./utils/browser";
import smartJson from "./utils/smartJson";
import {getHomeStorage} from "./utils/storage/Home";
import {getEmptyHomeData} from "./service/Home";
import {isFormData} from "./utils";
import {getLoginStorage} from "./utils/storage/Login";
import {getEmptyLoginData} from "./service/Login";
import {EGlobalKey, getStorageData} from "./utils/GlobalData";

/*TODO 测试 18621606561 123456*/

const IS_TEST = window.location.host.toLowerCase().indexOf('test.chepeilong.cn') >= 0,
    IS_DEVELOPMENT = process.env.NODE_ENV === 'development'
export const API_URL = IS_DEVELOPMENT ? 'http://localhost:3000/api' : IS_TEST ? 'http://cptapi.test.chepeilong.cn/api' : 'https://h5api.chepeitong.com/api'

export const getAPIHead: any = (data?: any) => {
    //console.log(_header,window._header)
    const HomeData = getHomeStorage() || getEmptyHomeData()
    const LoginData = getLoginStorage() || getEmptyLoginData()
    const head: any = {scptcode: HomeData.ScptCode, sccode: LoginData.SCCode, apptype: 2}
    const oneDay = 864e5

    const timestamp = new Date().getTime(),
        P_USER_ID = getStorageData(EGlobalKey.P_USER_ID)

    let channel = uriGet('channel')

    if (channel) {
        window && sessionStorage.setItem('channel', channel)
    } else if (window) {
        channel = sessionStorage.getItem('channel')
    }

    head.timestamp = timestamp
    //console.log(collectSonar.getUid())
    head.touristcode = collectSonar.getUid()

    head.webhost = `${window.location.origin}${HomeData.Host || ''}`

    //head.scptcode = '274661A244EF7E127'
    //head.sccode = '274661A244EF7E127'


    head.signcode = md5((head.sccode || '') + (data && !isFormData(data) ? smartJson(data) : '') + timestamp + (IS_DEVELOPMENT || IS_TEST ? 'cptapi' : 'BT1V25'))
    P_USER_ID && (head.PUserID = Number(P_USER_ID || 0))
    channel && (head.channel = channel)

    if (head.scptcode) {
        const viewsJson = window ? localStorage.getItem('views') : ''
        const views = viewsJson ? smartJson(viewsJson) : {}
        let spctView = views[head.scptcode]
        if (spctView) {
            if (spctView.next < timestamp) {
                head.isfirstview = 1
                spctView.next = timestamp + oneDay
            }
        } else {
            spctView = {uid: md5(uAgent + timestamp), next: timestamp + oneDay}
            head.isfirstview = 1
        }
        views[head.scptcode] = spctView
        window && localStorage.setItem('views', smartJson(views))
    }
    return head
}
