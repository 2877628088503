import * as React from 'react'
import {useLayoutEffect, useState} from "react";
import {fetchChooseAutoSeriesList, IAutoListItem} from "../../service/Common";
import {apiResponseCheck} from "../../utils";
import ScrollContainer from "../ScrollContainer";
import AutoTitle from "./AutoTitle";
import AutoItem from "./AutoItem";

type Props = {
    className?: string
    brandId?: string,
    onChange?(data: IAutoListItem): void
}

const AutoSeriesList: React.FC<Props> = ({className, brandId, onChange}) => {

    const [series, setSeries] = useState<IAutoListItem[]>([])

    useLayoutEffect(() => {
        brandId && fetchChooseAutoSeriesList(brandId).then(res => {
            apiResponseCheck<IAutoListItem[]>(res).then(data => {
                setSeries(data)
            })
        })
    }, [brandId])

    return <ScrollContainer className={className}>
        {series.map(group => <div key={group.key}>
            <AutoTitle>{group.showname || group.value}</AutoTitle>
            {group.child.map(item => <AutoItem key={item.key} data={item} onClick={onChange}/>)}
        </div>)}
    </ScrollContainer>
}

export default AutoSeriesList
