import {useState} from "react";
import {generateClassName} from "../../utils";
// @ts-ignore
import Styles from './Language.scss'
const languages=[{key:'chinese_simplified',value:'中文'},{key:'english',value:'English'},{key:'russian',value:'Русский язык'},]
export const Language=({className}:{className?: string})=>{
    const [showTrans,setShowTrans]=useState(false)
    const [to,setTo]=useState('')
    const changeLanguage=(t)=>{
        window.translate.changeLanguage(t)
        window.translate.execute();
        setTo(t)
        setShowTrans(false)
    }
    return    <div className={generateClassName(Styles.inputBox,className)}>
        <span  className={Styles.changeTxt}  onClick={()=>{setShowTrans(true)}}>语言</span>
        {!!showTrans&&<>
            <div className={ Styles.bg} onClick={()=>{setShowTrans(false)}}></div>

            <div className={generateClassName(Styles.languages,'languages') }>
                {languages.map(l=><div className={generateClassName(Styles.language,l.key===to?Styles.actived:'')} key={l.key} onClick={()=>{changeLanguage(l.key)}}>{l.value}</div>)}
            </div>
        </>}
    </div>
}