import * as React from 'react'
import {IHomeData} from "../../service/Home";
import {ILoginData} from "../../service/Login";
import {useState} from "react";
import {getHomeData, setHomeStorage} from "../../utils/storage/Home";
import {getLoginData, setLoginStorage} from "../../utils/storage/Login";

interface ISetData {
    HomeData?: IHomeData,
    LoginData?: ILoginData,
}

interface IStoreData {
    HomeData: IHomeData,
    LoginData: ILoginData,
}

export interface IStoreContext {
    store: {
        HomeData: IHomeData,
        LoginData: ILoginData,
    },
    setStore: (data: ISetData) => void
}


const StoreContext = React.createContext<IStoreContext>({
    store: {
        HomeData: getHomeData(),
        LoginData: getLoginData()
    },
    setStore: () => {
    }
})

export const StoreContextProvider: React.FC = ({children}) => {

    const [store, _setStore] = useState<IStoreData>({
            HomeData: getHomeData(),
            LoginData: getLoginData()
        }),
        setStore = (data: ISetData) => {
            const storeData = {...store, ...data}
            setLoginStorage(storeData.LoginData)
            setHomeStorage(storeData.HomeData)
            _setStore(storeData)
        }

    return <StoreContext.Provider value={{store, setStore}}>
        {children}
    </StoreContext.Provider>
}


export const StoreContextConsumer = StoreContext.Consumer
