import {compoundRoutePath} from "../utils";

export enum URLsComponent {
    Home = '/',
    Login = '/login',
    Agreement = '/agreement',
    Register = '/register',
    ForgetPwd = '/forget',
    CodeArea = '/area',
    Goods = '/goods-',
    GoodsList = '/goodslist',

    AfterSales = '/after-sales',

    AfterSales_Goods = '/after-sales/goods',
    AfterSales_Types = '/after-sales/types-',
    AfterSales_Cause = '/after-sales/cause',
    AfterSales_1 = '/after-sales-',

    SelectAuto = '/select-car',

    SearchAuto = '/select-car/search',
    Category = '/category',
    Search = '/search',
    ShopCar = '/shop-car',
    SubmitOrder = '/submit-order',

    ManageCenter = '/center',
    Collection = '/center/collection',
    BaseSet = '/center/base-set',
    News = '/center/news',
    NewsDetail = '/center/news-',

    AddressManage = '/center/address',
    AddAddress = '/center/address/add',

    EditAddress = '/center/address-',

    InvoiceManage = '/center/invoice',
    AddInvoice = '/center/invoice/add',

    EditInvoice = '/center/invoice-',

    AboutUs = '/about-us',
    Report = '/report',

    OrderList = '/orderlist',
    OrderList_1 = '/orderlist-',
    OrderDetail = '/order-',

    Logistics = '/logistics-',

    Evaluate = '/evaluate-',
    Vip = '/vip',

    QuickPurchase = '/quick',
    QuickPurchaseClassic = '/quick/classic',//快速采购
    QuickPurchasePicture = '/quick/picture',//快速采购
    QuickPurchaseEdit = '/quick/edit',
    QuickPurchaseAdd = '/quick/add',
    Func = '/func',
    Pay = '/payment-',
    PayResult = '/payresult-', //支付结果

    BillList = '/billlist', //账单列表
    BillDetail = '/bill-', //账单详情
    DRPOrder = '/drpbill-', //DRP保险订单详情
    OCRVin = '/ocrvin',
    AutoBrand = 'autobrand',
    AutoSeries = '/autoseries',

    QuoteOrder = 'quoteorder-', //drp报价单

    EPCPartsList = '/epcpartslist',
    EPCStructure = '/epcstructure',
    EPCPartsDetail = '/epcpartsdetail',
    RepairShopDetail = '/repairshopdetail',
    RepairShopMap = '/repairshopmap',
    CouponDetail = '/coupondetail-'
}

export const generateGoodsDetailURL = (id: number) => `${URLsComponent.Goods}${id}`

export const generateGoodsListURL = (pcateid: string | number = 0,
                                     cateid: string | number = 0,
                                     scateid: string | number = 0,
                                     autoid: number = 0,
                                     abid: number = 0,
                                     brandid: number = 0, seriesid: number = 0, showtype: number = 0, kw: string = '', GearboxParam = '') => {
    let url = URLsComponent.GoodsList
    let idArr = [GearboxParam, kw, showtype, seriesid, brandid, abid, autoid, scateid, cateid, pcateid]

    const index = idArr.findIndex(id => !!id)

    if (index < 0) return url

    return url + '/' + idArr.reverse().slice(0, idArr.length - index).map((id, index) => {
        switch (index) {
            case 0:
                return `pcateid-${id}`
            case 1:
                return `cateid-${id}`
            case 2:
                return `scateid-${id}`
            case 3:
                return `autoid-${id}`
            case 4:
                return `abid-${id}`
            case 5:
                return `brandid-${id}`
            case 6:
                return `seriesid-${id}`
            case 7:
                return `showtype-${id}`
            case 8:
                return `kw-${id}`
            case 9:
                return `gearbox-${id}`
        }
        return ''
    }).join('/')
}

export const generateAfterSalesTypeURL = (id: string | number) => `${URLsComponent.AfterSales_Types}${id}`

export const generateSelectAutoURL = (...attrArr: Array<string | number>) => {
    const index = attrArr.reverse().findIndex(id => !!id)
    if (index < 0) return URLsComponent.SelectAuto
    return URLsComponent.SelectAuto + '/' + attrArr.reverse().slice(0, attrArr.length - index).map((id, index) => {
        switch (index) {
            case 0:
                return `brand-${id}`
            case 1:
                return `series-${id}`
            case 2:
                return `model-${id}`
            case 3:
                return `year-${id}`
        }
        return ''
    }).join('/')
}

export const generateNewsDetailURL = (id: string | number) => `${URLsComponent.NewsDetail}${id}`

export const generateEditAddressURL = (id: string | number) => `${URLsComponent.EditAddress}${id}`

export const generateEditInvoiceURL = (id: string | number) => `${URLsComponent.EditInvoice}${id}`

export const generateLogisticsURL = (id: string | number) => `${URLsComponent.Logistics}${id}`

export const generateEvaluateURL = (id: string | number) => `${URLsComponent.Evaluate}${id}`

export const generatePayURL = (id: string | number) => `${URLsComponent.Pay}${id}`

export const generatePayURL2 = (id: string | number, couponId?: number) => `payment/id-${id}/couponid-${couponId}`

export const generatePayResultURLForApi = (id: string | number) => `${URLsComponent.PayResult}${id}`

export const generatePayResultURL = (id: string | number) => `${URLsComponent.PayResult}${id}`

export const generateOrderListURL = (id: string | number) => `${id ? URLsComponent.OrderList_1 : URLsComponent.OrderList}${id || ''}`

export const generateOrderDetailURL = (id: string | number) => `${URLsComponent.OrderDetail}${id}`

export const generateOrderDetailURLFormOther = (id: string | number) => `${URLsComponent.OrderDetail}${id}`

export const generateBillDetailURL = (id: string | number) => `${URLsComponent.BillDetail}${id}`

export const generateQuoteOrderURL = (id: string | number) => `${URLsComponent.QuoteOrder}${id}`
export const generateEPCPartsListURL = (type: number, key: string) => `${URLsComponent.EPCPartsList}/${type}/${key}`

export const generateEPCStructureURL = (id: string | number) => `${URLsComponent.EPCStructure}/${id}`

export const generateEPCPartsDetailURL = (id: string | number) => `${URLsComponent.EPCPartsDetail}/${id}`

export const generateRepairShopDetailURL = (id: string | number) => `${URLsComponent.RepairShopDetail}/${id}`
export const generateRepairShopMapURL = (id: string | number) => `${URLsComponent.RepairShopMap}/${id}`

export const generateCouponDetailURL = (id: string | number) => `${URLsComponent.CouponDetail}${id}`


export enum EURLs {
    Home = '/',//首页
    redirect = '/redirect',//跳转
    Login = '/login',//登录
    UserInfo = '/userInfo',//完善信息
    Agreement = '/agreement',//注册协议
    Register = '/register',//注册
    ForgetPwd = '/forget',//忘记密码
    CodeArea = '/area',//地区码
    Goods = '/goods-:id',//商品详情
    ReadOnlyGoods = '/:id',
    GoodsList = '/goodslist',//商品列表

    ExamineResult = '/examineresult', //等待注册审核

    AdvanceSearch = '/advsearch',//高级搜索
    EPCHome = '/epc',
    EPCSearch = '/epcsearch',
    EPCStructure = '/epcstructure/:id',
    EPCPartsList = '/epcpartslist/:type/:key',
    EPCPartsDetail = '/epcpartsdetail/:id',
    EPCShopCar = '/epcshopcar',
    ///goods-list/pcateid-***/cateid-***/scateid-**/autoid-**/abid-**/brandid-**
    //AutoSeriesID
    GoodsList_11 = '/goodslist/pcateid-:pcateid/cateid-:cateid/scateid-:scateid/autoid-:autoid/abid-:abid/brandid-:brandid/seriesid-:seriesid/showtype-:showtype/kw-/gearbox-:gearbox',
    GoodsList_10 = '/goodslist/pcateid-:pcateid/cateid-:cateid/scateid-:scateid/autoid-:autoid/abid-:abid/brandid-:brandid/seriesid-:seriesid/showtype-:showtype/kw-:kw/gearbox-:gearbox',
    GoodsList_9 = '/goodslist/pcateid-:pcateid/cateid-:cateid/scateid-:scateid/autoid-:autoid/abid-:abid/brandid-:brandid/seriesid-:seriesid/showtype-:showtype/kw-:kw',
    GoodsList_8 = '/goodslist/pcateid-:pcateid/cateid-:cateid/scateid-:scateid/autoid-:autoid/abid-:abid/brandid-:brandid/seriesid-:seriesid/showtype-:showtype',
    GoodsList_1 = '/goodslist/pcateid-:pcateid/cateid-:cateid/scateid-:scateid/autoid-:autoid/abid-:abid/brandid-:brandid/seriesid-:seriesid',
    GoodsList_2 = '/goodslist/pcateid-:pcateid/cateid-:cateid/scateid-:scateid/autoid-:autoid/abid-:abid/brandid-:brandid',
    GoodsList_3 = '/goodslist/pcateid-:pcateid/cateid-:cateid/scateid-:scateid/autoid-:autoid/abid-:abid',
    GoodsList_4 = '/goodslist/pcateid-:pcateid/cateid-:cateid/scateid-:scateid/autoid-:autoid',
    GoodsList_5 = '/goodslist/pcateid-:pcateid/cateid-:cateid/scateid-:scateid',
    GoodsList_6 = '/goodslist/pcateid-:pcateid/cateid-:cateid',
    GoodsList_7 = '/goodslist/pcateid-:pcateid',

    AfterSales = '/after-sales',//售后列表

    AfterSales_Goods = '/after-sales/goods',//售后选择商品
    AfterSales_Types = '/after-sales/types-:id',//售后选择退款类型
    AfterSales_Cause = '/after-sales/cause',//售后原因
    AfterSales_1 = '/after-sales-:id',//售后详情

    SelectAuto = '/select-car',//选择车型
    SelectAuto_1 = '/select-car/brand-:brand/series-:series/model-:model/year-:year',
    SelectAuto_2 = '/select-car/brand-:brand/series-:series/model-:model',
    SelectAuto_3 = '/select-car/brand-:brand/series-:series',
    SelectAuto_4 = '/select-car/brand-:brand',

    SearchAuto = '/select-car/search',//搜素车型
    Category = '/category',//分类
    Search = '/search',//搜索
    ShopCar = '/shop-car',//购物车
    SubmitOrder = '/submit-order',//提交订单

    ManageCenter = '/center',//个人中心
    Collection = '/center/collection',//收藏夹
    BaseSet = '/center/base-set',//基本信息设置
    News = '/center/news',//通知列表
    NewsDetail = '/center/news-:id',//通知详情

    AddressManage = '/center/address',//地址管理
    AddAddress = '/center/address/add',//添加地址

    EditAddress = '/center/address-:id',//修改地址

    InvoiceManage = '/center/invoice',//发票管理
    AddInvoice = '/center/invoice/add',//添加发票

    EditInvoice = '/center/invoice-:id',//修改发票

    AboutUs = '/about-us',//关于我们
    Report = '/report',//反馈

    OrderList = '/orderlist',//订单列表
    OrderList_1 = '/orderlist-:id',
    OrderDetailFromOther = '/order-:_id-',//订单详情
    OrderDetail = '/order-:id',//订单详情

    Logistics = '/logistics-:id',//物流信息

    Evaluate = '/evaluate-:id',//评价
    Vip = '/vip',//会员特权

    QuickPurchase = '/quick',//快速采购
    QuickPurchaseClassic = '/quick/classic',//快速采购
    QuickPurchasePicture = '/quick/picture',//快速采购
    QuickPurchaseEdit = '/quick/edit',//快速采购修改配件
    QuickPurchaseAdd = '/quick/add',//快速采购添加配件
    QuickPurchaseMultipleAdd = '/quick/multipleadd',//快速采购批量添加配件
    Func = '/func',//敬请期待
    Pay = '/payment-:id',//在线支付
    Pay2 = '/payment/id-:id/couponid-:couponid',//在线支付
    PayResult = '/payresult-:payid', //支付结果
    PayResult2 = '/payresult/payid-:payid/status-:status/message-:message/serialnumber-:serialnumber/ordercode-:ordercode',

    OCR = '/ocr',

    BillList = '/billlist', //账单列表
    BillDetail = '/bill-:id', //账单详情
    DRPOrder = '/drpbill-:code', //DRP保险订单详情
    OCRVin = '/ocrvin',
    AutoBrand = '/autobrand',
    AutoSeries = '/autoseries',

    QuoteOrder = 'quoteorder-:id', //drp报价单
    ScoreCenter = '/scorecenter',
    ScoreCash = '/scorecash',
    InviteRegister = '/inviteregister',
    SelectRepairShop = '/selectrepairshop',
    RepairShopList = '/repairshoplist',
    RepairShopDetail = '/repairshopdetail/:id',
    RepairShopMap = '/repairshopmap/:id',
    CouponList = '/couponlist',
    CouponDetail = '/coupondetail-:id',
    MyCoupon = '/mycoupon',
    DistributionGoods = '/distributiongoods',
    DistributionLand = '/distributionland-:PUserID',
    DistributionLog = '/distributionlog',
    DistributionCustomer = '/distributioncustomer',
    DistributionCash = '/distributioncash',
    DistributionTeam = '/distributionteam',
    DistributionInvite = '/distributioninvite',
    DistributionAudit = '/distributionaudit',
    DistributionSubmit = '/distributionsubmit'
}

export enum EUrlTitles {
    Home = '首页',
    Login = '用户登录',
    UserInfo = '完善信息',

    ExamineResult = '等待注册审核', //等待注册审核

    Agreement = '用户注册协议',
    Register = '快速注册',
    ForgetPwd = '忘记密码',
    CodeArea = '选择国家',
    Goods = '商品详情',
    ReadOnlyGoods = '商品详情',
    GoodsList = '商品列表',

    GoodsList_1 = '商品列表',
    GoodsList_2 = '商品列表',
    GoodsList_3 = '商品列表',
    GoodsList_4 = '商品列表',
    GoodsList_5 = '商品列表',
    GoodsList_6 = '商品列表',
    GoodsList_7 = '商品列表',
    GoodsList_8 = '商品列表',
    GoodsList_9 = '商品列表',

    AdvanceSearch = '高级搜索',//高级搜索

    AfterSales = '售后订单',

    AfterSales_Goods = '',
    AfterSales_Types = '选择退换货类型',
    AfterSales_Cause = '完善申请售后原因',
    AfterSales_1 = '售后详细',

    SelectAuto = '选择品牌',
    SelectAuto_1 = '选择版本',
    SelectAuto_2 = '选择年款',
    SelectAuto_3 = '选择车型',
    SelectAuto_4 = '选择车系',

    SearchAuto = '搜索',
    Category = '商品分类',
    Search = '搜索',
    ShopCar = '采购单',
    SubmitOrder = '提交采购订单',

    ManageCenter = '我的管理',
    Collection = '收藏夹',
    BaseSet = '基本信息',
    News = '消息通知',
    NewsDetail = '消息详情',

    AddressManage = '收货地址管理',
    AddAddress = '新增收货地址',

    EditAddress = '修改收货地址',

    InvoiceManage = '发票管理',
    AddInvoice = '新增开票信息',

    EditInvoice = '修改开票信息',

    AboutUs = '关于我们',
    Report = '意见反馈',

    OrderList = '',
    OrderList_1 = '',
    OrderDetailFromOther = '订单详情',
    OrderDetail = '订单详情',

    Logistics = '物流详情',

    Evaluate = '用户评价',
    Vip = '会员权益',

    QuickPurchase = '快速询价',
    QuickPurchaseClassic = '快速询价',
    QuickPurchasePicture = '快速询价',
    QuickPurchaseEdit = '修改配件',
    QuickPurchaseAdd = '添加配件',
    QuickPurchaseMultipleAdd = '添加配件',
    Func = '敬请期待',
    Pay = '选择支付方式',
    Pay2 = '选择支付方式',
    PayResult = '支付结果',
    OCR = '自动识别',

    BillList = '结算单管理', //账单列表
    BillDetail = '结算单', //账单详情
    DRPOrder = '查看报价信息',
    OCRVin = '自动识别',
    AutoBrand = '选择品牌',
    AutoSeries = '选择车系',

    QuoteOrder = '报价清单', //drp报价单
    EPCHome = 'EPC查询',
    EPCSearch = 'EPC查询',
    EPCStructure = '结构图',
    EPCPartsList = '配件列表',
    EPCPartsDetail = '配件详情',
    EPCShopCar = '询价清单',
    ScoreCenter = '积分中心',
    ScoreCash = '积分提现',
    InviteRegister = '邀请注册',
    SelectRepairShop = '选择服务门店',
    RepairShopList = '门店列表',
    RepairShopDetail = '门店详情',
    RepairShopMap = '门店地图',
    CouponList = '优惠券中心',
    CouponDetail = '优惠券详情',
    MyCoupon = '我的优惠券',
    DistributionGoods = '分销商品',
    DistributionLand = '加入团队',
    DistributionLog = '我的收益',
    DistributionCustomer = '我的客户',
    DistributionCash = '收益提现',
    DistributionTeam = '我的团队',
    DistributionInvite = '团队邀请',
    DistributionAudit = '分销申请',
    DistributionSubmit = '提交分销申请'
}

export const setPageTitleByURLs = (path: EURLs) => {
    console.log(path, 'path')
    for (let x in EURLs) {
        // @ts-ignore
        if (x !== 'Home' && compoundRoutePath(EURLs[x]) === path) {
            // @ts-ignore
            setPageTitle(EUrlTitles[x])
            break
        }
    }
}

export const setPageTitle = (title: string) => {
    if (window) {
        window.document.title = title
    }
}
