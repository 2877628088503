import {requestPost} from "../../utils/request";
import {ECompanyAuth, ECustomerType, EPersonalAuth, ESettlementType, ESex} from "../Login";

export interface ICompanyAuthInfo {
    CompanyAuth: ECompanyAuth
    LicenseImagePath?: string
    CompanyType?: number
    CompanyName?: string
    CreditCode?: string
    LicenseNum?: string
    OrganizationCode?: string
    TaxNum?: string
    OpeningBank?: string
    BankNumber?: string
    AuthMobile?: string
}


export interface IPersonalAuthInfo {
    PersonalAuth: EPersonalAuth
    IdCardFrontPath?: string
    IdCardBackPath?: string
    IdCardName?: string
    IdCard?: string
}

export interface IBaseSetInfo {
    Name?: string
    Mobile: string
    Sex?: ESex
    CustomerType: ECustomerType
    Category?: number
    OtherPhone?: string
    Email?: string
    VIPLevelName?: string
    QQ?: string
    Wechat?: string
    ProvinceID?: number
    CityID?: number
    AreaID?: number
    ProvinceName?: string
    CityName?: string
    AreaName?: string
    Address?: string
    SettlementType?: ESettlementType
    HeadIconUrl?: string,
    HeadIcon?: string,
}

export const getEmptyBaseSetInfo = (): IBaseSetInfo => ({
    Mobile: '',
    CustomerType: ECustomerType.Personal
})

export interface ISaveBaseSetRequest extends IBaseSetInfo {
    ActionType?: number
}

export const fetchBaseSetAction = () => requestPost<IBaseSetInfo>('/user/getuserinfo.ashx')

export const saveBaseSetAction = (data: ISaveBaseSetRequest) => requestPost('/user/usermanage.ashx', {
    ...data,
    ActionType: 1
})
