import * as React from 'react'
import {useEffect, useLayoutEffect, useState} from 'react'
//@ts-ignore
import Styles from './Header.scss'
import {apiResponseCheck, compoundRoutePath, generateClassName} from "../../utils";
import {Button, Image, Input, message, Select, Space, Typography} from "antd";
import {EHomeSearchType, fetchBottomNavigation, INavItem} from "../../service/Home";
import {getCheckedAuto} from "../../service/Login";
import {EditOutlined} from '@ant-design/icons'
import {Ellipsis} from "../EllipsisToolTip";
import ChooseAutoModal from "../ChooseAutoModal";
import {fetchUserAuto, IUserAutoItem, saveAutoAction} from "../../service/My";
import StoreWith from "../StoreWith";
import UserAutoModal from "./UserAutoModal";
import {setShowAddAutoFunc, setShowAutoFunc, setShowAutoVerFunc} from "../../utils/GlobalData";
import AutoVerModal from "../AutoVerModal";
import {IAutoListItem} from "../../service/Common";
import AnchorLink from "../AnchorLink";
import {useNavigate} from "react-router-dom";
import {generateGoodsListURL} from "../../types/URLs";
import {SearchTypeList} from "../../service/Item";
import {EStyleClass, ISelectOption} from "../../types";
import AdvanceSearchModal from "./AdvanceSearchModal";

const Header: React.FC = StoreWith(({store, setStore}) => {

    const navigate = useNavigate(),
        [navList, setNavList] = useState<INavItem[]>([]),
        [showAddAuto, setShowAddAuto] = useState(false),
        [showAuto, setShowAuto] = useState(false),
        [showAutoVer, setShowAutoVer] = useState(false),
        [showAdvance, setShowAdvance] = useState(false),
        [kw, setKw] = useState(''),
        HomeData = store.HomeData,
        auto = getCheckedAuto(store.LoginData),
        searchList = (HomeData.ShowItems?.split(',').map(id => SearchTypeList.find(item => item.value === Number(id))).filter(item => !!item) ?? []) as ISelectOption[],
        isAdvance = store.HomeData.SearchType === EHomeSearchType.advance && !!store.HomeData.ShowItems,
        onVerChange = (data: IAutoListItem) => {
            if (auto) {
                auto.AutoID = Number(data.key)
                auto.SalesVersion = data.value
                if (store.LoginData.SCCode) {
                    saveAutoAction(auto.GearboxParam, auto.AutoID)
                }
            }
            setStore({LoginData: store.LoginData})
            message.success('操作成功')
        },

        onAddChange = (data: IUserAutoItem) => {
            if (!store.LoginData.AutoList.some(item => item.GearboxParam === data.GearboxParam)) {
                if (store.LoginData.SCCode) {
                    saveAutoAction(data.GearboxParam)
                }

                store.LoginData.AutoList.unshift({
                    AutoName: data.AutoName,
                    GearboxParam: data.GearboxParam,
                    IsSelect: true
                })
            }
            store.LoginData.AutoList.forEach(item => item.IsSelect = item.GearboxParam === data.GearboxParam)
            setStore({LoginData: store.LoginData})
            message.success('添加成功')
        }

    setShowAutoFunc(() => setShowAuto(true))

    setShowAutoVerFunc(() => setShowAutoVer(true))

    setShowAddAutoFunc(() => setShowAddAuto(true))

    useLayoutEffect(() => {
        store.LoginData.SCCode && fetchUserAuto().then(res => {
            apiResponseCheck(res).then(data => {
                store.LoginData.AutoList = data
                setStore({LoginData: store.LoginData})
            })
        })
    }, [store.LoginData.SCCode])

    useLayoutEffect(() => {
        fetchBottomNavigation().then(res => {
            apiResponseCheck<{ NavigationList: INavItem[] }>(res).then(data => {
                setNavList(data.NavigationList)
            })
        })
    }, [])


    return <div className={Styles.container}>
        <div className={generateClassName(EStyleClass.ContentWith, Styles.headerBox)}>
            <Image preview={false} className={Styles.logo} src={HomeData.LogoPath}/>

            <Typography.Title className={Styles.title} level={5}>{HomeData.ShowName}</Typography.Title>

            {navList.map(item => <AnchorLink href={item.ToPage} key={item.NavigationID}>
                <span
                    className={generateClassName(Styles.navBtn, EStyleClass.TextClickImportant)}>{item.NavigationName}</span>
            </AnchorLink>)}

            <div className={Styles.endBox}>
                <Space>
                    <Input.Search allowClear size={"large"} placeholder={'搜索配件/品牌/OE/车型'}
                                  className={Styles.input} value={kw} onChange={e => setKw(e.target.value)}
                                  onClick={isAdvance ? () => setShowAdvance(true) : undefined}
                                  onSearch={() => isAdvance ? setShowAdvance(true) : navigate(compoundRoutePath(generateGoodsListURL(0, 0, 0, 0, 0, 0, 0, 0, kw)))}/>

                    {auto ? <Button size="large" type="dashed" onClick={() => setShowAuto(true)}>
                            <Space>
                                <EditOutlined/>
                                <Ellipsis style={{width: 78, display: 'inline-block'}}>{auto.AutoName}</Ellipsis>
                            </Space>
                        </Button> :
                        <Button type={"primary"} size={"large"} onClick={() => setShowAuto(true)}>选择车型</Button>}
                </Space>
            </div>
        </div>

        <UserAutoModal store={store} setStore={setStore} visible={showAuto} onCancel={setShowAuto}
                       onClick={() => setShowAddAuto(true)}/>

        <ChooseAutoModal visible={showAddAuto} onChange={onAddChange} onCancel={setShowAddAuto}/>

        <AutoVerModal gearbox={auto?.GearboxParam} visible={showAutoVer} onCancel={setShowAutoVer}
                      onChange={onVerChange}/>

        <AdvanceSearchModal homeData={store.HomeData} searchList={searchList} visible={showAdvance}
                            onChange={onAddChange} onCancel={setShowAdvance}/>
    </div>
})

export default Header
