import {IAjaxPage, requestPost} from "../utils/request";
import {ECategoryShow} from "./Common";
import {EItemType} from "./Home";
import {EInvoiceType} from "./Invoice";
import {EPayType, ICouponListItem, IReserveItem} from "./OrderManage";
import {IListData, ISelectOption} from "../types";
import {EGoodsLevel, EGoodsPost, EGoodsStatus, EStockType} from "./ShoppingCart";
import {ESettlementType} from "./Login";
import {IRepairShopItem} from "./RepairShop";

export enum EAfterSalesApplyType {
    Refund = 1,
    ReturnGoods = 2
}

export enum EAfterSalesGoodsStatus {
    Received = 1,
    NotReceived = 2
}

export enum EAdvanceSearchType {
    part = 1,
    vin = 2,
    auto = 3,
}

export const SearchTypeList: ISelectOption[] = [
    {value: EAdvanceSearchType.part, label: '按配件'},
    {value: EAdvanceSearchType.vin, label: '按VIN码'},
    {value: EAdvanceSearchType.auto, label: '按车型'},
]

/*商品搜索*/
export enum ESearchType {
    //1:配件名称(商品名称)
    PartName = 1,
    //2:OE编号
    OECode = 2,
    //3:VIN码
    VINCode = 3,
    //4:品牌名称
    BrandName = 4,
    //5:车型名称
    AutoName = 5,
    //6.三级分类搜索
    Category = 6,
}

export interface ISearchGoodsRequest {
    SearchType: ESearchType
    KeyWord: string
}

export interface ISearchGoodsItem {
    key: string
    value: string
}

export const SearchGoodsAction = (data: ISearchGoodsRequest) => requestPost<ISearchGoodsItem[]>('/item/searchdata.ashx', data)


/*获取商品列表*/
export enum EListOrderType {
    Def = 0,
    ASC = 1,
    DESC = 2,
}

export enum EListIsMatching {
    All = 0,
    AppleAuto = 1
}

export enum EItemModel {
    MainItem = 1,
    SubItem = 2,
}

export interface IGoodsListRequest {
    OrderBy?: EListOrderType
    AutoID?: number
    ParentCategoryID?: number
    CategoryID?: number
    PartsID?: number
    ItemName?: string
    IsMatching?: EListIsMatching
    BrandID?: number
    AutoBrandID?: number
    AutoSeriesID?: number
    CategoryName?: string
    SaleType?: number
    CategoryShow?: ECategoryShow,
    RemarkCategoryID?: number
    ItemType?: number
    Level?: number,
    SearchKey?: string
    GearboxParam?: string
}


export interface IGoodsListItem {
    ItemID: number
    ItemShowName: string
    PartsID: number
    PartsName: string
    OENO: string
    AutoNames: string
    ItemType: EItemType
    SalesPrice: number
    OldPrice: number
    IsMatching: number
    Images: string[]
    AutoCount: number

    IsCurrency?: number
    IsCommon?: 0 | 1
    UnLookSalesPrice?: number
    UnLookWholePrice?: number
    Standard?: string
    Position?: string,

    MinPrice?: number,
    ItemModel: EItemModel
}

export const fetchItemList = (data: IGoodsListRequest & IAjaxPage) => requestPost<IListData<IGoodsListItem>>('/item/getitemlist.ashx', data)


/*获取商品列表对应品牌*/
export interface IGoodsBrandItem {
    key: string
    value: string
    icon: string
}

export const fetchGoodsBrandList = (data: IGoodsListRequest) => requestPost<IGoodsBrandItem[]>('/item/getitemlistbrand.ashx', data)

/*获取商品详情*/
export interface IDetailRequest {
    ItemID: number,
    AutoID?: number,
    GearboxParam?: string
    SID?: string
    PUserID?: any
    SubItemIDs?: string
    readOnly?: boolean
}

export const fetchGoodsDetail = ({
                                     readOnly,
                                     ...data
                                 }: IDetailRequest) => requestPost<IGoodsDetail>(`/item/${readOnly ? 'getiteminfoforshare' : 'getiteminfo'}.ashx`, data)

export interface IDetailOtherRequest {
    ItemID: number
    AutoID?: number,
    SearchType?: number,
}

const fetchItemOtherInfo = <T>(data: {
    ItemID: number
    AutoID?: number

    SearchType?: number,
}) => requestPost<T>('/item/getitemother.ashx', data)

/*获取其他适用车型*/
export interface IOtherCarBrandItem {
    AutoName: string
    EngineModel: string
    ProductYear: string
}

export interface IOtherCarItem {
    BrandName: string
    AutoList: IOtherCarBrandItem[]
}

export const fetchItemOtherAutoList = (data: IDetailOtherRequest & IAjaxPage) => fetchItemOtherInfo<IOtherCarItem[]>({
    ...data,
    SearchType: 1
})

/*获取其他适用配件*/
export interface IOtherPartItem extends IGoodsListItem {


    Remark?: string


}

export const fetchItemOtherPartList = (data: IDetailOtherRequest) => fetchItemOtherInfo<IOtherPartItem[]>({
    ...data,
    SearchType: 2
})

/*获取购买须知*/
export const fetchItemBuyInfo = (data: IDetailOtherRequest) => fetchItemOtherInfo<string>(data)

/*获取商品海报*/
export enum EPosterShareType {
    image = 1,
    url = 2,
}

export const fetchItemPosterImg = (data: {
    ItemID: number, SubItemIDs?: string,
    ShareType?: EPosterShareType,
}) => requestPost<{ codepath: string }>('/item/getitemposterimg.ashx', data)


/*获取订单列表*/
export enum EOrderListType {
    All = 0,
    PendingOrder = 1,
    ToBeConfirmed = 2,
    PendingPayment = 3,
    PendingDelivery = 4,


    WaitForASignToSignIn = 5,
    SettlementToBeSettled = 6,
    TransactionCompletion = 7,

    TransactionClosureBy = -1,

    AfterSaleFailure = -10,

    AfterSaleCompletion = -11,

    AfterSale = -12,
    AllAfterSale = -13,
}

export enum EQualityType {
    none = 0,
    original = 1,
    brand = 2,
    cc = 3,
    recover = 4,
}


export const QualityTypeOptions: ISelectOption[] = [
    {value: EQualityType.none, label: '无要求'},
    {value: EQualityType.original, label: '原厂件'},
    {value: EQualityType.brand, label: '品牌件'},
    {value: EQualityType.cc, label: '拆车件'},
    {value: EQualityType.recover, label: '再制造件'},
]


export interface IGoodsItem {
    DetailID: number
    ItemID: number
    ItemName: string
    OENO: string
    SalePrice: number
    Quantity: number
    Quality?: EQualityType | string
    QualityType?: string
    Images: string[]
    Level: string,
    TotalPrice: number
    Foursprice?: number
    AutoNames?: string
    AutoName?: string
    Orderdays?: number

    IsDisabled?: boolean
    SubItemID: number
    SubName: string
}

export interface IAfterSalesGoodsItem extends IGoodsItem {
    ReturnNum?: number
    RealReturnNum?: number
    RealReturnAmount?: number
}

export enum EOrderStatus {
    PendingOrder = 1,
    ToBeConfirmed = 2,
    PendingPayment = 3,
    PendingDelivery = 4,


    WaitForASignToSignIn = 5,
    SettlementToBeSettled = 6,
    TransactionCompletion = 7,

    CanceledByCustomer = -1,
    CanceledByBusiness = -2,
    CanceledBySystem = -3,

    AfterSaleFailure = -10,
    AfterSaleCompletion = -11,
    AfterSale = -12
}

export enum EOrderFromType {
    Common = 1,
    Quotation = 2,
    QuickPurchase = 3,
    AfterSale = 4
}

export enum EOrderSource {
    Cusomter = 1,
    Business = 2
}

export interface IOrderItem extends ICommonOrder {
    OrderAmount: number
    PayableAmount: number
    FreightAmount: number
    ImageList: string[]
    QuickType: number

    ItemList: IGoodsItem[]
}

export interface IOrderListRequest {
    OrderStatus: EOrderListType
    SearchKey?: string
}

export const fetchOrderList = (data: IOrderListRequest & IAjaxPage) => requestPost<IOrderItem[]>('/order/getorderlist.ashx', data)

/*获取订单详情*/
export enum EPayStatus {
    Payed = 1,
    UnPayed = 2
}

export enum EFreightType {
    //包邮
    Free = 1,
    //买家到付
    hargeC = 2,
    //买家支付
    pay = 3,
    //到店自取
    manual = 4
}

export interface IOrderDetailRequest {
    OrderCode: string
    OrderType?: EOrderFromType
}

export interface ICommonOrder {
    OrderCode: string
    OrderStatus: EOrderStatus
    OrderStatusStr: string
    OrderType: EOrderFromType
    Telphone?: string
    EvaluateStatus?: number
    PayType: EPayType
    OrderSource: EOrderSource
    CusPayType?: string
    CreditAmount?: number
    ReturnStatus?: number
    IsReturnAll?: number
    PayStatus?: EPayStatus
    HasItem?: number
    CanInvoiceType?: string
    SendRemark?: string
    HasPay: number
    HasQuote: number
    HasReturn: number
    HasSale: number
    HasQuoteExcel: number
    ExcelName: string
    QuoteUrl: string
    SaleUrl: string
}

export interface ICommonOrderDetail extends ICommonOrder {
    QuickType: number
    QuickExpectedDate: string
    QuickInvoiceTypeStr: string
    QuickAddress: string

    ItemAmount?: number
    OrderAmount?: number
    DiscountAmount?: number
    PayableAmount?: number
    FreightAmount?: number
    PayAmount?: number
    ScoreAmount?: number
    CouponAmount?: number
    AdditionalAmount?: number
    NoSmallAmount?: number
    FreightType: EFreightType
    FreightTypeStr?: string

    IsNoSmallAmount?: number

    PredictTime?: string

    CreateTime: string
    TakeTime?: string
    QuoteTime?: string
    ConfirmTime?: string
    PayTime?: string
    SendTime?: string
    SignTime?: string
    SettlementTime?: string
    CompleteTime?: string
    CancelTime?: string

    Receiver?: string
    Mobile?: string

    Address?: string
    InvoiceID?: number
    InvoiceTypeStr?: string
    InvoiceType?: EInvoiceType
    IsNeedInvoice?: number
    InvoiceTitle?: string
    SerialCode?: string
    OrderRemark?: string

    RemarkImg?: string[]

    ShopRemark?: string

    LogisticsName?: string
    ExpressNO?: string

    CancelReason?: string
    VinNo?: string
    ItemList: IGoodsItem[]

    OriginalAmount?: number
    OriginalCategory?: number
    OriginalNum?: number
    BrandAmount?: number
    BrandCategory?: number
    BrandNum?: number
    DCarCategory?: number
    DCarNum?: number
    DCarAmount?: number


    ServiceAmount?: number
    ShopInfo?: IRepairShopItem & { ReserveInfo?: IReserveItem },
    LastQuote?: string
}

/*售后订单*/
export interface IAfterSalesOrder extends ICommonOrder {
    ReturnOrderCode: string
    ApplyType: EAfterSalesApplyType
    ApplyTypeStr: string
    GoodType: EAfterSalesGoodsStatus
    GoodTypeStr: string

    Reason: string
    Remark: string
    //ShopRemark:string
    RealPayAmount: number
    ReturnItemList: IAfterSalesGoodsItem[]

    Images: string[]

    ApplyPayAmount: number
    RealReturnNum: number
    ApplyTime: string
    FailTime?: string

    ShopImages?: string[]
}

/*售后 end*/


/*快速采购单*/
export interface IQuickPurchasePart extends ICommonOrder {
    PartsName: string
    OENO: string
    QualityType: EQualityType
    QualityTypeStr?: string,
    Quantity: number
    Images: string[]
}

export interface IQuickPurchaseDetail extends ICommonOrder {
    AutoName?: string
    BrandName?: string
    VinNo: string
    //PartsList:QuickPurchasePart[]
    Images: string[]
    QualityType: EQualityType
    InquiryRemark?: string
}


export type ITotalOrderDetail = IQuickPurchaseDetail & IQuickPurchasePart & IAfterSalesOrder & ICommonOrderDetail

export const getEmptyCommonOrderDetail = (): ITotalOrderDetail => ({
    OrderCode: '',
    OrderStatus: EOrderStatus.PendingOrder,
    OrderStatusStr: '',
    OrderType: EOrderFromType.Common,
    PayType: EPayType.Payed,
    OrderSource: EOrderSource.Cusomter,
    HasPay: 0,
    HasQuote: 0,
    HasReturn: 0,
    HasSale: 0,
    HasQuoteExcel: 0,
    ExcelName: '',
    QuoteUrl: '',
    SaleUrl: '',

    QuickType: 0,
    QuickExpectedDate: '',
    QuickInvoiceTypeStr: '',
    QuickAddress: '',


    FreightType: EFreightType.Free,


    CreateTime: '',

    ItemList: [],

    ReturnOrderCode: '',
    ApplyType: EAfterSalesApplyType.Refund,
    ApplyTypeStr: '',
    GoodType: EAfterSalesGoodsStatus.Received,
    GoodTypeStr: '',

    Reason: '',
    Remark: '',
    //ShopRemark:string
    RealPayAmount: 0,
    ReturnItemList: [],

    Images: [],

    ApplyPayAmount: 0,
    RealReturnNum: 0,
    ApplyTime: '',

    PartsName: '',
    OENO: '',
    QualityType: EQualityType.cc,
    Quantity: 0,
    VinNo: '',
})

export const fetchOrderInfo = (data: IOrderDetailRequest) => requestPost<ITotalOrderDetail>('/order/getorderinfo.ashx', data)


/*获取物流*/
export interface ILogisticsItem {
    Title: string
    Content: string
    TimeValue: string
}

export interface ILogisticsResponse {
    LogisticsName: string
    LogisticsCode: string
    LogisticsDetail: ILogisticsItem[]
}

export const fetchLogisticsInfo = (OrderCode: string) => requestPost<ILogisticsResponse>('/order/getlogisticsinfo.ashx', {OrderCode})

/*订单评价*/
export interface IEvaluateRequest {
    OrderCode: string
    ItemMark: number
    OverallMark: number
    AttitudeMark: number
    LogisticsMark: number
    Content: string
}

export const saveEvaluateAction = (data: IEvaluateRequest) => requestPost('/order/orderreview.ashx', data)


/*订单状态操作*/
export enum EOrderActionType {
    //1:取消订单
    CancelOrder = 1,
    //2:提醒接单
    NoticeReceive = 2,
    //3:确认订单
    ConfirmOrder = 3,
    //4:确认收货
    ConfirmCollect = 4,
    //5:取消申请
    CancelApply = 5,
    //6:删除订单
    DeleteOrder = 6,
    //7.提醒发货
    NoticeDeliver = 7,
    //8.提醒确认付款
    NoticePayConfirm = 8,
}

export interface IOrderActionRequest {
    OrderCode: string
    OrderType: EOrderFromType
    ActionType: EOrderActionType
    Reason?: string
    InvoiceInfoID?: number
    AddressID?: number
    ShopID?: number
    PayType?: EPayType
    ItemList?: { ItemID: number, ItemName?: string, DetailID: number }[]
    LastQuote?: string,
    OrderRemark?: string,
    IsUseScore?: 0 | 1
    UseScore?: 0 | 1
    ScoreAmount?: number
    ProjectList?: {
        ShopProjectID: number,
        ProjectName: string,
        ServicePrice: number
    }[],
    ReserveInfo?: IReserveItem
    CouponInfo?: ICouponListItem
}

export const CancelOrderReasonOptions: ISelectOption[] = [
    {value: '我不想买了', label: '我不想买了'},
    {value: '信息填写错误，重新下单', label: '信息填写错误，重新下单'},
    {value: '已在其他渠道，购买', label: '已在其他渠道，购买'},
]

export const setOrderStatus = (data: IOrderActionRequest) => requestPost('/order/setorderstatus.ashx', data)

/*获取订单可退货信息*/
export interface ICanAfterSalesItem {
    ItemID: number
    ItemName: string
    OENO: string
    SalePrice: number
    Quantity: number
    Images: string[]
    Level: number
    DetailID: number
    ReturnNum?: number
    checked: boolean
}

export type ICanOrderReturnInfo = {
    OrderCode: string
    ReturnAddress: string
    ReturnReceiver: string
    ReturnMobile: string
    ItemList: ICanAfterSalesItem[]
}

export const getEmptyCanOrderReturnInfo = (): ICanOrderReturnInfo => ({
    OrderCode: '',
    ReturnAddress: '',
    ReturnReceiver: '',
    ReturnMobile: '',
    ItemList: [],
})

export const fetchCanOrderReturnInfo = (OrderCode: string) => requestPost<ICanOrderReturnInfo>('/order/getorderreturninfo.ashx', {OrderCode})


/*售后申请*/
export interface IReturnGoodsItem {
    DetailID: number
    ItemID: number
    ReturnQuantity: number
}

export interface ISubmitReturnOrderRequest {
    OrderCode: string,
    ApplyType: EAfterSalesApplyType
    GoodType?: EAfterSalesGoodsStatus
    Reason: string
    Remark?: string
    Images?: string
    ReturnItem: IReturnGoodsItem[]
}

export const fetchQuoteOrderDetail = (data: ISubmitReturnOrderRequest) => requestPost('/order/applyreturnorder.ashx', data)


/*完善待确认订单*/
export interface IPerfectOrderRequest {
    OrderCode: string,
    InvoiceInfoID?: number,
    AddressID?: number
    PayType?: EPayType
}

export const perfectOrder = (data: IPerfectOrderRequest) => requestPost('/order/perfectorder.ashx', data)


/*支付订单*/
export interface IAccountInfo {
    account: string,
    encryptdata: string,
    qrcodepath?: string,
    realname: string,
    downurl?: string
    bankname?: string
    subbank?: string
}

export interface IPayOrderRequest {
    OrderCode?: string,
    PayType: number,
    CouponID?: number,
    PayMethod?: number,
    accountid?: number,
    ReturnUrl?: string,
    accountinfo?: IAccountInfo & {
        payvoucher?: string
    }
}


export interface IPayOrderResponse {
    url?: string
    payid: string
    param: {
        version: string
        cmd_id: string
        mer_cust_id: string
        check_value: string
        url: string
    }
}

export const payOrder = (data: IPayOrderRequest) => requestPost<IPayOrderResponse>('/order/payorder.ashx', data)


/*获取支付结果*/
export enum EPayResultStatus {
    unknown = 1,
    success = 2,
    fail = 3
}

export interface IPayResult {
    status: EPayResultStatus
    message: string
    //交易流水
    serialnumber: string
    //订单编号
    ordercode: string
}

export const fetchPayResult = (payid: string) => requestPost<IPayResult>('/order/getpayresult.ashx', {payid})


/*获取订单支付信息*/
export interface IPayMethodItem {
    accountid?: number,
    payname: string
    paytype: number
    icon: string
    accountinfo?: IAccountInfo
    methodlist: IPaymentItem[]
}

export interface IPaymentItem {
    paymethod: number
    methodname: string
    //remark:string
    icon: string
}

export interface IPayOrderInfoResponse {
    shopname: string
    ordercode: string
    createtime: string
    itemnum: number
    payamount: number
    shopmobile: string,
    paylist: IPayMethodItem[]
}

export const getEmptyIPayOrderInfo = (): IPayOrderInfoResponse => ({
    shopname: '',
    ordercode: '',
    createtime: '',
    itemnum: 0,
    payamount: 0,
    shopmobile: '',
    paylist: []
})

export const fetchPayOrderInfo = (OrderCode: string, CouponID?: number) => requestPost<IPayOrderInfoResponse>('/order/getpayorderinfo.ashx', {
    OrderCode,
    CouponID
})

/*重新支付订单*/
export const repayOrder = (payid: string) => requestPost<IPayOrderResponse & { ispcwx?: boolean | 0 | 1 }>('/order/repayorder.ashx', {payid})

/*获取报价订单详情*/
export interface IQuoteOrderDetail extends ICommonOrderDetail {
    CusName: string
    ShopName: string
}

export const fetchQuoteInfo = (OrderCode: string) => requestPost<IQuoteOrderDetail>('/order/getquoteinfo.ashx', {OrderCode})


export interface IVerItem {
    AutoID: number
    AutoName: string
    IsMatching: number
}

export interface ISubItem {
    SubItemID: number
    SubName: string
    SalesPrice: number
    WholeSalePrice: number
    VIPPrice: number
    VIPWholePrice: number
    StockType: number
    StockNum: number
    Orderdays: number
    SaleType: number
    MinWholeSaleNum: number
    Images: string[]
    PriceStr: string
    ColList: ISubItemCol[]
    Quantity: number
    checked?: boolean
}

export interface ISubItemCol {
    colname: string
    cplvalue: string
}

export interface IGoodsDetail {
    ItemID: number
    ItemShowName: string
    Status: EGoodsStatus
    SalesPrice: number
    OldPrice: number
    WholeSalePrice: number
    Foursprice?: number
    VIPPrice: number
    VIPWholePrice: number
    Quantity: number
    StockType: EStockType
    StockNum: number
    Orderdays: number
    SaleType: number
    MinWholeSaleNum: number
    MaxBuyNum: number
    OENO: string
    Level: EGoodsLevel
    IsFreePost: EGoodsPost
    IsMatching: number
    Remark: string
    CptRemark?: string
    WarrantyPeriod: string
    PartsName: string
    Unit: string
    Images: string[]
    IsFavorite: number
    ItemType: EItemType
    Weight: number
    PartsNo: string
    AutoID: number
    IsCustomer: number
    ServicePhone: string
    HasSuitAuto?: number

    SettlementType?: ESettlementType
    IsCurrency?: number
    IsCommon?: number
    UnLookSalesPrice?: number
    UnLookWholePrice?: number
    DefaultImage?: string
    BrandName?: string,

    Position?: string,

    ViewNum?: number,
    FavoriteNum?: number,

    Special: string
    BuyDepict?: string,

    DepictImageList: {
        ImageName: string
        SmallImg: string
        BigImg: string
    }[]
    Standard: string
    AutoList: IVerItem[]
    ShareUrl?: string
    DisUrl?: string
    IsShowSaleItem: number
    PriceStr: string
    BrokeageType: number
    Brokeage: number
    InBrokeageType: number
    InBrokeage: number
    /*
    * 分销状态
0.未申请
1.审核中
2.审批成功
3.审批失败
    * */
    DistriAuditStatus: number
    DistriAuditStatusStr: number
    SaleUserID: number
    ItemModel: number
    MinPrice: number
    MaxPrice: number
    MinSalesPrice: number
    MinWholeSalePrice: number
    SubItemList: ISubItem[]
}

export const getEmptyGoodsDetail = (): IGoodsDetail => ({
    ItemID: 0,
    ItemShowName: '',
    Status: EGoodsStatus.ONLINE,
    SalesPrice: 0,
    OldPrice: 0,
    WholeSalePrice: 0,
    VIPPrice: 0,
    VIPWholePrice: 0,
    Quantity: 0,
    StockType: EStockType.Online,
    StockNum: 0,
    Orderdays: 0,
    SaleType: 0,
    MinWholeSaleNum: 0,
    MaxBuyNum: 0,
    OENO: '',
    Level: EGoodsLevel.cc,
    IsFreePost: EGoodsPost.free,
    IsMatching: 0,
    Remark: '',
    WarrantyPeriod: '',
    PartsName: '',
    Unit: '',
    Images: [],
    IsFavorite: 0,
    ItemType: EItemType.STANDARD,
    Weight: 0,
    PartsNo: '',
    AutoID: 0,
    IsCustomer: 0,
    ServicePhone: '',
    Special: '',

    DepictImageList: [],
    Standard: '',
    AutoList: [],
    IsShowSaleItem: 0,
    PriceStr: '',
    BrokeageType: 0,
    Brokeage: 0,
    InBrokeageType: 0,
    InBrokeage: 0,
    DistriAuditStatus: 0,
    DistriAuditStatusStr: 0,
    SaleUserID: 0,
    ItemModel: 0,
    MinPrice: 0,
    MaxPrice: 0,
    MinSalesPrice: 0,
    MinWholeSalePrice: 0,
    SubItemList: [],
})


export const generateDetailItemId = (id: number | string, detailId: number | string = '') => `${id}-${detailId}`

export const getIdsFromDetailItemId = (id: string): [number, number | undefined] => {
    return id.split('-').map(k => Number(k)) as [number, number | undefined]
}
