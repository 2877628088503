import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {fetchHome} from "./service/Home";
import {setHomeStorage} from "./utils/storage/Home";
import {getAnimationFrame, windowWidth} from "./utils";

const _getAnimationFrame = getAnimationFrame()
declare global {
    interface Window{
        startTrans:(func:()=>void)=>void,
        translate:any
    }
}
const ReactRender = (url?: string) => {

    const checkWidth = () => {
        _getAnimationFrame(() => {
            if (url && windowWidth() <= 600) {
                window.location.href = url
                throw new Error('h5')
            }
        })
    }

    checkWidth()

    window.addEventListener("resize", checkWidth)

    ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById('root'),()=>{
            const CToEUrl=process.env.PUBLIC_URL+'/CToE.txt'
            const CToRUrl=process.env.PUBLIC_URL+'/CToR.txt'
            Promise.all([fetch(CToEUrl, {
                mode:"no-cors",
            }).then(r=>r.text()),fetch(CToRUrl, {
                mode:"no-cors",
            }).then(r=>r.text())]).then(ress=>{
                const CToE=ress[0]
                const CToR=ress[1]
                window.startTrans(()=>{
                    window.translate.setUseVersion2(); //设置使用v2.x 版本
                    window.translate.selectLanguageTag.show = false; //不出现的select的选择语言
                    window.translate.listener.start();
                    window.translate.language.setLocal('chinese_simplified');
                    window.translate.setAutoDiscriminateLocalLanguage();
                    window.translate.ignore.class.push('languages');
                    window.translate.nomenclature.append('chinese_simplified', 'english',CToE);
                    window.translate.nomenclature.append('chinese_simplified', 'russian',CToR);
                    window.translate.execute();
                })
            })


        }
    );
}

const HomeUrl = process.env.NODE_ENV === 'development' ?
    window.location.href.replace(window.location.host,
        process.env.NODE_ENV === 'development' ? 'chepeitong.shoppc.test.chepeilong.cn' : 'h5.chepeilong.com') :
    window.location.href

fetchHome(HomeUrl).then(res => {
    if (res.body.GoUrl) {
        return window.location.href = res.body.GoUrl
    } else {
        setHomeStorage(res.body)
        ReactRender(res.body.H5Host)
    }
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
