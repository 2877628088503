import * as React from 'react'
import {Modal} from "antd";
import {useEffect, useLayoutEffect, useState} from "react";
import {apiResponseCheck, tryCatch} from "../utils";
import {fetchChooseAutoVerList, IAutoListItem} from "../service/Common";
import ScrollContainer from "./ScrollContainer";
import AutoItem from "./ChooseAutoModal/AutoItem";

type Props = {
    gearbox?: string,
    visible?: boolean,
    onChange?(data: IAutoListItem): void
    onCancel?(data: boolean): void
}

const AutoVerModal: React.FC<Props> = ({gearbox, visible, onCancel, onChange}) => {

    const [list, setList] = useState<IAutoListItem[]>([]),
        _onCancel = () => onCancel && onCancel(false),
        _onChange = (data: IAutoListItem) => {
            onChange && onChange(data)
            _onCancel()
        }

    useLayoutEffect(() => {
        visible && gearbox && tryCatch(async () => {
            const res = await fetchChooseAutoVerList(gearbox),
                data = await apiResponseCheck<IAutoListItem[]>(res)
            setList(data)
        })
    }, [visible])

    return <Modal title={'选择版本'} visible={visible} footer={false} width={600} onCancel={_onCancel}>
        <ScrollContainer type={"y"} style={{height: 400}}>
            {list.map(item => <AutoItem key={item.key} data={item} onClick={_onChange}/>)}
        </ScrollContainer>
    </Modal>
}

export default AutoVerModal
