import {requestPost} from "../utils/request";
import {IUserAutoItem} from "./My";
import {ISelectOption} from "../types";

/*用户注册*/

//结算类型
export enum ESettlementType {
    Now = 1,
    Month = 2
}

//用户类型
export enum ECustomerType {
    Personal = 1,
    Company = 2
}

export const CustomerTypeOptions: ISelectOption[] = [{value: ECustomerType.Personal, label: '个人'},
    {value: ECustomerType.Company, label: '企业'}]


//性别
export enum ESex {
    MAN = 2,
    WOMEN = 1,
}

export const SexOptions: ISelectOption[] = [{value: ESex.MAN, label: '男'}, {value: ESex.WOMEN, label: '女'}]

export enum EPersonalAuth {
    Uncertified = 1,
    Certification = 2,
    Success = 3,
    Failed = 4,
}

export enum ECompanyAuth {
    Uncertified = 1,
    Certification = 2,
    Success = 3,
    Failed = 4,
}

export interface IUserInfo {
    Name?: string
    Mobile: string
    Sex?: ESex
    CustomerType: ECustomerType
    Category?: number
    OtherPhone?: string
    Email?: string
    VIPLevelName?: string
    QQ?: string
    Wechat?: string
    ProvinceID?: number
    CityID?: number
    AreaID?: number
    ProvinceName?: string
    CityName?: string
    AreaName?: string
    Address?: string
    SettlementType?: ESettlementType
    HeadIconUrl?: string,
    HeadIcon?: string,

    ActionType?: number

    SCCode?: string,
    PersonalAuth?: EPersonalAuth
    CompanyAuth?: ECompanyAuth

    PayType: string

    CarNum?: number
}


export interface IRegisterData {
    AutoID?: number
    scptcode: string,
    Mobile: string | number,
    Password: string
    VerifyCode: string
    SID?: string
}

export const accountRegister = (data: IRegisterData) => requestPost<IUserInfo>('/login/register.ashx', data)

/*用户登录*/
export enum ECheckStatus {
    wait = 1,
    reject = 2,
    resolve = 3,
    auto = 4,
}

export interface ICheckLoginState {
    SCCode?: string,
    /*1.待审核
    2.审核拒绝
    3.审核通过
    4.自动通过*/
    AuditStatus: ECheckStatus,
    //审核原因
    AuditReason: string
    //	是否可以再次提交审核
    HasAgaAudit: 0 | 1,
    //	是否需要完善信息
    NeedPerfect: 0 | 1,

    //是开启完善信息
    HasPerfect: 0 | 1,
    //	是否可以跳过
    IsCanSkip: 0 | 1,
}

export interface ILoginData extends IUserInfo, ICheckLoginState {
    AutoList: IUserAutoItem[],
    timestamp?: number
}

export const getCheckedAuto = (data: ILoginData) => {
    return data.AutoList.find(item => item.IsSelect)
}

export const addAutToLoginInfo = (data: ILoginData, auto: IUserAutoItem) => {
    if (!data.AutoList.some(item => item.AutoID === auto.AutoID || item.GearboxParam === auto.GearboxParam)) {
        data.AutoList.forEach(item => item.IsSelect = false)
        data.AutoList.unshift({...auto, IsSelect: true})
    }
    return data
}

export const getEmptyLoginData = (): ILoginData => ({
    Mobile: '',
    CustomerType: ECustomerType.Personal,

    PayType: '',
    AutoList: [],
    AuditStatus: ECheckStatus.auto,
    //审核原因
    AuditReason: '',
    //	是否可以再次提交审核
    HasAgaAudit: 0,
    //	是否需要完善信息
    NeedPerfect: 0,

    //是开启完善信息
    HasPerfect: 0,
    //	是否可以跳过
    IsCanSkip: 0,
})

export enum ELoginType {
    ACCOUNT = 1,
    SMS = 2
}

export interface ILoginRequest {
    Mobile: string | number,
    Password: string
    LoginType?: ELoginType
    AutoID?: number
}

export const accountLogin = (data: ILoginRequest) => requestPost<ILoginData>('/login/login.ashx', data)

/*忘记密码*/
export interface IRestPasswordRequest {
    AutoID?: number,
    scptcode: string,
    Mobile: string
    VerifyCode: string
    Password: string
}

export const resetPassword = (data: IRestPasswordRequest) => requestPost<ILoginData>('/login/resetpwd.ashx', data)

/*获取注册协议*/
export const fetchGVRP = () => requestPost<{ GVRP: string }>('/login/getgvrp.ashx')


