import {requestPost} from "../utils/request";
import {ECategoryAutoType, EStatus} from "./Common";

/*获取首页信息*/

//车配通开通状态
export enum ECPTStatus {
    //1未开通
    Unopened = 1,
    // 2已开通
    Opened = 2,
    //3已过期
    expired = 3
}

export enum IScptInfoModel {
    //经营模式 1按品牌  2按分类
    Brand = 1,
    //2按分类
    Category = 2
}

export interface IScptInfo {
    //游客编号
    TouristCode?: string,
    ScptCode: string
    ShowName: string
    CPTStatus: ECPTStatus
    Model: IScptInfoModel

    LogoPath: string
    MainParts: string
    MainAuto: string
    Description: string
    OpenTime: string
    Address: string
    Contacts: string
    Mobile: string

    Images: string[]
    Special: string

    Telphone: string
    ServicePhone?: string

    OrderNum: number
    //跳转url
    GoUrl?: string
    //域名关键字
    Host?: string
    //h5地址
    H5Host?: string
}


export enum EHomeSelectAutoType {
    all = 0,
    usual = 1,
    business = 2,
}

export enum EHomeCategoryType {
    all = 0,
    usual = 1,
    business = 2,
}

export enum EHomeSearchType {
    usual = 0,
    advance = 1
}

export interface IQQItem {
    QQName: string,
    QQServiceUrl: string,
}

export enum EHomFirstShow {
    home = 0,
    login = 1,
}

export enum EHomeAddressType {
    //全部
    all = 0,
    //仅送货
    shipping = 1,
    //仅到店
    store = 2
}

export interface IHomeData extends IScptInfo {
    CategoryType?: EHomeSelectAutoType,
    VinIsEnabled?: EStatus,
    IsShowAuto?: EStatus,
    IsRecommend?: EStatus,
    SearchType?: EHomeSearchType,
    SearchImg?: string,
    ShowItems?: string
    DefaultItem?: number,
    ShowType?: EHomeCategoryType
    QQServiceShow?: EStatus,
    QQList?: IQQItem[]
    HotKeys?: string[],
    TopImg?: string,
    HomeType?: EHomFirstShow,
    HasCoupon?: EStatus,
    AddressType?: EHomeAddressType
    IsSharePrice?: EStatus

    DefaultType?: ECategoryAutoType
    /*0.佣金模式
    1.积分模式*/
    BrokeMode?: 1 | 0,
    //佣金名称
    BrokeName?: string,
    //备案号
    RecordNo?: string,
}


export const getEmptyHomeData = (): IHomeData => ({
    ShowName: '',
    CPTStatus: ECPTStatus.Opened,
    Model: IScptInfoModel.Brand,

    ScptCode: '',

    LogoPath: '',
    MainParts: '',
    MainAuto: '',
    Description: '',
    OpenTime: '',
    Address: '',
    Contacts: '',
    Mobile: '',

    Images: [],
    Special: '',

    Telphone: '',
    OrderNum: 0,
})

/*分类数据是否区别*/
export const getCategoryDataType = (homeData: IHomeData): EHomeCategoryType => {
    const allData = homeData.CategoryType === EHomeSelectAutoType.all,
        allShow = homeData.ShowType === EHomeCategoryType.all,
        isAll = allData && allShow
    return (isAll ? EHomeCategoryType.all
        : allData ? homeData.ShowType
            : homeData.CategoryType === EHomeSelectAutoType.usual
                ? EHomeCategoryType.usual : EHomeCategoryType.business) ?? EHomeCategoryType.all
}

/*默认分类展示类别区分*/
export const getCategoryShowType = (homeData: IHomeData):ECategoryAutoType => {
    const type = getCategoryDataType(homeData)
    return type === EHomeCategoryType.all ? homeData.DefaultType || ECategoryAutoType.usual : type === EHomeCategoryType.business ? ECategoryAutoType.business : ECategoryAutoType.usual
}

export const fetchHome = (homeurl: string, ispreview?: boolean | 0 | 1) => requestPost<IHomeData>('/gethome.ashx', {
    homeurl,
    ispreview: Number(ispreview ?? 0)
})

/*获取首页模块信息*/

//模块编号
export enum EHomeModelType {
    //0.头部bananer
    Banner = 0,
    // 2.广告图片
    Ads = 2,
    // 3.商家信息
    ShopInfo = 3,
    // 4.配件分类
    Category = 4,
    // 5.配件品牌
    PartBrand = 5,
    // 6.配件商品
    Goods = 6,
    // 7.汽车品牌
    AutoBrand = 7,
    // 8.汽车车型
    AutoModel = 8,
    // 9.底部导航
    Navigation = 9,
    // 10.版权信息
    CopyRight = 10,
    // 11.功能导航
    FuncNav = 11,
}

export enum EItemType {
    //1标准价
    STANDARD = 1,
    // 2报价类
    OFFER = 2
}

export interface ITitleSetting {
    Title: string
    TitleIsShow: number
    TitleSize: string
    TitleColour: string
    TitleWeight: number | 'bold' | 'normal',
    IsShowMore?: 0 | 1
}

export const getEmptyTitleSetting = (): ITitleSetting => ({
    Title: '',
    TitleIsShow: 0,
    TitleSize: '16',
    TitleColour: '',
    TitleWeight: 'normal',
})

export interface IHome extends ITitleSetting {
    ModelType: EHomeModelType
    ModelStyle: number
    JsonStr: string
}

export interface IShopInfo {
    LogoPath: string
    MainParts: string
    MainAuto: string
    Special: string
    Description: string
    OpenTime: string
    Address: string
    Telphone: string
    ShopName: string
}

export const getEmptyShopInfo = (): IShopInfo => ({
    LogoPath: '',
    MainParts: '',
    MainAuto: '',
    Special: '',
    Description: '',
    OpenTime: '',
    Address: '',
    Telphone: '',
    ShopName: '',
})

//配件品牌模块信息
export interface IPartBrand {
    LogoPath: string
    ItemBrandID: number
    BrandName: string
    ToPage: string
}

//配件分类模块信息
export interface IPartCategory {
    PartsCaregoryID: number
    PartsName: string
    IconPath: string
    LevelID: number
    ToPage: string
    IconType?: 0 | 1
}

//车型品牌模块信息
export interface IAutoBrand {
    LogoPath: string
    AutoBrandID: number
    BrandName: string
    ToPage: string
}


//头部bananer模块信息
export interface IBannerItem {
    Title: string
    ImagePath: string
    ToPage: string
}

export interface IAds {
    SourceID: number
    ImagePath: string
    ToPage: string
}

//车型模块信息
export interface IAutoModel extends IAutoBrand {
    AutoSeriesID: number
    SeriesName: string
}

//商品模块信息
export interface IGoodsItem {
    ItemID: number
    ItemName: string
    ImagePath: string
    PartsName: string
    SalePrice: number
    OldPrice: number
    MinPrice: number
    MaxPrice: number
    ItemType: EItemType
    ToPage: string
    UnLookSalesPrice?: number
    UnLookWholePrice?: number
}

export interface IGoodsList {
    Title: string
    ItemList: IGoodsItem[]
}

export interface ICopyRight {
    IsShow?: number
    Content?: string
}

export const fetchHomeModel = (ispreview?: boolean | 0 | 1) => requestPost<IHome[]>('/getmodel.ashx', {ispreview})

/*获取底部导航 and 功能导航模块*/
export interface INavItem {
    NavigationID: number
    NavigationName: string
    IconPath: string
    ToPage: string
}

export const fetchBottomNavigation = () => requestPost<{ NavigationList: INavItem[] }>('/getbottomnavigation.ashx',)


/*获取关于我们*/
export interface IAboutUsData {
    LogoPath: string
    MainParts: string
    MainAuto: string
    Description: string
    OpenTime: string
    Address: string
    Telphone: string
    ShowName: string
    Special: string
    Images: string[]
    Wechat: string,
    CompanyIntro: string
}

export const getEmptyAboutUsData = (): IAboutUsData => ({
    LogoPath: '',
    MainParts: '',
    MainAuto: '',
    Description: '',
    OpenTime: '',
    Address: '',
    Telphone: '',
    ShowName: '',
    Special: '',
    Images: [],
    Wechat: '',
    CompanyIntro: '',
})

export const fetchAboutUs = () => requestPost<IAboutUsData>('/getaboutus.ashx')


