import md5 from 'md5'
import dataset from "../prototypeExtend/dataset";
import smartJson from "../utils/smartJson";
import {API_URL, getAPIHead} from "../config";
import {uAgent} from "../utils/browser";

export enum EClickType {
    bananer = 0,
    base = 1,
    ads = 2,
    shopInfo = 3,
    category = 4,
    partBrand = 5,
    partItem = 6,
    autoBrand = 7,
    auto = 8,
    tel = 11,
}

interface ClickProps {
    AssemblyType?: EClickType,
    Name?: string
    AssemblyID?: number
    PageUrl?: string
    Other?: object
}

interface ICollectSonar {
    global: Window
    _init: boolean
    _options: IOptions
    init: () => void
    getUid: () => string
    setClick: (data: { name?: string, params?: ClickProps }, e: MouseEvent | string) => void
    sendSonar: (obj: ISendSonarProps, async?: boolean) => void
    view: () => void
}

interface IOptions {
    setAuto?: boolean
    initData?: object | (() => object)
    url?: string
}

interface ISendSonarProps {
    type: ESonarType
    url?: string
    eventid?: string
    name?: string
    params?: object
    HotID?: any
}

interface ISonarData extends ISendSonarProps {
    uid: string
    timestamp: number
    userAgent: string
    referrer?: string
}

export enum ESonarType {
    view = 1,
    click = 2,
    back = 3,
    laod = 4,
    unload = 5,
    refresh = 6,
    hotCategory = 7,
    hotGoods = 8,
    hotAutoBrand = 9,
    hotAutoSeries = 10,
}

/*enum ESendMode {
    xhr = 1,
    img = 2,
}*/

// @ts-ignore
const isNode = process.env.NODE_ENV === 'node'

const sendSonarData = (url: string, params: ISonarData, async: boolean = true) => {
    // @ts-ignore
    if (!url || isNode) return
    const {
        type: BurialType, uid: ViewID,
        url: PageUrl,
        name: PageName,
        params: Params,
        timestamp: Timestamp,
        eventid: EventId, userAgent: UserAgent,
        HotID,
    } = params
    const data = {BurialType, ViewID, PageUrl, PageName, Params, Timestamp, EventId, UserAgent, HotID}
    const head = getAPIHead(data)
    if (navigator.sendBeacon) {
        navigator.sendBeacon(url, JSON.stringify({head, data}))
    } else {
        const client = new XMLHttpRequest();
        client.open("POST", "/log", async);
        client.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
        client.send(JSON.stringify({head, data}));
    }
}

const getThisURL = () => window.location.href

const CollectSonar = function (this: ICollectSonar, global: any = window || this) {
    this._init = false
    this.global = global
} as any as { new(): ICollectSonar }

CollectSonar.prototype = {
    init: function (this: ICollectSonar) {

        this._options = {}

        if (!this._init) {
            if (this.global && isNode) {
                this._init = true

                /*const global = this.global
                const {pushState,replaceState} = this.global.history

                const collectSonar = this*/

                /* this.global.history.pushState = function (...arr:any[]) {
                     //console.log()
                     collectSonar.sendSonar({type:ESonarType.view,url:`http://${window.location.host }${arr[2]}`})
                     pushState.apply(global.history,arr)
                 }

                 this.global.history.replaceState = function (...arr:any[]) {
                     collectSonar.sendSonar({type:ESonarType.view,url:`http://${window.location.host }${arr[2]}`})
                     replaceState.apply(global.history,arr)
                 }*/

                this.global.addEventListener('popstate', () => {
                    localStorage['_SR'] = 1
                }, false)

                this.global.document.addEventListener('click', (e: MouseEvent) => {
                    //console.log(uAgent)
                    if (e.target) {
                        const elem = (e.target as HTMLElement)
                        const params = smartJson(dataset(elem, '_sonar'))
                        params && this.setClick(params, e)
                    }
                }, false)

                //console.log(this.global.document.readyState)
                if (this.global.document.readyState === 'complete') {
                    const st = localStorage['_ST']
                    this.sendSonar({type: st ? ESonarType.refresh : ESonarType.laod})
                    st && (localStorage.removeItem('_ST'))
                } else {
                    this.global.addEventListener('load', () => {
                        const st = localStorage['_ST']
                        this.sendSonar({type: st ? ESonarType.refresh : ESonarType.laod})
                        st && (localStorage.removeItem('_ST'))
                    })
                }

                this.global.addEventListener('hashchange', () => {
                    this.sendSonar({type: ESonarType.back})
                })

                this.global.addEventListener('unload', () => {
                    localStorage.removeItem('_ST')
                    localStorage.removeItem('_SR')
                    this.sendSonar({type: ESonarType.unload}, false)
                })

                this.global.addEventListener('beforeunload', () => {
                    //this.sendSonar({type:ESonarType.refresh},false)
                    localStorage['_ST'] = 1
                })
            }
        }
    },

    view: function () {
        //console.log('view')
        if (!this._init) {
            this.init()
        } else {
            const SR = localStorage['_SR']
            this.sendSonar({type: SR ? ESonarType.back : ESonarType.view})
            SR && localStorage.removeItem('_SR')
        }
    },

    sendSonar: function (this: ICollectSonar, obj: ISendSonarProps, async: boolean = true) {
        const {referrer} = this.global.document
        sendSonarData(`${API_URL}/common/setburialpointdata.ashx`, Object.assign({}, obj,
            {
                uid: this.getUid(),
                referrer,
                timestamp: Date.now(),
                userAgent: uAgent, url: obj.url || getThisURL()
            }), async)
    },

    setClick: function (data: { name?: string, params?: ClickProps }, e: MouseEvent | string) {
        let roundstr = ''
        if (typeof e === 'string' || typeof e === "number") {
            roundstr = e
        } else if (e.target) {
            const elem = e.target as HTMLElement
            if (elem) {
                roundstr = elem.outerHTML
            }
        }

        const eventid = md5(`${getThisURL()}_${roundstr}`)
        this.sendSonar(Object.assign({}, data, {eventid, type: ESonarType.click}))
    },

    getUid: function (): string {
        if (window) {
            const id = localStorage.getItem('sonarid') || md5(uAgent + Date.now())
            localStorage.setItem('sonarid', id)
            return id
        } else {
            return ''
        }
    }
}

const collectSonar = new CollectSonar()

export default collectSonar
