import * as React from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import {compoundRoutePath, isThisDomain} from "../utils";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    autoTarget?: boolean


    innerRef?(e: HTMLAnchorElement | null): void

    replace?: boolean

    subPageCount?: boolean
}

const AnchorLink:React.FC<Props> = (props ) => {
    const native = useNavigate()
    const {href, children, target, autoTarget, innerRef, replace, onClick, subPageCount, ...attr} = props
    const click = (e: any) => {
        if (replace) {
            subPageCount && (window._PAGE_COUNT = window._PAGE_COUNT > 0 ? window._PAGE_COUNT - 1 : 0)
            href && native(href,{replace})
        }
        onClick && onClick(e)
    }
    let newTarget = target
    if (autoTarget && href && !isThisDomain(href)) {
        newTarget = '_blank'
    }

    if (!href || /^http/i.test(href) || replace) {
        return <a {...attr} ref={innerRef} href={replace ? 'javascript:void(0)' : href} target={newTarget}
                  onClick={click}>{children}</a>
    } else {
        return <NavLink ref={innerRef} {...attr} target={newTarget} to={compoundRoutePath(href)}
                        onClick={click}>{children}</NavLink>
    }
}

export default AnchorLink
