import {compoundRoutePath, getAnimationFrame, isUndefined} from "./index";
import {NavigateFunction} from "react-router";
import {EURLs} from "../types/URLs";
import {getLoginData} from "./storage/Login";
import {ImmediatelyBuyItemInfo, IShopCarGoodsItem} from "../service/ShoppingCart";
import {getHomeData} from "./storage/Home";

const _data: { [name: string]: any } = {}

//const GiftSendLogKey = 'GIFT_SEND_LOG'

export enum EGlobalKey {
    admin = 'adminInfo',
    home = 'HOME',
    submitOfferGoods = 'submitOfferGoods',
    submitGoods = 'submitGoods',
    SID = 'SID',
    P_USER_ID = 'P_USER_ID',
    SKIP_PREFECT = 'SKIP_PREFECT',
    EPC_AUTO_HISTORY = 'EPC_AUTO_HISTORY',
    EPC_AUTO = 'EPC_AUTO',
    EPC_CREATE_CAR = 'EPC_CREATE_CAR',
}

//本地数据隔离
const getShopKey = (key: string) => key === EGlobalKey.home ? EGlobalKey.home : `${getHomeData().ScptCode}_${key}`


export const setGlobalData = <T = any>(key: string | EGlobalKey, data: T): void => {
    _data[getShopKey(key)] = data
}

export const getGlobalData = <T = any>(key: string | EGlobalKey, _delete: boolean = false): T => {
    const res = _data[getShopKey(key)]
    if (_delete) {
        removeStorageData(getShopKey(key))
    }
    return res
}

export const setStorageData = <T = any>(key: string | EGlobalKey, data: T): void => {
    localStorage.setItem(getShopKey(key), JSON.stringify(data))
    _data[getShopKey(key)] = data
}

export const getStorageData = <T = any>(key: string | EGlobalKey, _delete: boolean = false): T => {
    let res = _data[getShopKey(key)]
    try {
        if (isUndefined(res)) {
            const json = localStorage.getItem(getShopKey(key))
            if (isUndefined(json) || json === null) {
                res = json
            } else {
                return JSON.parse(json)
            }
        }

        if (_delete) {
            removeStorageData(getShopKey(key))
        }
    } catch (e) {
        throw new Error(`getStorageData JSON error:${getShopKey(key)}`)
    }
    return res
}

export const setSubmitGoods = (key: EGlobalKey, data: (IShopCarGoodsItem | ImmediatelyBuyItemInfo)[]) => {
    setStorageData(key, data)
    getGlobalData(key === EGlobalKey.submitGoods ? EGlobalKey.submitOfferGoods : EGlobalKey.submitGoods, true)
}

export const removeStorageData = (key: string) => {
    delete _data[getShopKey(key)]
    localStorage.removeItem(key)
}

export const setSessionData = <T = any>(key: string | EGlobalKey, data: T): void => {
    sessionStorage.setItem(key, JSON.stringify(data))
    _data[getShopKey(key)] = data
}

export const getSessionData = <T = any>(key: string | EGlobalKey, _delete: boolean = false): T => {
    let res = _data[getShopKey(key)]
    try {
        if (isUndefined(res)) {
            const json = sessionStorage.getItem(key)
            if (isUndefined(json) || json === null) {
                res = json
            } else {
                return JSON.parse(json)
            }
        }

        if (_delete) {
            removeSessionData(key)
        }
    } catch (e) {
        throw new Error(`getStorageData JSON error:${key}`)
    }
    return res
}

export const removeSessionData = (key: string) => {
    delete _data[getShopKey(key)]
    sessionStorage.removeItem(key)
}

let navigate: NavigateFunction | null = null

export const setNavigate = (_navigate: NavigateFunction) => {
    navigate = _navigate
}

export const uriCheckLogin = (param: any, typeMode: boolean = false, justCheck: boolean = false) => {
    const sccode = getLoginData().SCCode
    if (sccode) {
        param && param !== EURLs.Login && !justCheck && navigate && navigate(param, {replace: typeMode})
    } else if (param) {
        // @ts-ignore
        (window || global)._uri = param && param !== EURLs.Login ? param : ''
        navigate && navigate(EURLs.Login, {replace: typeMode})
    }
    return !!sccode
}

export const getNavigate = (): NavigateFunction | null => navigate

let _redirect: string = ''

export const setRedirect = (redirect: string) => {
    _redirect = redirect
}

export const getRedirect = (del?: boolean) => {
    const res = _redirect || EURLs.Home
    if (del) {
        _redirect = ''
    }
    return res
}

export const JumpToLogin = () => {
    //window.history.replaceState({},'login',compoundRoutePath('login'))
    const navigate = getNavigate(),
        redirect = window.location.href.replace(window.location.origin, '')

    if (compoundRoutePath(redirect.toLowerCase()) !== compoundRoutePath(EURLs.Login)) {
        setRedirect(redirect)
        getAnimationFrame()(() => navigate ? navigate(compoundRoutePath(EURLs.Login), {replace: true}) : (window.location.href = compoundRoutePath(EURLs.Login)))
    }
}


const globalShowFormWork = () => {
    let _f: (() => void | undefined)
    return {
        setF: (f: () => void) => _f = f,
        getF: () => _f,
        exec: () => _f && _f()
    }
}


export const {setF: setShowAddAutoFunc, exec: showAddAutoFunc} = globalShowFormWork()

export const {setF: setShowAutoFunc, exec: showAutoFunc} = globalShowFormWork()

export const {setF: setShowAutoVerFunc, exec: showAutoVerFunc} = globalShowFormWork()


let showPrefectFunc: ((data: boolean) => void | undefined)

export const setShowPrefectFunc = (f: (data: boolean) => void) => showPrefectFunc = f

export const getShowPrefectFunc = () => showPrefectFunc

export const globalShowPrefect = () => {
    const f = getShowPrefectFunc()
    f && f(true)
}
