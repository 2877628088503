import * as React from 'react'
import {Button, Form, Input, message, Space, Upload} from "antd";
import {SearchOutlined, UploadOutlined} from '@ant-design/icons'
import {EImageMIME} from "../../types";
import {fetchAutoListFromVin, fetchVINFromOCR, IAutoGearItem} from "../../service/Common";
import {apiResponseCheck} from "../../utils";
import {useState} from "react";

type VINData = {
    //0.失败 1.成功
    status: 0 | 1
    message: string
    vin: string
}

type Props = {
    onChange?(data: IAutoGearItem[]): void
}

const VINSearch: React.FC<Props> = ({onChange}) => {

    const [form] = Form.useForm(),
        [uploadLoading, setUploadLoading] = useState(false),
        [loading, setLoading] = useState(false),
        beforeUpload = (file: File) => {
            //console.log(file)
            setUploadLoading(true)
            fetchVINFromOCR(file).then(res => {

                apiResponseCheck<VINData>(res).then(data => {
                    if (data.status) {
                        form.resetFields()
                        form.setFieldsValue({vin: data.vin})

                    } else {
                        message.error(data.message)
                    }
                })
            }).finally(() => setUploadLoading(false))

            return false
        },
        checkVin = (vin: string) => {
            if (vin.length === 17 && !/^[\w\d]{17}$/.test(vin)) {
                return Promise.reject(new Error('车架号格式有误'))
            }
            return Promise.resolve()
        },

        onSubmit = async () => {
            setLoading(true)
            try {
                const {vin} = await form.validateFields(),
                    res = await fetchAutoListFromVin(vin),
                    data = await apiResponseCheck<{ GearboxList: IAutoGearItem[] }>(res)

                if (data.GearboxList.length) {
                    onChange && onChange(data.GearboxList)
                } else {
                    message.warning('无法查询到任何信息')
                }
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }

    return <Form layout={"vertical"} form={form}>
        <Form.Item>
            <Space>
                <Form.Item noStyle name={'vin'} rules={[
                    {required: true, message: '输入17位车架号'},
                    {min: 17, max: 17, message: '车架号必须为17位'},
                    {validator: (_, v) => checkVin(v)}]}>
                    <Input.Search prefix={<SearchOutlined/>} style={{width:420}} maxLength={17} placeholder={'输输入17位车架号'} onSearch={onSubmit}
                                  enterButton={<Button type={"primary"} loading={loading}
                                                       onClick={onSubmit}>搜索</Button>}/>
                </Form.Item>

                <Upload showUploadList={false} beforeUpload={beforeUpload}
                        accept={[EImageMIME.jpeg, EImageMIME.pjpeg, EImageMIME.png].join(',')}>
                    <Button loading={uploadLoading} icon={<UploadOutlined/>}>上传VIN码</Button>
                </Upload>
            </Space>
        </Form.Item>
    </Form>
}

export default VINSearch
