import * as React from 'react'
import {useEffect, useLayoutEffect, useRef, useState} from 'react'
//@ts-ignore
import Styles from './AutoBrandList.scss'
import {fetchChooseAutoBrandList, IAutoListItem} from "../../service/Common";
import {apiResponseCheck, GCNAlias} from "../../utils";
import AutoTitle from "./AutoTitle";
import AutoItem from "./AutoItem";
import {Typography} from "antd";
import {EStyleClass} from "../../types";
import ScrollContainer from "../ScrollContainer";

type IFilterItem = { filter: string, list: IAutoListItem[] }
type Props = {
    onChange?(data: IAutoListItem): void
}

const AutoBrandList: React.FC<Props> = ({onChange}) => {

    const [brandList, setBrandList] = useState<IFilterItem[]>([]),
        [scrollData, setScrollData] = useState<{ x?: number, y?: number } | undefined>(),
        scrollDiv = useRef<HTMLDivElement | null>(null),
        onNavClick = (filter: string) => () => {
            let top = 0
            brandList.find(item => {
                const res = item.filter === filter
                res || (top += 54 * item.list.length + 30)
                return res
            })
            setScrollData({y: top})
        },
        _onChange = (data: IAutoListItem) => {
            setScrollData(undefined)
            onChange && onChange(data)
        }

    useLayoutEffect(() => {
        fetchChooseAutoBrandList().then(res => {
            apiResponseCheck<IAutoListItem[]>(res).then(data => {
                setBrandList(data.reduce((list: IFilterItem[], item) => {

                    const fItem = list.find(fItem => fItem.filter === item.alphabet)
                    if (fItem) {
                        fItem.list.push(item)
                    } else {
                        list.push({
                            filter: item.alphabet,
                            list: [item]
                        })
                    }

                    return list
                }, []))
            })
        })
    }, [])

    return <div className={Styles.container} ref={scrollDiv}>
        <ScrollContainer className={Styles.autoList} scroll={scrollData}>
            {brandList.map(group => <div key={group.filter}>
                <AutoTitle>{group.filter}</AutoTitle>
                {group.list.map(item => <AutoItem key={item.key} data={item} onClick={_onChange}/>)}
            </div>)}
        </ScrollContainer>

        <div className={Styles.navWrap}>
            {brandList.map(group => <Typography.Text key={group.filter} type={"secondary"}
                                                     onClick={onNavClick(group.filter)}
                                                     className={GCNAlias(Styles.navItem, EStyleClass.TextClick)}>
                {group.filter}
            </Typography.Text>)}
        </div>
    </div>
}

export default AutoBrandList
